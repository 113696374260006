import { logger } from 'react-native-logs';

const DEVEL = true;

const log = logger.createLogger();
 
if (DEVEL) {
  log.setSeverity('debug');
} else {
  log.setSeverity('error');
}

const HOSTNAME = window.location.hostname;

// Límite de datos para las gráficas líneales. Sólo afecta en el modo realtime.
const GRAPH_LIMIT_DISPLAY = 10;
 
export {
  log,
  HOSTNAME,
  GRAPH_LIMIT_DISPLAY
};  

