import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import NavItemSocrates from '../navItem';
import { Typography, Grid } from '@material-ui/core/';
import State from '../../layout/State';
import AdminList from './adminList';
import Breadcrumbs from '../../layout/breadcrumbs';


export default function Admin() {    
    return (
        <Fragment>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <State />
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item xs>
                    <Typography variant="h3" gutterBottom>
                    <Trans>Sócrates</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Breadcrumbs />
                </Grid>
            </Grid>
            <NavItemSocrates />
            <AdminList />
        </Fragment>
    )
}