import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { login } from '../../actions/auth';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, TextField, Typography, Box, Link, AppBar, Toolbar, CssBaseline, IconButton, Container } from '@material-ui/core/';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import PersonIcon from '@material-ui/icons/Person';

/**
* Función para los estilos.
* En esta función se recogen los parámetros necesarios para el estilo de Noname.
*/
const styles = theme => ({
  root: {
    height: '100vh',
    alignItems: 'center',
    backgroundColor: '#212529',
  },
  logo: {
    backgroundImage: 'url(/static/images/logo-exta-dark.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
    width: '100%',
    height: '3.2em',
  },
  paper: {
    margin: ' auto 25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '3em',
  },
  submit: {
    margin: '2rem auto',
    borderRadius: '2rem',
    padding: '0.75rem 2rem',
    color: '#282C30',
    backgroundColor: '#988575',
    '&:hover': {
      backgroundColor: '#b9a796',
    }
  },
  AppBarLogin: {
    backgroundColor: '#282C30',
    padding: '0.8rem 5%',
    opacity: '0.5',
  },
  aLogin: {
    display: 'block',
    height: '4em',
    width: '100%',
  },
  aButton: {
    padding: '7px',
  }
});

/**
* Parte del componente para copyright.
* Contiene toda la información necesaria para mostrar el Copyright
*/
function Copyright() {
  return (
    <Typography variant="body2" align="center" style={{color: '#6C757D'}}>
      {'Copyright © '}
      {' '}
      {new Date().getFullYear()}
      {''}
    </Typography>
  );
}

/**
 * Se permite la introducción de datos para el login de los usuarios.
 * 
 * @example ../../../../docs/Frontend/Login/Login.md
 */
 class Login extends Component {
    state = {
        username: '',
        password: ''
    };

    /**
    * Función de props en los que se indican los PropTypes que se van a usar
    */
    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool
    }

    /**
    * Función que es llamada en el momento de enviar los datos.
    */
    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.username, this.state.password);
    };

    /**
    * Función usada para que sea posible el rellenado de los campos y mandar esos valores a los estados correspondientes.
    */
    onChange = e => this.setState({
        [e.target.name]:e.target.value
    });

    render() {
        const { classes } = this.props;
        if(this.props.isAuthenticated){
            return <Redirect to="/buildings" />
        }

        const { username, password } = this.state;
        return (
        <div>
          <AppBar position="fixed" className={classes.AppBarLogin}>
            <Toolbar>
              <a className={classes.aLogin} href='http://135.181.140.100:15302/'><div className={classes.logo}></div></a>
              <IconButton color="inherit" href='tel:914210151' className={classes.aButton}>
                <PhoneIcon />
              </IconButton>
              <IconButton color="inherit" href='https://goo.gl/maps/EzAdaKomQN61UgZw8' className={classes.aButton}>
                <RoomIcon />
              </IconButton>
              <IconButton color="inherit" href='http://135.181.140.100:15302/' className={classes.aButton}>
                <PersonIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
            <Grid container component="main" className={classes.root}>
              <CssBaseline />
                <Grid item xs={12} sm={12} md={12} square>
                  <Container maxWidth="md">
                    <div className={classes.paper}>
                      <Typography variant="h3" align="center" style={{color: '#fff', marginTop: '2rem'}}>
                        Accede a tu área personal
                      </Typography>
                      <Typography align="center" style={{color: '#6C757D'}}>
                        y gestiona la normativa de tu proyecto
                      </Typography>
                        <form onSubmit={this.onSubmit} className={classes.form}>
                            <TextField type="text" onChange={this.onChange} variant="outlined" margin="normal" required fullWidth label="Usuario" name="username" value={username} />
                            <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Contraseña" type="password" autoComplete="current-password" onChange={this.onChange} value={password} />
                            <Button className={classes.submit} fullWidth type="submit" variant="contained" color="primary" size="large">Entrar</Button>
                            <Box mt={5}>
                              <Copyright />
                            </Box>
                        </form>
                    </div>
                  </Container>
                </Grid>
            </Grid>
        </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});
Login.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { login })(withStyles(styles)(Login));
