import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import Metre from '../components/metre';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>ELECTRICIDAD-008</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>SISTEMAS DE ALIMENTACIÓN ININTERRUMPIDA</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>INFORMACIÓN GENERAL</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="FECHA" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="UBICACIÓN" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="CLIENTE" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="PROYECTO" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="FABRICANTE" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="MODELO" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="POTENCIA" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PROTOCOLO DE PRUEBAS SAI</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo de medición" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Nº de serie" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>TENSIÓN</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>INTENSIDAD</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>FRECUENCIA</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>COS</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>TRANSFERENCIA TRAS CAIDA DE RED</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>ACOPLAMIENTO A RED</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>TIEMPO TRANSCURRIDO TRAS CAIDA DE RED</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>PASO A BYPASS ESTÁTICO</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>RETORNO DE BYPASS ESTÁTICO</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>NIVEL DE BATERIAS</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="body1" gutterBottom>
                            <Trans>CORRECTA REGULACIÓN FORMA DE ONDA DE SALIDA</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="h5" gutterBottom>
                            <Trans>CONFORMIDAD</Trans>
                        </Typography>
                        <TextField fullWidth label="Firma persona que realiza el protocolo" variant="outlined" />
                        <TextField fullWidth label="Fecha" variant="outlined" />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);