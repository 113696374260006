import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>SISTEMAS DE DETECCIÓN Y ALARMA</Trans>
            </Typography>
            <Container>
              <Typography variant="body1" gutterBottom>
                  <Trans>Todos los sistemas nuevos de detección y alarma de Incendios deberán ser certificados y probados para garantizar su correcto funcionamiento e instalación antes de la puesta en servicio y ocupación del edificio conforme al Reglamento de Protección contra incendios RD 513/2017 y Norma UNE 23007-14.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las pruebas serán dirigidas obligatoriamente por un representante cualificado del instalador autorizado, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos y los medios necesarios para las pruebas serán presentados por el instalador antes del inicio de las mismas para aprobación.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Certificados de materiales:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Certificado de conformidad a normas UNE-EN, NFPA, CEPREVEN, etc. de la instalación realizada. </li>
                    <li>Certificado de conformidad a normas EN o UNE de todos los detectores de incendio y la central de incendio, emitido por Organismo de Control reconocido.</li>
                    <li>Certificado de materiales del fabricante para el cable eléctrico, tubos de protección, cajas, etc.</li>
                    <li>Cálculo del fabricante del sistema de la fuente secundaria de suministro de energía eléctrica para el tiempo de vigilancia y del estado de alarma.</li>
                    <li>Cálculos justificativos de los diámetros de los tubos y orificios en caso de sistemas de aspiración especiales.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Verificación de los equipos:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Con la instalación vacía verificar cada uno de los puntos siguientes:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se inspeccionará visualmente la instalación para comprobar que todos los trabajos se han realizado de forma satisfactoria y que los materiales y componentes del sistema cumplen con lo indicado en los planos y documentos del Proyecto.</li>
                    <li>Se comprobará que todos los detectores, pulsadores y alarmas corresponden con los modelos aprobados que están conectados e instalados conforme a la normativa empleada. Los pulsadores que no sean fácilmente localizables estarán debidamente señalizados.</li>
                    <li>Se comprobará la situación de la central de control, estando esta instalada en lugar permanentemente vigilado por personal responsable. Se verificará que dispone de alimentación eléctrica principal exclusiva y las baterías de emergencia están conectadas. Se medirá y anotará el valor de la tensión principal (V) y la tensión y capacidad de las baterías (Ah).</li>
                    <li>Se comprobará todos los repetidores, anunciadores y fuentes de alimentación auxiliares disponibles en la instalación, verificándose que están instalados en las zonas asignadas en los planos y conectados correctamente y en servicio.</li>
                    <li>Se comprobarán los trazados de los tubos eléctricos, estando estos debidamente instalados y sujetos con grapas de fijación según R.E.B.T. Las cajas de derivación estarán debidamente identificadas con rótulos en las tapas y se verificará las uniones de los tubos y las entradas a las cajas y zócalos.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Prueba de funcionamiento:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>La comprobación del funcionamiento de los sistemas de detección y alarma se realizará sólo después de un periodo preliminar de funcionamiento de al menos 1 semana durante el cual se observará la estabilidad de la instalación en condiciones normales de funcionamiento.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>La prueba incluirá todos los equipos que forman la instalación incluido los dispositivos de transmisión de alarma locales y a distancia. Se tomarán las medidas adecuadas para evitar disparos en los sistemas de extinción y otras instalaciones asociadas desconectando las bobinas de las electroválvulas.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se seguirán cuidadosamente las instrucciones del fabricante para las pruebas de cada equipo. El instalador contará durante las pruebas con la presencia de un técnico de la empresa suministradora que asesorará en todo momento de la operación y pruebas del sistema.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Central de control:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se comprobará (en condiciones de tensión de red) que las indicaciones visuales son correctas y no hay averías, no iniciándose las pruebas en caso contrario. Se identificarán los comandos y teclas de la central y su operativa y se anotarán las claves de acceso en caso de estar disponibles.</li>
                    <li>Se actuará el pulsador de prueba de lámparas. Todos los leds deberán encenderse correctamente.</li>
                    <li>Se desconectará la red y se señalizará la avería correctamente, silenciando el zumbador y volviéndolo a conectar. La avería se eliminará permaneciendo la central nuevamente operativa.</li>
                    <li>Se desconectará un terminal de las baterías de emergencia y se señalizará correctamente la avería, silenciándose el zumbador y volviéndolo a conectar.</li>
                    <li>Se verificará mediante listado la configuración del sistema, comprobado el número de lazos, el tipo de conexión en abierto o cerrado y que están habilitadas las funciones generales previstas (servicio día-noche, tiempos de verificación, opción de silencio de sirenas, parpadeo de leds, etc.).</li>
                    <li>Se sacará un listado de todos los equipos instalados por la impresora o por pantalla del display en los sistemas microprocesados y se comprobará que no hay equipos activados, fuera de servicio o en avería.</li>
                    <li>Se revisarán cuidadosamente las lecturas de cada equipo para verificar el tipo, estado, dirección, etiqueta de identificación, sensibilidad y ecuación de control. Se deberán corresponder exactamente con los listados de programación incluidos en la documentación, comprobado que el valor analógico de cada detector es correcto.</li>
                    <li>Se comprobará el funcionamiento correcto de la impresora, estando conectada al puerto serie correspondiente y disponiendo del papel adecuado, siendo los textos claros y legibles.</li>
                    <li>Se comprobará el funcionamiento del PC si se dispone en la instalación, estando debidamente configurado con el software de gráficos instalado. Se revisarán las diversas pantallas con los planos de la instalación y se verificará que se corresponden con lo especificado.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Equipos de campo (detectores, pulsadores, etc.):</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                      <li>Se activará en la central de control la opción de pruebas si se dispone, seleccionando la primera zona o lazo de detectores.</li>
                      <li>Mediante un dispositivo de pruebas adecuado (generador de humos, imán, etc.) se activará el primer detector de incendio, comprobado que funciona correctamente si se enciende el LED y si se activan las alarmas acústicas asociadas.</li>
                      <li>Se comprobará el porcentaje de detectores acordado (mínimo el 10%). En caso de fallo de alguno de los equipos se cambiará por otro nuevo y probará. En caso de nuevos fallos se probará el 100% de las unidades.</li>
                      <li>Se comprobará el 100% de los pulsadores y todos los dispositivos de alarma. </li>
                      <li>Se leerán los resultados en el histórico de la central o en la impresora si se dispone. Se comprobará que la dirección e identificación corresponde con el equipo probado. En sistemas convencionales la activación será confirmada por una persona situada en la central de control rearmando después de cada actuación.</li>
                      <li>Se repetirá la comprobación de los equipos de las siguientes zonas o lazos. Se comprobarán los equipos de al menos una zona o lazo sólo con alimentación de emergencia (baterías).</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Repetidores y anunciadores:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                      <li>Se comprobará que los mensajes transmitidos se corresponden con los de la central de control. En anunciadores o sinópticos se comprobará que las alarmas identifican fielmente las áreas comprobadas.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Actuaciones asociadas:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                      <li>Se verificará los listados con todas las actuaciones asociadas, comprobado que se corresponden con las necesidades del proyecto, completándolas o revisándolas en caso necesario.</li>
                      <li>Actuaciones que impliquen a otras instalaciones o equipos (parada de ascensores, corte de válvulas de gas, desconexión de cuadros, parada de ventiladores, disparo exhutorios, etc.) deberán verificarse en vacío antes de su conexión definitiva. En caso de extinciones se desconectarán las electroválvulas o dispositivos automáticos para la descarga. </li>
                      <li>Con la central de control en automático se activarán los detectores y/ o pulsadores requeridos y se comprobará la señalización en la central y la actuación solicitada. Se verificarán y anotarán los tiempos de retardo en sistemas de extinción. Se rearmará la central de control y se repetirá el procedimiento para todas las actuaciones asociadas. </li>
                      <li>Se repetirán las actuaciones anteriores ahora sólo con corriente de baterías.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
              </Typography>
            </Container>
        </Fragment>
    )
}