import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '../../actions/messages';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, TextField, FormControlLabel, Checkbox, Grid, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core/';
import { getClient, editClient } from '../../actions/clients';
import { loadUsers } from '../../actions/users';
import { loadBuildings } from '../../actions/building';
import { loadModules } from '../../actions/modules';
/**Componente que contiene el formulario para la edición de los datos del cliente actual. */
export class EditClient extends Component {

  constructor(props) {
    super(props);
  };

  state = {
    id: null,
    name: '',
    user_assigned: [],
    building_assigned: [],
    modules_assigned: [],
    user_list: [],
    building_list: [],
    modules_list: []
  };


  static propTypes = {
    client: PropTypes.func,
    edit: PropTypes.func,
  };

  onSubmit = e => {
    e.preventDefault();
    const { id, name, user_assigned, building_assigned, modules_assigned } = this.state;

    const editClient = {
      id,
      name,
      user_assigned,
      building_assigned,
      modules_assigned,
    };
    this.props.editClient(editClient);
  }


  componentDidMount() {
    this.props.loadUsers();
    this.props.loadBuildings();
    this.props.loadModules();
    this.props.getClient(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.setState({
        id: this.props.client.id,
        name: this.props.client.name,
        user_assigned: this.props.client.user_assigned,
        building_assigned: this.props.client.building_assigned,
        modules_assigned: this.props.client.modules_assigned,
        user_list: this.props.users,
        building_list: this.props.buildings,
        modules_list: this.props.modules,
      })
    }

    if (prevProps !== this.props) {
      this.setState({
        user_list: this.props.users,
        building_list: this.props.buildings,
        modules_list: this.props.modules,
      })
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {

    const { name, user_assigned, building_assigned, modules_assigned, user_list, building_list, modules_list } = this.state;

    return (
      <div className="root">
        <Typography variant="h3" gutterBottom>
          <Trans>Editar cliente</Trans>
        </Typography>
        <form className="root"
          onSubmit={this.onSubmit}>
          <TextField variant="outlined" margin="normal" id="client-new-name" label="Nombre" type="text" fullWidth
            name="name"
            onChange={this.onChange}
            value={name} />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="label-user-list">Usuarios Asignados</InputLabel>
            <Select
              name="user_assigned"
              multiple
              labelId="label-user-list"
              value={user_assigned}
              onChange={this.onChange}
            >
              {user_list.map((user) => (
                <MenuItem value={user.id}>{user.username}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="label-building-list">Edificios Asignados</InputLabel>
            <Select
              name="building_assigned"
              multiple
              labelId="label-building-list"
              value={building_assigned}
              onChange={this.onChange}
            >
              {building_list.map((building) => (
                <MenuItem value={building.id}>{building.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="label-module-list">Módulos Asignados</InputLabel>
            <Select
              name="modules_assigned"
              multiple
              labelId="label-module-list"
              value={modules_assigned}
              onChange={this.onChange}
            >
              {modules_list.map((module) => (
                <MenuItem value={module.id}>{module.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
              <Button type="button" color="primary" onClick={this.onSubmit}><Trans>Editar cliente</Trans></Button>
              <Button type="button" color="secondary" component={Link} to="/adminpanel/clients"><Trans>Volver</Trans></Button>
            </ButtonGroup>
          </Grid>
        </form>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  client: state.clients.client,
  users: state.users.users,
  modules: state.modules.modules,
  buildings: state.buildings.buildings
});

export default connect(mapStateToProps, { getClient, editClient, loadUsers, loadBuildings, loadModules })(EditClient);