import { GET_BUILDING, ADD_BUILDING, LOAD_BUILDINGS, DELETE_BUILDING, EDIT_BUILDING, LOAD_BUILDING } from '../actions/types';

const initalState = {
    buildings: [],
    building: {}
};

export default function(state = initalState, action) {
    switch(action.type) {
        case GET_BUILDING:
            return {
                ...state,
                building: action.payload
            };
        case ADD_BUILDING:
            return {
                ...state,
                buildings: [...state.buildings, action.payload]
            };
        case LOAD_BUILDINGS:
            return {
                ...state,
                buildings: action.payload
            };
        case LOAD_BUILDING:
            return {
                ...state,
                building: action.payload
            };
        case DELETE_BUILDING:
            return {
                ...state,
                buildings: state.buildings.filter(building => building.id !== action.payload.id)
            };
        case EDIT_BUILDING:
            return {
                ...state,
                isAuthenticated: true,
                buildings: [action.payload]
            };
        default:
            return state;
    }
}
