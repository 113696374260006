import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>REDES DE BOCAS DE INCENDIO EQUIPADAS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Todas las instalaciones nuevas de bocas de incendio equipadas (BIES) deberán ser probadas y certificadas antes de la puesta en servicio y ocupación del edificio conforme al Reglamento de Protección contra incendios RD 513/2017.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas serán dirigidas obligatoriamente por un representante cualificado del instalador autorizado, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos y los medios necesarios para las pruebas serán presentados por el instalador antes del inicio de las mismas para aprobación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                    <ul>
                    <li>Certificado de conformidad a normas EN o UNE de todas las BIES de 25 y 45 mm emitido por Organismo de Control reconocido. </li>
                    <li>Certificado del Factor K de la boquilla o conjunto de la BIE.</li>
                    <li>Certificado de materiales del fabricante para la tubería, accesorios y soportación.</li>
                    <li>Certificados del fabricante de otros materiales (válvulas, interruptores de flujo, etc.)</li>
                    <li>Certificado de homologación de los soldadores y ensayos de soldaduras si aplica.</li>
                    </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba hidráulica:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La totalidad de la instalación se someterá a una prueba de estanqueidad y resistencia mecánica a una presión estática igual a la máxima de servicio y como mínimo 1000 kPa (10 bar), manteniendo dicha presión al menos durante dos horas, no debiendo aparecer fugas en ningún punto de la instalación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión de prueba en las instalaciones de BIES realizadas en la Comunidad de Madrid deberá ser al menos de <strong>1500 kPa (15 bar)</strong> según se indica en el Certificado Oficial de prueba de la Delegación de Industria.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión de prueba será medida en el punto más bajo de la instalación o sector probado, eliminando el aire totalmente en los puntos altos.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se asegurará que todas las válvulas de BIE están cerradas y las válvulas de seccionamiento de la red abiertas. Los manómetros de las BIES estarán colocados o en su defecto se dispondrá de un tapón de rosca equivalente para taponar los extremos de tubería inacabados.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los equipos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Con la instalación llena de agua a la presión de servicio se verificará cada uno de los puntos siguientes:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                    <ul>
                    <li>Se comprobará el estado, accesibilidad y señalización de todas las unidades de BIES instaladas en el edificio, comprobado que son del modelo previsto y están ubicadas en la posición conforme al proyecto y verificando que las cotas son correctas y que todos los puntos del local son protegidos por al menos una BIE.</li>
                    <li>Se verificará que las uniones no presentan ningún tipo de fugas, los racores en el caso de BIES de 45 mm están acoplados y son de modelos normalizados, anotándose la lectura de los manómetros. La presión estática estará comprendida entre 3.5 y 6 bar. </li>
                    <li>Se accionará la boquilla en caso de ser de varias posiciones, limpiando y engrasando los dispositivos de cierre y bisagras de los armarios.</li> 
                    <li>Se desplegará un tramo de manguera en al menos una BIE por planta para comprobar el funcionamiento correcto de las devanaderas giratorias y los dispositivos especiales de guiado de la manguera. </li>
                    <li>Se comprobará cada uno de los dispositivos reductores de la presión para verificar que están instalados correctamente y que operan conforme a lo previsto, anotando las lecturas de las presiones estática y dinámica tanto en la entrada como en la salida. </li>
                    <li>Se abrirá y cerrará cada una de las válvulas de sectorización que dispone el sistema, asegurándose de que están marcadas en los planos e identificadas correctamente con placas grabadas.</li> 
                    <li>Se comprobará que todos los dispositivos de alarma tales como interruptores de flujo, presostatos, etc. están conectados y transmiten correctamente a la central de control mediante prueba real de flujo.</li>
                    <li>Se comprobará si la toma de bomberos en fachada IPF-41 está instalada, verificando que no hay pérdidas de agua por las válvulas, desmontando los tapones de los racores y comprobando que disponen de juntas y orificios de despresurización.</li>
                    </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de funcionamiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El sistema de abastecimiento previsto será probado para verificar el diseño abriendo las dos BIES hidráulicamente más desfavorables, tomando precauciones para el arranque automático y parada manual de las bombas contra incendios.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se conducirán las descargas a un drenaje previamente aceptado para evitar daños, anotando las presiones estáticas y dinámicas obtenidas. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión dinámica mínima será de <strong>2 bar</strong> en el orificio de salida de cualquier BIE. </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba del drenaje principal:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se abrirá la válvula de drenaje principal de la instalación, anotando las presiones estáticas y dinámicas en el manómetro más próximo, verificando que el drenaje está conducido y que la descarga de agua es correcta.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}