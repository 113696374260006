import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Signature from '../components/signature';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO-008</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>PRUEBA CIRCULACIÓN: PUESTA EN SERVICIO BOMBAS</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Técnico" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación del circuito en la instalación" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación del circuito hidráulico al que alimenta" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PUESTA EN SERVICIO GRUPO DE BOMBEO</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El grupo de bombeo instalado se corresponde con el Proyecto o Memoria Técnica?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La ubicación del grupo se corresponde con la del Proyecto o Memoria Técnica?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La ubicación del grupo permite el mantenimiento de las bombas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El grupo de bombeo se ha montado según especifica el Proyecto o Memoria Técnica?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Las bombas están convenientemente soportadas con elementos antivibratorios?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El grupo de bombeo está adecuadamente protegido? (para grupos instalados en el exterior)</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Las tuberías están convenientemente conectadas a la bomba con elementos antivibratorios?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Cada bomba cuenta con válvulas de corte de independencia en la aspiración y en la impulsión?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se han instalado correctamente válvulas antirretorno? (en observaciones, indicar número y tipo)</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Existen tomas que permiten la lectura de la presión de aspiración e impulsión?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Las conexiones eléctricas se han realizado adecuadamente?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La tensión de alimentación es la adecuada para el grupo de bombas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La tensión de alimentación y las protecciones eléctricas son las adecuadas para el grupo de bombas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El circuito se encuentra bien purgado y el filtro está limpio?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El ruido de funcionamiento es adecuado?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El sentido de giro del grupo de bombeo es el correcto?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El sistema de control del grupo funciona correctamente?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>MEDIDAS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Servicio a" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Situada en" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Fabricante/Modelo/No Serie" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Potencia eléctrica nominal grupo (kW)" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Diámetro del rodete (mm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>PROYECT0</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Δp proyecto m.c.a" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal proyecto l/h" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>AJUSTE</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Δp ajuste m.c.a" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Caudal (curva de trabajo adjunta) l/h" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Caudal ajuste l/h" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);