import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import YesNo from '../../components/yes-no';
import Signature from '../../components/signature';
import Submit from '../../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < this.props.json.yes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes.click()
            }
        }
        
        for (let i = 0; i < this.props.json.no.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo.click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>REDES DE SANEAMIENTOS CTE DBHS-001</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>PRUEBAS DE ESTANQUIDAD</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Obra" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Nº Obra" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Nº Ref. ensayo" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Tipo de red" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Material" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Zona de ensayo" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Llenado de red de todos los ramales de saneamiento bajantes y desagües, y taponado de los mismos</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>AGUA</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Presión de prueba" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Fluidos" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caida presión" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Duración" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Resultado" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Incidencias ocurridas durante las pruebas</Trans>
                        </Typography>
                        <TextField fullWidth label="Incidencias" multiline rows={6} variant="outlined" />

                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document}/>
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);