import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid } from '@material-ui/core/';

export default function YesNo(time_used) {
    const num = time_used.time_used
    
    const check_yes = "check_yes_" + num
    const check_no = "check_no_" + num
    const check_obs = "check_obs_" + num
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={<Checkbox name={check_yes} color="primary" />}
                        label="Si"
                    />
                    <FormControlLabel
                        control={<Checkbox name={check_yes} color="primary" />}
                        label="No"
                    />
                </Grid> 
                <Grid item xs={8}>
                    <TextField fullWidth label="Observaciones" variant="outlined" name={check_obs} />
                </Grid>
            </Grid>
        </Fragment>
    )
}