import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA SOLAR</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>VERIFICACIÓN DE LA EJECUCIÓN DEL MONTAJE</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la verificación de la ejecución del montaje.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de la ejecución del montaje</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La verificación de la ejecución del montaje implica la realización de los siguientes procedimientos:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Se comprobará que el modelo de captador instalado se corresponde con el Proyecto o Memoria Técnica, así como su disposición en filas y su conexionado en serie o paralelo.</li>
                            <li>Se comprobará que los captadores solares térmicos se han instalado en la ubicación, inclinación y orientación especificadas en el Proyecto o Memoria Técnica, verificando que la situación es adecuada y que no existen elementos próximos que produzcan sombras que no se tuvieron en consideración en el proyecto.</li>
                            <li>Se comprobará que los captadores solares se encuentran convenientemente anclados (generalmente sobre la cubierta) según Proyecto o Memoria Técnica y siguiendo las recomendaciones del fabricante para evitar que estos puedan desprenderse.</li>
                            <li>Se verificará que la ubicación permite el mantenimiento de la instalación de forma segura, existiendo los elementos de seguridad necesarios, siendo la instalación accesible sin la utilización de escaleras portátiles para la realización del mantenimiento de los distintos elementos instalados en cumplimiento de la IT 1.3.4.4.3 de accesibilidad.</li>
                            <li>Se verificará que se hayan instalado todos los elementos especificados en el esquema de principio de la instalación hidráulica.</li>
                            <li>Se comprobará la existencia de válvulas de corte en cada fila de captadores solares y la instalación de una válvula de seguridad por cada sección del circuito que pueda ser sectorizada y de válvulas de vaciado, estando estas conducidas al depósito de llenado.</li>
                            <li>Se comprobará que el fluido térmico con el que se ha llenado el circuito primario se corresponde con el especificado en el Proyecto o Memoria Técnica. Se recomienda el empleo de agua desmineralizada para el circuito primario solar o, como alternativa, un compuesto recomendado por el fabricante de los colectores con las características apropiadas en cuanto a un bajo punto de congelación (anticongelante) y una inocuidad comprobada respecto de estos. Asimismo, en el caso de colectores planos, y siempre que el fabricante no indique lo contrario, podría utilizarse agua filtrada y tratada adecuadamente (en función de la composición química del agua de la zona), lo que permitiría una reposición controlada en caso de evaporación.</li>
                            <li>Se comprobará, en el caso de emplear fluido caloportador que contenga elementos químicos, que se dispone en el circuito de un depósito para la recogida del fluido caloportador, de las características apropiadas a la composición química del mismo (en ningún caso se eliminará el fluido caloportador a la red de saneamiento).</li>
                            <li>Se comprobará, en el caso de colectores planos que emplean agua filtrada y tratada, que se dispone de un depósito especial donde el agua quedaría acumulada hasta ser nuevamente introducida en el circuito.</li>
                            <li>Se comprobará que la sonda de control de la temperatura se ha instalado correctamente en la parte superior de uno de los captadores, estando este expuesto a la radiación solar en todo momento. Asimismo, la sonda deberá instalarse sumergida en el tubo de salida del agua de la fila de captadores o en en uno de los captadores centrales de la fila (nunca en un extremo).</li>
                            <li>Se comprobará que el resto de sondas de control se hayan instalado en contacto con el circuito hidráulico o en termopozos con sustancia conductora, no permitiendo el uso permanente de sondas de temperatura de contacto, en cumplimiento de la IT 1.3.4.4.5 de medición.</li>
                            <li>Se comprobará que la lectura de las sondas de temperatura de los captadores es correcta.</li>
                            <li>Se comprobará que la centralita está configurada adecuadamente y que su funcionamiento es correcto.</li>
                            <li>Se comprobará que los colectores solares (estructura metálica) están protegidos contra descargas eléctricas.</li>
                        </ul>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}