import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class PlumbingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Ver / Estado',
        data: null,
        action: "run-redirect-dynamic",
        redirect_path: ""
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          const infoURL = {
            48 : 1,
            49 : 2,
            50 : 3,
            51 : 4,
            52 : 5,
            53 : 6,
            54 : 7,
          }
          return '<a href="/' + data.building + '/plato/plumbing/info/' + infoURL[data.id] + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary" target="_blank"><i class="fa fa-info-circle" aria-hidden="true"></i></a>'
        },
      }
    ]
  }
  static propTypes = {
    data: PropTypes.array.isReqd,
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Verificación de la ejecución del montaje', state: 'Secondary', detail: 'Denegado', info: 'Valores inadecuados' },
      // { id: 2, name: 'Reparación y limpieza de tuberías', state: 'Primary', detail: 'aprobado', info: 'Válido hasta 02/06/2023' },
      // { id: 3, name: 'Pruebas de estanquidad y reparación de fugas', state: 'Primary', detail: 'completo', info: 'Falta de firma' },
      // { id: 4, name: 'Comprobación y ajuste de los sistemas de expansión y seguridad', state: 'Secondary', detail: 'pendiente', info: 'Falta de validación' },
      // { id: 5, name: 'Prueba de libre dilatación', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 6, name: 'Llenado del circuito', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 7, name: 'Prueba circulación: Ajuste y equilibrio', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
    ],
  };

  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 7
    this.props.getDocumentTypes(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    const { idBuild } = this.props.match.params
    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    document_types.forEach(e => {
      e.building = idBuild
    });

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, {  getDocumentTypes })(withRouter(PlumbingList));
