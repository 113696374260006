import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import NavItemPlato from '../navItem';
import { Typography, Grid } from '@material-ui/core/';
import State from '../../layout/State';
import Breadcrumbs from '../../layout/breadcrumbs';
import AirList from './airList';


export default function Air() {    
    return (
        <Fragment>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item>
                    <State />
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item xs>
                    <Typography variant="h3" gutterBottom>
                    <Trans>Platón</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                    <Breadcrumbs />
                </Grid>
            </Grid>
            <NavItemPlato />
            <AirList />
        </Fragment>
    )
}