import React, { Fragment } from 'react';
import { TextField, Grid } from '@material-ui/core/';

export default function Metre() {    
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <TextField fullWidth label="Unidad" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Medida" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Previsión" variant="outlined" />
                </Grid>
            </Grid>
        </Fragment>
    )
}