import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { Component, Suspense } from 'react';
import Index from './layout/Index';
import Login from '../components/accounts/Login';
import PrivateRoute from './common/PrivateRoute';
import { Provider } from 'react-redux';
import store from '../store';
import { ToastContainer } from "react-toastify";
//Componentes de usuarios
import Users from '../components/NonameAdmin/users/users'
import NewUser from '../components/NonameAdmin/users/newUser';
import EditUser from '../components/NonameAdmin/users/editUser';
import Logs from '../components/NonameAdmin/audits/audit';
import Alerts from './alerts/alerts';
//Componentes de exta
import Control from '../components/control/control';
import Technics from './socrates/technics/technics';
import Admin from './socrates/docAdmin/admin';
import Installations from './socrates/installations/installations';
import Others from './socrates/docOthers/others';
import Pci from '../components/plato/pci/pci';
import FormPci from '../components/plato/pci/form/form';
import InfoPci from '../components/plato/pci/info/info';
import Air from './plato/air/air';
import FormAir from '../components/plato/air/form/form';
import InfoAir from './plato/air/info/info';
import Electric from './plato/electric/electric';
import FormElectric from '../components/plato/electric/form/form';
import InfoElectric from './plato/electric/info/info';
import Sanit from './plato/sanit/sanit';
import FormSanit from '../components/plato/sanit/form/form';
import InfoSanit from './plato/sanit/info/info';
import Plumbing from './plato/plumbing/plumbing';
import FormPlumbing from '../components/plato/plumbing/form/form';
import InfoPlumbing from './plato/plumbing/info/info';
import AirA from './aristotle/air/air';
import FormAirA from '../components/aristotle/air/form/form';
import InfoAirA from './aristotle/air/info/info';
//Componentes de clientes
import Clients from '../components/clients/clients';
import NewClient from '../components/clients/newClient';
import EditClient from '../components/clients/editClient';
//Componentes de edificios
import Building from '../components/buildings/buildings';
import NewBuilding from '../components/buildings/newBuilding';
import EditBuilding from '../components/buildings/editBuilding';

//Componente de Validacion
import Validation from "./validation/validation";
import validationForm from './validation/validationForm';

import SelectBuilding from "./buildings/selectBuilding"
import AdminPanel from "./NonameAdmin/admin/adminpanel"

const Register = () => (
    <div className="App">
        <div>Register...</div>
    </div>
);
const Loader = () => (
    <div className="App">
        <div>loading...</div>
    </div>
);

class App extends Component {
    componentDidMount() {
    }

    render(){
        return (
            <Router>
                <ToastContainer autoClose={2000} pauseOnHover={false} pauseOnFocusLoss={false} />
                <Suspense fallback={<Loader />}>
                    <Provider store={store}>
                            <Switch>
                                <Route exact path="/" component={Login} />
                                <PrivateRoute exact path="/users" component={Users, Index} />
                                <PrivateRoute exact path="/newuser" component={NewUser, Index} />
                                <PrivateRoute exact path="/myuser" component={EditUser, Index} />
                                <PrivateRoute exact path="/edituser/:id" component={EditUser, Index} />
                                <PrivateRoute exact path="/logs" component={Logs, Index} />
                                <PrivateRoute exact path="/alerts" component={Alerts, Index} />
                                <PrivateRoute exact path="/:idBuild/control" component={Control, Index} />
                                <PrivateRoute exact path="/:idBuild/socrates/technics" component={Technics, Index} />
                                <PrivateRoute exact path="/:idBuild/socrates/docadmin" component={Admin, Index} />
                                <PrivateRoute exact path="/:idBuild/socrates/installations" component={Installations, Index} />
                                <PrivateRoute exact path="/:idBuild/socrates/others" component={Others, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/pci" component={Pci, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/pci/form/:id" component={FormPci, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/pci/info/:id" component={InfoPci, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/air" component={Air, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/air/form/:id" component={FormAir, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/air/info/:id" component={InfoAir, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/electric" component={Electric, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/electric/form/:id" component={FormElectric, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/electric/info/:id" component={InfoElectric, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/sanit" component={Sanit, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/sanit/form/:id" component={FormSanit, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/sanit/info/:id" component={InfoSanit, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/plumbing" component={Plumbing, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/plumbing/form/:id" component={FormPlumbing, Index} />
                                <PrivateRoute exact path="/:idBuild/plato/plumbing/info/:id" component={InfoPlumbing, Index} />
                                <PrivateRoute exact path="/:idBuild/aristotle/air" component={AirA, Index} />
                                <PrivateRoute exact path="/:idBuild/aristotle/air/form/:id" component={FormAirA, Index} />
                                <PrivateRoute exact path="/:idBuild/aristotle/air/info/:id" component={InfoAirA, Index} />
                                <PrivateRoute exact path="/buildings" component={SelectBuilding, Index} />
                                <PrivateRoute exact path="/adminpanel/validation" component={Validation, Index} />
                                <PrivateRoute exact path="/adminpanel/validation/form" component={validationForm, Index} />
                                <PrivateRoute exact path="/adminpanel" component={AdminPanel, Index} />
                                <PrivateRoute exact path="/adminpanel/clients" component={Clients, Index} />
                                <PrivateRoute exact path="/adminpanel/newclient" component={NewClient, Index} />
                                <PrivateRoute exact path="/adminpanel/editclient/:id" component={EditClient, Index} />
                                <PrivateRoute exact path="/adminpanel/buildings" component={Building, Index} />
                                <PrivateRoute exact path="/adminpanel/newbuilding" component={NewBuilding, Index} />
                                <PrivateRoute exact path="/adminpanel/editbuilding/:id" component={EditBuilding, Index} />
                            </Switch>
                    </Provider>
                </Suspense>
            </Router>
        );
    }
};

export default App;
