import { GET_USERS, GET_USER, ADD_USER, EDIT_USER, DELETE_USER, GET_PERMISSION } from '../actions/types';

const initialState = {
    users: [],
    permissions: [],
    user_edit: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            };
        case ADD_USER:
            return {
                ...state,
                users: [...state.users, action.payload]
            }
        case GET_USER:
        case EDIT_USER:
            return {
                ...state,
                user_edit: action.payload
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload.id)
            }
        case GET_PERMISSION:
            return {
                ...state,
                permissions: action.payload
            };
        default:
            return state;
    }
}