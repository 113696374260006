import React, { Fragment } from 'react';
import { TextField, Grid } from '@material-ui/core/';

export default function Load() {    
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={4}>
                <Grid item xs={4}>
                    <TextField fullWidth label="25%" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="50%" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="75%" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="100%" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="125%" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Observaciones" variant="outlined" />
                </Grid>
            </Grid>
        </Fragment>
    )
}