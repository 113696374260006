import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid } from '@material-ui/core/';

export default function Signature() {    
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6}>
                    <TextField fullWidth label="Nombre del técnico" variant="outlined" />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Fecha" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Firma empresa instaladora" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Firma constructora" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth label="Firma C. calidad" variant="outlined" />
                </Grid>
            </Grid>
        </Fragment>
    )
}