import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>COLUMNAS SECAS</Trans>
            </Typography>
            <Container>
              <Typography variant="body1" gutterBottom>
                  <Trans>Todas las instalaciones nuevas de columnas secas deberán ser probadas y certificadas antes de la puesta en servicio y ocupación del edificio. </Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las pruebas serán realizadas obligatoriamente por un representante cualificado del instalador, anotando los resultados en los protocolos de pruebas adjuntos. Los procedimientos para las pruebas serán presentados para aprobación antes del inicio de las mismas.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Certificados de materiales:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Certificado de los racores conforme a normas UNE 23.400 y 23.091.</li>
                    <li>Certificado de materiales del fabricante de la tubería.</li>
                    <li>Certificado del galvanizado de la tubería.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Prueba hidráulica:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>El sistema de columna seca se someterá a una prueba de estanqueidad y resistencia mecánica a una presión estática de 1500 kPa (15 bar), manteniendo dicha presión durante dos horas como mínimo, no debiendo aparecer fugas en ningún punto de la instalación.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>La presión de prueba en las instalaciones realizadas en la Comunidad de Madrid deberá ser de <strong>20 bar</strong> según el Reglamento de Prevención de Incendios Decreto 341/1999 de 23 de diciembre, siendo medida en el punto más bajo de la instalación.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se asegurará que todas las válvulas de seccionamiento estén abiertas y las siamesas de las bocas cerradas, eliminando el aire totalmente por la válvula de expansión colocada en la parte superior.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Verificación de los equipos:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Con la instalación vacía verificar cada uno de los puntos siguientes:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se comprobará el estado y accesibilidad de la toma en fachada (IPF-41) y todas las bocas de salida en pisos (IPF-39 / IPF-40) instaladas en el edificio, verificándose que las hornacinas están limpias y libres de objetos. Las tapas y cristales deberán estar íntegros con sus rótulos de “uso exclusivo de bomberos” y abatiéndose cada una de las tapas y comprobar que abren con facilidad y no hay oxidaciones.</li>
                    <li>Se abrirá y cerrará la válvula de purga DN 25 situada en la conexión siamesa de la toma de fachada.</li>
                    <li>Se operará cada una de las válvulas de bola de las conexiones siamesas para comprobar su actuación y que las palancas giren en todo su recorrido, evitándose la producción de movimientos o vibraciones del conjunto y verificando que todas las tapas de los racores salen con facilidad y tienen junta y cadenilla.</li>
                    <li>Se cerrarán y abrirán todas las llaves de seccionamiento DN 80 tipo bola instaladas cada cuatro plantas en la columna, verificándose que operan normalmente y que las palancas no chocan con ningún obstáculo.</li>
                    <li>Se verificará el estado de la válvula de expansión de aire en la parte superior.</li>
                    <li>Se asegurará que está perfectamente alineado y que las partes móviles están libres de obstrucciones.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
              </Typography>
            </Container>
        </Fragment>
    )
}