import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class AirList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Ver / Estado',
        data: null,
        action: "run-redirect-dynamic",
        redirect_path: ""
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          const infoURL = {
            84 : 1,
            85 : 2,
            86 : 3,
            87 : 4,
            88 : 5,
            89 : 6,
            90 : 7,
            91 : 8,
            92 : 9,
          }
          return '<a href="/' + data.building + '/aristotle/air/info/' + data.id + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary" target="_blank"><i class="fa fa-info-circle" aria-hidden="true"></i></a>'
        },
      }
    ]
  }
  static propTypes = {
    document_types: PropTypes.array,
    getDocumentTypes: PropTypes.func,
    isLoading: PropTypes.bool
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Diagnóstico en operación de motores de fancoils y utas', state: 'Secondary', detail: 'Denegado', info: 'Valores inadecuados' },
      // { id: 2, name: 'Diagnóstico en operación de grupos de frío', state: 'Primary', detail: 'aprobado', info: 'Válido hasta 02/06/2023' },
      // { id: 3, name: 'Diagnóstico en operación de bombas de calor', state: 'Primary', detail: 'completo', info: 'Falta de firma' },
      // { id: 4, name: 'Diagnóstico en operación local CPD', state: 'Secondary', detail: 'pendiente', info: 'Falta de validación' },
      // { id: 5, name: 'Diagnóstico en operación de oficinas', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 6, name: 'Diagnóstico en operación de quirófano', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 7, name: 'Diagnóstico en operación de tratamiento de aire', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 8, name: 'Diagnóstico en operación de grupos de bombeo', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 9, name: 'Diagnóstico en operación de calderas', state: 'Secondary', detail: 'incompleto', info: 'Faltan datos' },
    ],
  };
  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 10
    this.props.getDocumentTypes(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    const { idBuild } = this.props.match.params
    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    document_types.forEach(e => {
      e.building = idBuild
    });

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(AirList));
