import { LOAD_ALERTS, READ_ALERT } from '../actions/types';

const initialState = {
    alerts: [],
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOAD_ALERTS:
            return {
                ...state,
                alerts: action.payload
            };
        case READ_ALERT:
            return {
                ...state,
                alerts: state.alerts.filter(alert => alert.id !== action.payload.id)
            };
        default:
            return state;
    }  
}
