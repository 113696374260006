import { LOAD_MODULES } from '../actions/types';

const initialState = {
    modules: [],
};

export default function(state = initialState, action) {
    switch(action.type) {
        case LOAD_MODULES:
            return {
                ...state,
                modules: action.payload
            };
        default:
            return state;
    }  
}
