import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Button, ButtonGroup, Grid, Paper, Link, Container, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core/';
import { loadClients } from '../../actions/clients';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '120px',
        borderRadius: '1rem',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
    root: {
        paddingBottom: theme.spacing(5),
    },
    heading: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5rem',
        textAlign: 'center',
        opacity: '0.5',
    },
    accordionNav: {
        padding: '0.5rem',
    },
    noPadding: {
        padding: '8px 0px 0px',
    }
});

/**Componente para la gestión de edificios. */
export class SelectBuilding extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: []
        }
    }

    /**Declaración de los PropTypes */
    static propTypes = {
        // loadConfig: PropTypes.func.isRequired
    }

    /**
     * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los edificios
     * existentes en ese momento.
     */
    componentDidMount() {
        this.props.loadClients();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                clients: this.props.clients
            })
        }
    }

    render() {
        const { classes } = this.props;
        const { clients } = this.state
        return (
            <div className={classes.root}>
                {clients.map((client, i) => {
                    return <Accordion className={classes.accordionNav}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}><Trans>Cliente: {client.name}</Trans></Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.noPadding}>
                            <Container>
                                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                                    {client.building_assigned.map((item, i) => {
                                        return <Grid item>
                                            <Link href={`${item.id}/control`}>
                                                <Paper className={classes.paper} elevation={2}>
                                                    <BusinessIcon /> <br />
                                                    <Typography variant='caption' align='center'><Trans>{item.name}</Trans></Typography>
                                                </Paper>
                                            </Link>
                                        </Grid>
                                    })}
                                </Grid>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                })}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    clients: state.clients.clients,
});

export default compose(connect(mapStateToProps, { loadClients }), withStyles(useStyles),)(SelectBuilding);
