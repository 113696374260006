import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Signature from '../components/signature';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>PUESTA EN MARCHA INSTALACIÓN DE FONTANERÍA-005</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>PRUEBA DE LIBRE DILATACIÓN</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PRUEBA LIBRE DILATACIÓN</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha llevado la instalación a temperatura de tarado de los elementos de seguridad?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha enfriado la instalación y comprobado visualmente que no hayan tenido lugar deformaciones?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);