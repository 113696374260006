import React, { Fragment, Component } from 'react';
import Top from '../layout/Top';
import Nav from './nav';
import  { loadBuilding } from '../../actions/building';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
export class Control extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modules: []
        }
    }

    componentDidMount(){
        this.props.loadBuilding(this.props.match.params.idBuild)
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props){
            this.setState({
                modules: this.props.building.modules_assigned
            })
        }
    }

    render() {
        const { modules } = this.state
        return (
            <Fragment>
                <div style={{ marginBottom: '4rem' }}>
                    <Top modules={modules}/>
                </div>
                <Nav modules={modules}/>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    building: state.buildings.building
});

export default connect(mapStateToProps, { loadBuilding })(Control);