import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>EXTINCIÓN CON AGENTES GASEOSOS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Todos los sistemas nuevos de extinción de incendios mediante agentes gaseosos HFC 227 ea (FM-200) y FE-13 deberán ser certificados y probados antes de su puesta en servicio para garantizar su correcto funcionamiento e instalación, conforme al Reglamento de Protección contra incendios RD 513/2017 y Norma UNE 15.004.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas serán realizadas obligatoriamente por un representante cualificado del instalador autorizado, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos y los medios necesarios para las pruebas realizados a partir de instrucciones de los fabricantes serán presentados por el instalador antes del inicio de las mismas para aprobación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Certificado de conformidad a normas UNE, CEPREVEN, NFPA, etc. de la instalación realizada.</li>
                        <li>Certificado de Aprobación del agente extintor y componentes de la instalación.</li>
                        <li>Certificado de prueba del MIE de todos los cilindros piloto y de reserva.</li>
                        <li>Certificado de prueba hidrostática del fabricante del colector de descarga.</li>
                        <li>Certificado de materiales del fabricante para la tubería, accesorios y soportación.</li>
                        <li>Certificado de homologación de los soldadores y ensayos de soldaduras si aplica.</li>
                        <li>Cálculos por ordenador de las tuberías y difusores para cada sistema, firmados y/o sellados por el fabricante del equipo. Se deberá indicar el programa empleado, la cantidad de gas, concentración de diseño, el tiempo de descarga y el número de difusores y cilindros reserva.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba neumática e hidráulica:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Toda la tubería abierta se probará neumáticamente en un circuito cerrado durante 10 minutos a una presión mínima de 3 bar. Al cabo de 10 minutos la pérdida de presión no deberá ser superior al 20% de la presión de prueba.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Los tramos de tubería que estén permanentemente presurizados o puedan quedar con presión por existir válvulas direccionales se probarán hidrostáticamente a una presión mínima de 1,5 veces la presión máxima de trabajo. Al cabo de 2 minutos no se deberá observar ninguna fuga. Al terminarse la prueba se purgará la tubería para eliminar la humedad. Esta prueba puede omitirse si el instalador presenta el certificado de ensayo realizado en el taller del fabricante.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba de presión neumática se realizará con las debidas precauciones, sin personas en la sala y garantizando la seguridad de los operarios que realizan las pruebas.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los sistemas:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se comprobará que las dimensiones y condiciones del riesgo protegido corresponden exactamente con lo indicado en proyecto y los cálculos. </li>
                        <li>Se comprobará la integridad del recinto, cerrándose cualquier fuga significativa de aire que pudiera impedir mantener la concentración mínima durante el tiempo especificado y realizándose prueba de estanqueidad del recinto en caso necesario conforme al anexo E de la norma UNE 23.570.</li>
                        <li>Se prestará atención a elementos estructurales, placas de falso techo, pantallas eléctricas, conductos u otros elementos que cuelguen del techo. Deberán estar firmemente sujetos y separados de los difusores.</li>
                        <li>Se comprobarán las distancias de seguridad a los elementos en tensión en caso de riesgos eléctricos.</li>
                        <li>Se comprobará que los difusores se corresponden con lo indicado en cálculos anotando el modelo, orificio y cantidad utilizada. Se verificará que las descargas no se producen directamente sobre elementos que puedan ser movidos o sobre personas.</li>
                        <li>Se comprobarán las botellas de cada batería. El número y capacidad se debe corresponder con lo determinado en los cálculos. Se comprobará también los dispositivos de seguridad, los manguitos y todas las conexiones roscadas y que están timbradas por el MIE y tienen las etiquetas con los datos básicos del gas, carga e instrucciones. Se anotará el año de fabricación y la presión del manómetro de cada cilindro.</li>
                        <li>Se comprobarán las tuberías en todo su recorrido, verificando que corresponden a lo indicado en cálculos y planos en cuanto a diámetros y trazado y que está debidamente soportada y puesta a tierra. Se comprobará meticulosamente las uniones roscadas, atornilladas y/o soldadas. La pintura será uniforme y del color determinado en proyecto.</li>
                        <li>Se comprobará que los dispositivos eléctricos de alarma tales como detectores de incendio, pulsadores de disparo, avisadores acústicos, avisadores exteriores, etc. están conectados y transmiten correctamente a la central de control. </li>
                        <li>Se comprobará que las puertas de acceso estarán cerradas en la descarga y abren hacia el exterior, y los carteles de instrucciones al personal.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de funcionamiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La comprobación del funcionamiento de los sistemas incluirá la prueba completa de todos los equipos que forman la instalación sin descarga real del GAS. Se tomarán las medidas adecuadas para evitar disparos no deseados desconectando las bobinas de las electroválvulas.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas se realizarán siempre con alimentación de red en la central de detección y posteriormente se repetirán con alimentación de emergencia (baterías).</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Funcionamiento automático:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se comprobará la actuación de los detectores de incendio previstos como se indique en el protocolo de detección correspondiente. Al menos deberán activarse dos sensores para el Disparo Automático. </li>
                        <li>Se comprobará que se activan por este orden los siguientes elementos: 
                            <ul>
                              <li>Alarma acústica de evacuación.</li>
                              <li>Cierre de puertas y CCF.</li>
                              <li>Para de equipos AA.</li>
                              <li>Apertura de la válvula direccional (si la hubiese).</li>
                              <li>Disparo de la electroválvula (retardo de 20 segundos)</li>
                              <li>Avisador exterior de la descarga.</li>
                            </ul> 
                        </li>          
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Funcionamiento manual:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se rearmará el sistema totalmente y se repetirá la actuación mediante los dispositivos de disparo manual previstos.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hojas de toma de datos.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}