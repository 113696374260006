import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Metre from '../components/metre';
import Signature from '../components/signature';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO-003</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBAS DE ESTANQUIDAD Y REPARACIÓN DE FUGAS</Trans>
            </Typography>
            <Container>
                <form className={classes.root}>
                    <Typography variant="h5" gutterBottom>
                    <Trans>Datos Generales</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Cliente" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Técnico" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Material de la Tuberías" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Identificación del circuito en la instalación" variant="outlined" />
                        </Grid>      
                    </Grid>
                    <Typography variant="h5" gutterBottom>
                    <Trans>PRUEBAS DE ESTANQUIDAD Y REPARACIÓN DE FUGAS</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se han realizado las pruebas de presión en las tuberías?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se han cerrado los terminales abiertos del circuito hidráulico?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se ha realizado una prueba preliminar de estanqueidad a baja presión?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se ha realizado la prueba de resistencia mecánica?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se han reparado las fugas derectadas y sustituido la parte defectuosa si las hubiese?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="body1" gutterBottom>
                    <Trans>¿Se han repetido las pruebas de estanqueidad si se hubiesen reparado anomalías?</Trans>
                    </Typography>
                    <YesNo />
                    <Typography variant="h5" gutterBottom>
                    <Trans>Medidas</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Fluido" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Lado de prueba" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Clase de tubería" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Fluido de prueba" variant="outlined" />
                        </Grid>
                    </Grid>
                    <Typography variant="body1" gutterBottom>
                    <Trans>MEDIDAS REALIZADAS EN LA TUBERÍA</Trans>
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                    <Trans>Temperatura de diseño</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="caption" gutterBottom>
                    <Trans>Temperatura máxima de trabajo</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="caption" gutterBottom>
                    <Trans>Presión de diseño</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="caption" gutterBottom>
                    <Trans>Presión máxima de trabajo</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="body1" gutterBottom>
                    <Trans>MEDIDAS REALIZADAS EN EL FLUIDO</Trans>
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                    <Trans>Temperatura del fluido de prueba</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="caption" gutterBottom>
                    <Trans>Presión de prueba</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="caption" gutterBottom>
                    <Trans>Duración de la prueba</Trans>
                    </Typography>
                    <Metre />
                    <Typography variant="h5" gutterBottom>
                    <Trans>Resultado de la actividad</Trans>
                    </Typography>
                    <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined"  />
                    <Typography variant="h5" gutterBottom>
                    <Trans>Conformidad</Trans>
                    </Typography>
                    <Signature />
                    <Submit document={this.props.document} />
                </form>
            </Container>
        </Fragment>
    )
}
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);