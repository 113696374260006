import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import Top from './Top';
import BarChartIcon from '@material-ui/icons/BarChart';
import { Drawer, Button } from '@material-ui/core/';

const useStyles = makeStyles(theme => ({
    list: {
        width: 250,
        paddingTop: theme.spacing(5),
      },
      fullList: {
        width: "auto",
      },
  }));


export default function State() {
    const classes = useStyles();
    const [state, setState] = React.useState({
      top: false
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <div
        className={clsx(classes.list, {
          [classes.fullList]: anchor === "top" || anchor === "bottom"
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Top />
      </div>
    );
    return (
        <div>
            {["Estado actual"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button variant="contained" right color='primary' onClick={toggleDrawer(anchor, true)}> <BarChartIcon /> {anchor}</Button>
                    <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    )
}