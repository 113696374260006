import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>EXTINTORES PORTÁTILES Y DE CARRO</Trans>
            </Typography>
            <Container>
              <Typography variant="body1" gutterBottom>
                    <Trans>Todos los extintores portátiles y de carro instalados en los edificios para protección contra incendios deberán ser comprobados y certificados antes de la puesta en servicio y ocupación del mismo conforme al Reglamento de Protección contra incendios RD 513/2017.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones serán realizadas obligatoriamente por un representante cualificado del instalador, anotando los resultados en los protocolos de pruebas aplicables. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Certificado de conformidad a normas EN o UNE de cada tipo instalado emitido por Organismo de Control reconocido.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los equipos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                      <li>Se comprobará el tipo, situación y eficacia de todos los extintores instalados en el edificio que se deben corresponder con lo indicado en las especificaciones y planos definitivos del proyecto. Estarán fácilmente visibles y accesibles sin obstáculos y debidamente señalizados con placas normalizadas.</li>
                      <li>Se verificará en cada unidad su estado de conservación y que tienen todas las etiquetas del fabricante, características, instrucciones de operación y precintos de seguridad colocados. </li>
                      <li>Se comprobará que el manómetro marca la presión correcta, se encuentra cargado y dispone de placa de timbre o marca de la Delegación de Industria. La fecha de fabricación será actual, rechazándose las unidades que no cumplan estas condiciones.</li>
                      <li>Se verificará que la manguera y la boquilla de descarga están perfectamente acopladas al extintor con especial atención a los de anhídrido carbónico (CO2).</li>
                      <li>El soporte estará fijado al menos por dos tornillos en la pared con la parte superior del extintor a 1,70 m máximo del suelo.</li>
                      <li>En montajes en interior de armarios se verificará la integridad de vidrios y puertas, limpiándose y engrasándose todos los dispositivos de cierre y las bisagras y eliminándose cualquier resto visible de obra.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}