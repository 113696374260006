import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Container, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core/';
import NavItemPlato from './navItem';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
      },
      heading: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5rem',
        textAlign: 'center',
        opacity: '0.5',
      },
      accordionNav: {
          padding: '0.5rem',
      },
      noPadding: {
        padding: '8px 0px 0px',
      }
  }));

export default function NavPlato() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Accordion className={classes.accordionNav}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}><Trans>Platón</Trans></Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.noPadding}>
            <Container>
                <NavItemPlato />
            </Container>
            </AccordionDetails>
            </Accordion>
        </div>
    )
}