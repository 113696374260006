import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMAS DE AIRE</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBAS DE CIRCULACIÓN REDES DE AIRE</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas de circulación de la red de conductos.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de circulación de la red de conductos</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La red de conductos, en cumplimiento de la IT 2.3.2 “Ajuste y equilibrado: Sistemas de distribución y difusión de aire” Pruebas de recepción de redes de conductos de aire”, se someterá a un proceso de ajuste a fin de que las unidades terminales estén ajustadas a sus caudales nominales, siguiendo el siguiente procedimiento:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Se comprobará que las unidades terminales de impulsión y retorno, rejillas y difusores instalados se corresponden con las especificadas en el Proyecto o Memoria Técnica. Asimismo, se comprobará que el nivel de ruido en los locales producido en los elementos de difusión de aire y en los conductos es el adecuado, realizándose en caso contrario las medidas de ruido correspondientes.</li>
                            <li>Se comprobará que el interior de las máquinas esté limpio, que los filtros hayan sido limpiados o sustituidos, que no existe objetos en su interior, que los alabes o palas de ellos giran libremente sin roces u otras anomalías que pudieran deteriorarlos. Asimismo, se comprobará la incorporación de un interruptor de corte de energía eléctrica para seguridad de los operarios en las fases de mantenimiento.</li>
                            <li>Se pondrá en funcionamiento los ventiladores correspondientes, efectuando las siguientes mediciones en cada circuito, así como en ramales y unidades terminales, con los elementos de regulación abiertos y los ventiladores en la velocidad indicada en el Proyecto o Memoria Técnica:
                                <ol>
                                    <li>Se medirá la temperatura de salida del aire, la temperatura de cada local y velocidad del aire en la salida, permitiendo estos datos comprobar si alguna de las unidades recibe un caudal de aire por debajo del nominal</li>
                                    <li>Se medirá la presión de los ventiladores.</li>
                                    <li>Se realizará lectura de la intensidad eléctrica por fase de los motores.</li>
                                </ol>
                            </li>
                            <li>Se ajustará los caudales hasta conseguir los previstos en proyecto mediante medida de la velocidad del aire en puntos convenientes elegidos y actuación sobre las compuertas y elementos de regulación.</li>
                            <li>Se comprobará la difusión del aire en espacios acondicionados mediante ensayos de humos.</li>
                            <li>Se repetirá las mediciones comprobando los resultados con las curvas de funcionamiento facilitadas por el fabricante.</li>
                        </ol>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>En los locales donde se controle la presión diferencial se ajustará esta mediante actuaciones sobre los elementos de regulación de los caudales de impulsión y extracción de aire manteniendo constante la presión en el conducto, adaptando su punto de trabajo a las variaciones de presión diferencial mediante un dispositivo adecuado.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}