import { CREATE_MESSAGE, GET_ERRORS } from './types';


/**Función que controla la creación de mensajes de éxito para las alertas de PNotify */
export const createMessage = msg => {
    return {
        type: CREATE_MESSAGE,
        payload: msg
    };
};

/**Función que controla la creación de mensajes de error para las alertas de PNotify */
export const returnErrors = (msg, status) => {
    return {
        type: GET_ERRORS,
        payload: { msg, status }
    }
}