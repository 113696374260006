import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>ELECTRICIDAD</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>EJECUCIÓN Y MEDICIÓN DE PUESTA A TIERRA</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento describe la red de puesta a tierra del edificio, compuesta por los siguientes electrodos:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Red general de puesta a tierra de la estructura (B.T.) que debe tener un VALOR DE RESISTENCIA INFERIOR A 2 OHMIOS (2Ω).</li>
                            <li>Electrodo del Neutro del Transformador, con un VALOR DE RESISTENCIA INFERIOR A 10 OHMIOS (10Ω).</li>
                            <li>Electrodo del Neutro de los alternadores de los GRUPOS ELECTRÓGENOS, con un VALOR DE RESISTENCIA INFERIOR A 10 OHMIOS (10Ω).</li>
                            <li>Electrodo de la bajante del PARARRAYOS, con un VALOR DE RESISTENCIA INFERIOR A 10 OHMIOS (10Ω).</li>
                        </ul>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Es necesario medir la Resistividad del Terreno (ρ) por el método Wenner (por ejemplo) para poder estimar la resistencia de los electrodos (Ω) con las configuraciones representadas en planos.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Red general de puesta a tierra de estructura (B.T).</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Bajo la losa de cimentación se dispondrá de una red en forma de anillo enterrado (según disposición indicada en planos), formada por un conductor de cobre desnudo de 50mm2, enterrado a 80cm. por debajo de la losa. Este anillo de puesta a tierra se complementará con picas de acero cobrizado de 2m. de longitud, conectadas al anillo de cobre desnudo de 50mm2.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El valor de la resistencia de puesta a tierra, medido en todos los latiguillos, debe arrojar el mismo valor para garantizar la continuidad del anillo ejecutado, siendo este valor inferior a 2Ω.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>En el sótano 4 se procederá a conectar todos los pilares y muros con una red de puesta a tierra vista por el techo del sótano 4, formada por una varilla de cobre de 8mm de diámetro, con las piezas de conexión entre barras, ángulos, soportación y enlace con los latiguillos adecuados.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>La conexión entre la ferralla de la estructura y la red vista de puesta a tierra (varilla de Ø8mm) se realizará mediante soldaduras aluminotérmicas, disponiéndose de un latiguillo de conexión en cobre. La soldadura aluminotérmica se ejecutará de manera simultánea a la ferralla de la estructura y ANTES DE EL ENCOFRADO DE LOS PILARES Y MUROS, dejando previsto el latiguillo de interconexión saliente antes de hormigonar, evitándose así tener que descarnar los pilares a posteriori.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Dado el carácter exotérmico de la soldadura aluminotérmica se tomarán las medidas de prevención pertinentes para evitar accidentes en su ejecución.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>En el techo del sótano 4, se procederá a la conexión entre el anillo enterrado bajo la losa y la red vista de conexión de la estructura ejecutada en el techo del propio sótano 4.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Electrodos de neutros de transformadores y electrodo de neutro de los alternadores de los Grupos Electrógenos</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Bajo la losa de cimentación se dispondrá de un electrodo independiente por cada uno de los neutros de los transformadores (3 uds.) y otro para el conjunto de los neutros de los alternadores de los Grupos Electrógenos (1 ud).</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Todos estos electrodos se ejecutarán mediante una configuración en hilera formada por picas de acero cobrizado de 4 metros de longitud (tantas como sean necesarias para conseguir el valor objetivo), interconectadas entre sí mediante cable de cobre desnudo de 50mm2 y terminada en un cable de 120mm2 aislado (tipo RV) que llegará hasta el sótano 4, mediante un pasatubos que atravesará la losa de cimentación.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Electrodos de bajantes de pararrayos</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Bajo la losa se dispondrá de un electrodo para cada bajante de los pararrayos (2 uds.), situados en fachadas opuestas y con una configuración en triángulo. Cada una de las picas será de acero cobrizado y 4 metros de longitud (tantas como sean necesarias para conseguir el valor objetivo). Las picas se interconectarán entre sí mediante cable de cobre desnudo de 50mm2 y el electrodo se terminará en un cable de 120mm2 aislado (tipo RV) que llegará hasta el sótano 4 mediante un pasatubos que atravesará la losa de cimentación. En caso de obtener un valor de resistencia de puesta a tierra inferior al objetivo, se ejecutarán más picas con configuración en triángulo.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Electrodo de Media Tensión</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>De forma aislada y a una distancia superior a 15 metros del resto de electrodos y estructura, se ejecutará el electrodo de Media Tensión (M.T.), todo bajo la losa de cimentación.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Este electrodo se ejecutará mediante una configuración en hilera formada por picas de acero cobrizado de 4 metros de longitud (tantas como sean necesarias para conseguir el valor objetivo), interconectadas entre sí mediante cable de cobre desnudo de 50mm2 y terminada en un cable de 120mm2 aislado (tipo RV) que llegará hasta el sótano 4, mediante un pasatubos que atravesará la losa de cimentación.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}