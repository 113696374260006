import axios from 'axios';
import { ADD_CLIENT, GET_CLIENT, LOAD_CLIENTS, DELETE_CLIENT, EDIT_CLIENT } from './types';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

// GET CLIENT
export const getClient = (id) => (dispatch, getState) => {
    axios.get(`/api/v1/exta/client/${id}`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_CLIENT,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// CREATE CLIENT
export const createNewClient = (newClient) => (dispatch, getState) => {
    axios
        .post('/api/v1/exta/client/', newClient, tokenConfig(getState))
        .then(res => {
            toast.success(`Se ha creado el cliente: ${res.name} con éxito`)
            dispatch({
                type: ADD_CLIENT,
                payload: res.data
            })
        })
        .catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: REGISTER_FAIL
            });
        });
};

// LOAD CLIENT
export const loadClients = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/client/', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOAD_CLIENTS,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// DELETE CLIENT
export const deleteClient = (id) => (dispatch, getState) => {
    axios
        .delete(`/api/v1/exta/client/${id}`, tokenConfig(getState))
        .then(res => {
            toast.success(`Se ha borrado el cliente con éxito`)
            dispatch({
                type: DELETE_CLIENT,
                payload: res.data.data
            })
        }).catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};

// EDIT CLIENT,
export const editClient= (client) => (dispatch, getState) => {
    axios
        .put(`/api/v1/exta/client/${client.id}`, client, tokenConfig(getState))
        .then(res => {
            toast.success(`Cliente editado con éxito`)
            dispatch({
                type: EDIT_CLIENT,
                payload: res.data
            })
        }).catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};
