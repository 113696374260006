import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { loadUsers, deleteUser } from '../../../actions/users';
import Table from '../../layout/Table';
import { Typography, Button, ButtonGroup, Grid } from '@material-ui/core/';
import EditUser from '../../../components/NonameAdmin/users/editUser';
import ReactDOM from 'react-dom'

/**Componente para la gestión de usuarios. */
export class UsersList extends Component {

  constructor(props) {
    super(props);
    /**
     * Se crean las columnas para la tabla que mostrará los usuarios creados hasta el momento.
     */
    this.columns = [
      {
        title: 'ID',
        data: 'id'
      },
      {
        title: 'Usuario',
        data: 'username'
      },
      {
        title: 'Nombre',
        data: 'first_name'
      },
      {
        title: 'Apellido',
        data: 'last_name'
      },
      {
        title: 'Email',
        data: 'email'
      },
      {
        title: 'Cliente',
        data: 'client_name'
      },
      {
        title: 'Admin Cliente',
        data: 'user_type',
        render: function (data) {
          if (data == 'admin') {
            return '<a class="btn MuiButton-textPrimary" disable><i class="fa fa-check" aria-hidden="true"></i></a>'
          } else {
            return ''
          }
        }
      },
      {
        title: 'Admin Aplicación',
        data: 'is_superuser',
        render: function (data) {
          if (data == true) {
            return '<a class="btn MuiButton-textPrimary" disable><i class="fa fa-check" aria-hidden="true"></i></a>'
          } else {
            return ''
          }
        }
      },
      {
        title: 'Editar',
        data: 'id',
        action: 'edit',
        f_action: editUser.bind(this)
      },
      {
        title: 'Borrar',
        data: 'id',
        action: 'delete',
        f_action: deleteUser.bind(this)
      }
    ]

    function deleteUser(e, rowData) {
      this.props.deleteUser(rowData.id)
    }
    function editUser(e, rowData) {
      window.location.href = `/edituser/${rowData.id}`;
    }

    this.state = {
      users: [],
      user: []
    }

  }

  /**Declaración de los PropTypes */
  static propTypes = {
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired,
    user: PropTypes.array.isRequired
  }


  /**
   * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los usuarios
   * existentes en ese momento.
   */
  componentDidMount() {
    this.props.loadUsers();
  }

  componentDidUpdate(prevProvs) {
    if (this.props !== prevProvs) {
      this.setState({
        users: this.props.users,
        user: this.props.user
      })
    }
  }
  render() {
    const { users, user } = this.state

    if (user.is_superuser == false && user.user_type != "admin") {
      return <EditUser />;
    }

    return (
      <div className="root" >
        <Typography variant="h3" gutterBottom>
          <Trans>Gestión de usuarios</Trans>
        </Typography>
        <Grid container direction="row" justify="flex-end" alignItems="flex-end">
          <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
            <Button type="button" color="primary" component={Link} to="/newuser"><Trans>Nuevo Usuario</Trans></Button>
          </ButtonGroup>
        </Grid>
        <Table
          title=""
          columns={this.columns}
          data={users}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // Recoger del usersReducer el state
  users: state.users.users,
  user: state.auth.user,
  user_permissions: state.auth.user.permissions,
});

export default connect(mapStateToProps, { loadUsers, deleteUser })(UsersList);