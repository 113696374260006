import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';
import Template2 from './templates/2';
import Template3 from './templates/3';
import Template4 from './templates/4';
import Template5 from './templates/5';
import Template6 from './templates/6';
import Template7 from './templates/7';
import Template8 from './templates/8';
import Template9 from './templates/9';
import Template10 from './templates/10';
import Template11 from './templates/11';
import Template12 from './templates/12';


export default function FormAir() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/electric/form/1" component={(prop) => <Template1 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/2" component={(prop) => <Template2 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/3" component={(prop) => <Template3 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/4" component={(prop) => <Template4 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/5" component={(prop) => <Template5 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/6" component={(prop) => <Template6 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/7" component={(prop) => <Template7 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/8" component={(prop) => <Template8 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/9" component={(prop) => <Template9 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/10" component={(prop) => <Template10 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/11" component={(prop) => <Template11 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/electric/form/12" component={(prop) => <Template12 document={prop.location.state} />} />
        </Switch>
    )
}