import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Signature from '../components/signature';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>PRUEBAS SISTEMAS DE AIRE-011</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>PRUEBAS DE CIRCULACIÓN REDES DE AIRE</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Técnico" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación de los difusores en la instalación" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación de la red de conductos al que están conectados" variant="outlined" />
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>CARACTERÍSTICAS DE LOS DIFUSORES INSTALADOS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="TIPO DIFUSOR" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="FABRICANTE" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="MODELO" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="CAUDAL ( )" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="VEL. (m/s)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>PRUEBAS CIRCULACIÓN RED DE CONDUCTOS</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se comprobará que las unidades terminales, rejillas y difusores se han instalado según esquema de principio del Proyecto o Memoria Técnica?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se comprobará que el nivel de ruido producido en elementos de difusión de aire y conductos es el adecuado?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se comprobará que el interior de las máquinas esté limpio y que los filtros hayan sido limpiados o sustituidos?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se comprobará la incorporación de un interruptor de corte de energía eléctrica?</Trans>
                        </Typography>
                        <YesNo />

                        <Typography variant="h5" gutterBottom>
                            <Trans>MEDIDAS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Local" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Difusor" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Nominales - Caudal()" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Nominales - Veloc.(m/s)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª local (ºC)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª impuls. (ºC)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Caudal()" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Presión (Pa)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Veloc.(m/s)" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />

                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);