import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import Signature from '../components/signature';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        Object.keys(form.elements).forEach((el) => {
            if (parseInt(el) != el) {
                if (el.includes("check_") && inputs[el].length > 1) {
                    let check_values = this.props.json[el]
                    if (check_values[0] == true) {
                        inputs[el][0].click()
                    } else if (check_values[1] == true) {
                        inputs[el][1].click()
                    }
                } else {
                    inputs[el].value = this.props.json[el];
                }
            }
        });
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>OPERACIÓN SISTEMAS DE AIRE-004</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>DIAGNÓSTICO EN OPERACIÓN LOCAL CPD</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Proyecto" variant="outlined" name="text_proyecto"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Sistema Nº" variant="outlined" name="text_sistema"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Servicio a" variant="outlined" name="text_servicio"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Plano ref. Nº" variant="outlined" name="text_planoref"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Planta" variant="outlined" name="text_planta"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Protocolo realizado por" variant="outlined" name="text_protocolo"/>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>APARATOS DE MEDIDA</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>TERMÓMETRO</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="MARCA" variant="outlined" name="text_marca_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="EN FECHA" variant="outlined" name="text_enfecha_1"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="CONTRASTADO POR" variant="outlined" name="text_contrastado_1"/>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>HIGOMETRO</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="MARCA" variant="outlined" name="text_marca_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="EN FECHA" variant="outlined" name="text_enfecha_2"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="CONTRASTADO POR" variant="outlined" name="text_contrastado_2"/>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>SONOMETRO</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="MARCA" variant="outlined" name="text_marca_3"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="EN FECHA" variant="outlined" name="text_enfecha_3"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="CONTRASTADO POR" variant="outlined" name="text_contrastado_3"/>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PROYECTO</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="MARCA" variant="outlined" name="text_marca_4"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="EN FECHA" variant="outlined" name="text_enfecha_4"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="CONTRASTADO POR" variant="outlined" name="text_contrastado_4"/>
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="LOCAL" variant="outlined" name="text_local_5"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="ZONA" variant="outlined" name="text_zona_5"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="DESTINO" variant="outlined" name="text_destino_5"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="TEMP. PROY." variant="outlined" name="text_temproy_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="TEMP. MED." variant="outlined" name="text_tempmed_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="HUME. PROY." variant="outlined" name="text_humeproy_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="HUME. MED." variant="outlined" name="text_humemed_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="NIVEL SONORO PROY." variant="outlined" name="text_sonoroproy_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="NIVEL SONORO MED." variant="outlined" name="text_sonoromed_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="C. AUTOMÁ. TIPO" variant="outlined" name="text_cautomatipo_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="C. AUTOMÁ. PROT.Nº" variant="outlined" name="text_cautomaprot_5" />
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" name="text_resultado" />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);