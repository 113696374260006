import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_de from "./translations/de/common.json";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

// the translations
const resources = {
    en: {
        translation: common_en
    },
    de: {
        translation: common_de
    },
    es: {
        translation: common_es
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources: resources,
        fallbackLng: "en",
        debug: false,
        lng: "en",
        interpolation: {
            escapeValue: false
        }
    });


export default i18n;