import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';
import Template2 from './templates/2';
import Template3 from './templates/3';
import Template4 from './templates/4';
import Template5 from './templates/5';
import Template6 from './templates/6';
import Template7 from './templates/7';


export default function FormPlumbing() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/plumbing/form/1" component={(prop) => <Template1 document={prop.location.state} />}/>
            <Route path="/:idBuild/plato/plumbing/form/2" component={(prop) => <Template2 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/plumbing/form/3" component={(prop) => <Template3 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/plumbing/form/4" component={(prop) => <Template4 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/plumbing/form/5" component={(prop) => <Template5 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/plumbing/form/6" component={(prop) => <Template6 document={prop.location.state} />} />
            <Route path="/:idBuild/plato/plumbing/form/7" component={(prop) => <Template7 document={prop.location.state} />} />
        </Switch>
    )
}