import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>ROCIADORES AUTOMÁTICOS DE AGUA</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Todas las instalaciones nuevas de rociadores automáticos de agua (sprinklers) deberán ser probadas y certificadas antes de la puesta en servicio y ocupación del edificio.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas serán realizadas obligatoriamente por un representante cualificado del instalador, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos para las pruebas serán presentados para aprobación antes del inicio de las mismas.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Certificado de conformidad a normas EN o UNE, NFPA, etc. emitido por la empresa instaladora. </li>
                        <li>Certificado de aceptación por FACTORY MUTUAL en caso de instalaciones diseñadas bajo su normativa y supervisión. </li>
                        <li>Certificados de Aprobación y Homologación aplicables UL, FM, VdS, ULC, etc. de las válvulas de alarma, Válvulas de corte principal, rociadores automáticos e interruptores de flujo.</li>
                        <li>Certificado de materiales del fabricante para la tubería, accesorios y soportación.</li>
                        <li>Certificado de homologación de los soldadores y ensayos de soldaduras si aplica.</li>
                        <li>Cálculos hidráulicos para cada sistema.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba hidráulica:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La totalidad de la instalación se someterá a una prueba de estanqueidad y resistencia mecánica a una presión estática de 1.500 kPa (15 bar) cuando la presión de trabajo sea igual o inferior a 1.000 kPa (10 bar) manteniendo dicha presión al menos durante dos horas, no debiendo aparecer fugas ni deformaciones en ningún punto de la instalación. La presión de prueba deberá ser 500 kPa (5 bar) por encima de la de trabajo cuando ésta sea superior a 1.000 kPa (10 bar).</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión de prueba será medida en el punto más bajo de la instalación o sector probado, eliminando el aire totalmente en los puntos altos y por la válvula de pruebas del sistema. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se comprobará que la válvula de corte principal está cerrada en caso de estar instalada y se taponarán los extremos de tubería inacabados, no utilizándose aditivos en el agua de pruebas de los que se emplean para taponar las fugas.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En el caso de sistemas Deluge (Inundación total) se emplearán tapones para la prueba, reemplazándolos posteriormente por los rociadores abiertos una vez completada la misma favorablemente. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se proveerán medidas especiales para la prueba en caso de zonas con temperaturas inferiores a 0 ºC para evitar congelaciones y se utilizará aire para el tramo afectado como se indica en el punto 3.2 en el caso de no ser posible la prueba con agua.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de aire:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Los sistemas de rociadores secos y de preacción supervisados se someterán además de la prueba hidrostática a una prueba de estanqueidad con aire comprimido a <strong>275 kPa (2,8 bar)</strong> durante <strong>24 horas.</strong>  Las pérdidas de presión superiores a <strong>10 kPa (0,1 bar)</strong> en ese tiempo deberán ser corregidas.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los sistemas:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Con la instalación llena de agua a la presión de servicio o de aire en los sistemas secos y de preacción supervisados, se verificará cada uno de los puntos siguientes:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se comprobará que el sistema cubre la totalidad del área definida en proyecto. Las coberturas por rociador deben ser las indicadas en los cálculos y se prestará atención a las vigas, mamparas, plataformas, conductos, luminarias y otros descuelgues que producen obstrucciones en las descargas de los rociadores. </li>
                        <li>Se comprobarán todos los modelos de rociadores empleados anotando la marca y modelos, año de fabricación, orificio, cantidad y temperatura de operación.</li>
                        <li>Se comprobará la válvula de alarma y sus accesorios, anotándose el modelo, diámetro y lecturas de los manómetros situados aguas arriba y abajo. En sistemas secos y de preacción se anotará también la presión del aire de supervisión y se verificará que la válvula de corte principal esté abierta y bloqueada en esa posición.</li>
                        <li>Se comprobará que los dispositivos eléctricos de alarma tales interruptores de flujo, presostatos, etc. están conectados y transmiten correctamente a la central de control.</li>
                        <li>Se comprobará el punto de pruebas de la instalación comprobándose que tiene válvula de aislamiento en posición cerrada, manómetro y un rociador abierto en el exterior del local al final de la tubería. Se deberá estar debidamente señalizado y la válvula bloqueada en caso necesario. La descarga estará libre de obstáculos y no producirá daños en materiales o equipos próximos.  En su defecto deberá disponer de placa con orificio calibrado y drenaje conducido.</li>
                        <li>Se verificará los puntos de limpieza de la instalación y se comprobará que las válvulas están cerradas y se dispone de racores normalizados con tapones. Deberán estar debidamente señalizados y la válvula bloqueada en caso necesario.</li>
                        <li>Se comprobarán las tuberías en todo su recorrido, verificándose que corresponden a lo indicado en planos en cuanto a diámetros y trazado y que está debidamente soportada. La pintura será uniforme y del color determinado en proyecto.</li>
                        <li>Se comprobará la toma de bomberos en fachada IPF-41 si está instalada, verificándose que no hay pérdidas de agua por las válvulas. Se desmontarán las tapas de los racores y comprobar que disponen de juntas y tapones de descompresión.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de funcionamiento:</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Sistema de tubería húmeda:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se abrirá la válvula de drenaje principal, comprobándose que no se producen fugas ni movimientos en el tramo de tubo hasta la arqueta. Se anotarán las presiones estática y dinámica y se cerrará lentamente la válvula verificando que no hay pérdidas.</li>
                        <li>Se comprobará el funcionamiento de la campana hidráulica abriendo la válvula de prueba incorporada en el puesto de control. Si se dispone de presostato en la línea de alarma se verificará que transmite correctamente a la central de control y se comprobará que el agua drena correctamente y no hay fugas.</li>
                        <li>En el punto de pruebas (situado en la zona más alejada de la instalación) se abrirá a la válvula totalmente. Se comprobará que el chorro es uniforme y se anotará las presiones estática y dinámica. Se verificará que la campana hidráulica y los dispositivos eléctricos transmiten la alarma antes de 5 minutos.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Sistemas de tubería seca, preacción y especiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se seguirá cuidadosamente las instrucciones de los fabricantes de las válvulas de alarma para comprobación de su funcionamiento y del sistema de supervisión de aire. Se verificará los sistemas de disparo y de transmisión de la alarma. Las tuberías deberán drenarse totalmente en caso de disparo del sistema.</li>
                        <li>Se tomarán medidas especiales de precaución en los sistemas de rociadores abiertos para evitar daños con el agua.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}