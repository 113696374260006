import React, { Fragment } from 'react';
import { BrowserRouter as Switch, Route } from "react-router-dom";
import Template1 from './templates/1';
import Template2 from './templates/2';
import Template3 from './templates/3';
import Template4 from './templates/4';
import Template5 from './templates/5';
import Template6 from './templates/6';
import Template7 from './templates/7';
import Template9 from './templates/9';
import Template10 from './templates/10';
import Template11 from './templates/11';
import Template12 from './templates/12';
import Template13 from './templates/13';


export default function InfoAir() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/air/info/1" component={Template1} />
            <Route path="/:idBuild/plato/air/info/2" component={Template2} />
            <Route path="/:idBuild/plato/air/info/3" component={Template3} />
            <Route path="/:idBuild/plato/air/info/4" component={Template4} />
            <Route path="/:idBuild/plato/air/info/5" component={Template5} />
            <Route path="/:idBuild/plato/air/info/6" component={Template6} />
            <Route path="/:idBuild/plato/air/info/7" component={Template7} />
            <Route path="/:idBuild/plato/air/info/8" component={Template7} />
            <Route path="/:idBuild/plato/air/info/9" component={Template9} />
            <Route path="/:idBuild/plato/air/info/10" component={Template10} />
            <Route path="/:idBuild/plato/air/info/11" component={Template11} />
            <Route path="/:idBuild/plato/air/info/12" component={Template12} />
            <Route path="/:idBuild/plato/air/info/13" component={Template13} />
        </Switch>
    )
}