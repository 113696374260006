import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones de fontanería</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBA LLENADO DEL CIRCUITO</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones durante el llenado de la instalación son preceptivas bajo el CTE DB HS 4 del Código Técnico de la Edificación que establece las pruebas y ensayos a realizar en la instalación terminada.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas de llenado del circuito hidráulico.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de llenado del circuito hidráulico</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El llenado del circuito hidráulico se realizará siguiendo el siguiente procedimiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                    <ul>
                        <li>Se llenará el circuito hidráulico hasta la presión mínima o de llenado.</li>
                        <li>Se comprobará que el purgado de aire es eficaz y que el llenado se realiza sin dificultad.</li>
                        <li>Se comprobará que los purgadores instalados funcionan correctamente y que su emplazamiento sea el adecuado para facilitar la purga de aire.</li>
                        <li>Se comprobará que el circuito mantiene la presión inicial o de llenado y que no existen fugas de fluido. </li>
                        <li>Se medirá si es posible la presión posteriormente con el circuito caliente para comprobar que el incremento de presión producido por el cambio de la densidad del agua, se corresponde con los valores estimados en el proyecto. </li>
                    </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}