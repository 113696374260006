import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, Grid } from '@material-ui/core/';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Table from '../../layout/Table';
import { loadUsers } from '../../../actions/users';
import store from '../../../store';
import { getAudit } from '../../../actions/audits';
import moment from 'moment'

/**Componente para la gestión de logs. */
export class AuditList extends Component {
  constructor(props) {
    super(props);
    /**
     * Se crean las columnas para la tabla que mostrará los logs.
     */
    this.columns = [
      {
        title: 'date',
        data: 'date',
        render: this.formatDate
      },
      {
        title: 'id',
        data: 'id'
      },
      {
        title: 'message',
        data: 'message'
      },
      {
        title: 'criticality',
        data: 'criticality'
      },
    ]
  }

  formatDate = (date) => {
    const formattedDate = moment(new Date(date)).locale("es").format('YYYY-MM-DD HH:mm:ss');
    return formattedDate
  }

  /**Declaración de los PropTypes */
  static propTypes = {
    getAudit: PropTypes.func.isRequired,
  }


  /**
   * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los logs
   * en ese momento.
   */
  componentDidMount() {
    this.props.loadUsers();
    this.props.getAudit();
  }
  // TODO cambiar sotre.getState por this.state
  render() {
    return (
      <div className="root">
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="primary" component={Link} to="/logs">Logs</Typography>
        </Breadcrumbs>
        <Typography variant="h2" gutterBottom>
          Logs
        </Typography>
        <Table
          title="Listado de Auditoria"
          columns={this.columns}
          data={store.getState()['audit']['audit'][0]} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // Recoger del auditReducer el state
  audit: state.audit.audit
});

export default connect(mapStateToProps, { loadUsers, getAudit })(AuditList);