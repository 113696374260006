import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";


/**Componente para la gestión de usuarios. */
export class TechnicsList extends Component {

  constructor(props) {

    super(props);

    this.state = {
      isLoading: true,
      document_types: []
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Normativa',
        data: 'normative',
      },
      {
        title: 'Documentación\ndisponible',
        data: null,
        render: function (data, type, row) {
          let url = ""
          data.state = "no"
          data.detail = "No hay ningun archivo asociado";
          data.download = "disabled"
          if (data.file != null && data.file.file) {
            data.download = "disponible"
            data.state = "si"
            data.detail = "Texto si tiene archivo"
          }
          if (data.file != null) {
            url = `${data.file.file}`
            if (url.includes("http") == false){
              url = `${axios.defaults.baseURL}${data.file.file}`
            }
            return `<div class="tooltip-exta"><a href="${url}" target="_blank" class="MuiButtonBase-root MuiButton-root Mui-'${data.download}' MuiButton-outlinedPrimary">${data.state}<i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">${data.detail}</span></div>`
          }
          return '<div class="tooltip-exta"><a href="' + url + '" class="MuiButtonBase-root MuiButton-root Mui-' + data.download + ' MuiButton-outlinedPrimary">' + data.state + ' <i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">' + data.detail + '</span></div>'
        },
      },
      {
        title: 'Subir\ndocumentación',
        data: null,
        action: 'upload',
        f_action: ''
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          return '<a href="#info-modal' + data.id + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"><i class="fa fa-info-circle" aria-hidden="true"></i></a><a href="#"><div id="info-modal' + data.id + '" class="modal info-modal"><div class="MuiPaper-rounded MuiPaper-root modal-contenido"><h4 class="text-modal">' + data.description + '</h4><p class="text-modal">' + data.information + '</p></div></div></a>'
        },
      }
    ]
  }

  static propTypes = {
    document_types: PropTypes.array,
    getDocumentTypes: PropTypes.func,
    isLoading: PropTypes.bool
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Certificado final obra arquitectura', description: 'LOE Ley 38/1999', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Según el Artículo 6 sobre Recepción de la obra de la LEY 38/1999, de 5 de noviembre, de Ordenación de la Edificación. \n\n 1. La recepción de la obra es el acto por el cual el constructor, una vez concluida ésta, hace entrega de la misma al promotor y es aceptada por éste. Podrá realizarse con o sin reservas y deberá abarcar la totalidad de la obra o fases completas y terminadas de la misma, cuando así se acuerde por las partes. \n\n 2. La recepción deberá consignarse en un acta firmada, al menos, por el promotor y el constructor, y en la misma se hará constar: \n a. Las partes que intervienen. \n b. La fecha del certificado final de la totalidad de la obra o de la fase completa y terminada de la misma. \n c. El coste final de la ejecución material de la obra. \n d. La declaración de la recepción de la obra con o sin reservas, especificando, en su caso, éstas de manera objetiva, y el plazo en que deberán quedar subsanados los defectos observados. Una vez subsanados los mismos, se hará constar en un acta aparte, suscrita por los firmantes de la recepción. \n e. Las garantías que, en su caso, se exijan al constructor para asegurar sus responsabilidades. \n\n Asimismo, se adjuntará el certificado final de obra suscrito por el director de obra y el director de la ejecución de la obra.' },
      // { id: 2, name: 'Certificado final obra instalaciones', description: 'LOE Ley 38/1999', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Según el Artículo 12 sobre el director de obra de la LEY 38/1999, de 5 de noviembre, de Ordenación de la Edificación.\n\n 1. El director de obra es el agente que, formando parte de la dirección facultativa, dirige el desarrollo de la obra en los aspectos técnicos, estéticos, urbanísticos y medioambientales, de conformidad con el proyecto que la define, la licencia de edificación y demás autorizaciones preceptivas y las condiciones del contrato, con el objeto de asegurar su adecuación al fin propuesto. \n\n 2. Podrán dirigir las obras de los proyectos parciales otros técnicos, bajo la coordinación del director de obra. En este último caso se requiere un certificado final de obra por cada técnico.' },
      // { id: 3, name: 'Libro del edificio', description: 'CTE RD 732/2019', state: 'aprobado', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Según se indica en el Código Técnico de la Edificación bajo RD 732/2019, en el Libro del Edificio se incluirá la documentación referente a las características  de los productos, equipos y sistemas incorporados a la obra. \n\n Así mismo se incluirá el plan de mantenimiento que contemplará las operaciones y periodicidad necesarias para el mantenimiento, en el transcurso del tiempo, de los parámetros de diseño y prestaciones de lo diseñado. \n\n También, en el Libro del Edificio se documentarán todas las intervenciones, ya sean de reparación, reforma o rehabilitación realizadas a lo largo de la vida útil del edificio.' },
      // { id: 4, name: 'Certificación energetica', description: 'RD 732/2019 + RD 390/2021', state: 'si', detail: 'Validez 5 ó 10 años', download: 'disponible', info: 'Las prestaciones de consumo y demanda del edfiicio marcasdas en el DB HE 0 y 1 del CTE se justificarán mediente el correspondiente certificado de eficiencia energética del mismo. Para obra nueva lo regula el RD 47/2007 y para edificios existentes el RD 235/2008. La validez y condiciones de mantenimiento de este certificado las fija el Real Decreto 390/2021, de 1 de junio, por el que se aprueba el procedimiento básico para la certificación de la eficiencia energética de los edificios. Según el Artículo 13  sobre validez, renovación y actualización del certificado de eficiencia energética  "El certificado de eficiencia energética tendrá una validez máxima de diez años, excepto cuando la calificación energética sea G, cuya validez máxima será de cinco años".' },
    ],
    // { id: 5, name: '', description: '', state: 'si/no', detail: '', download: 'disponible/disabled', info: '' },
  };

  componentDidMount() {
    const { idBuild } = this.props.match.params
    const module_id = 1
    this.props.getDocumentTypes(idBuild,module_id);
    // this.props.getFiles(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {

    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(TechnicsList));
