import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Container, IconButton } from '@material-ui/core/';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import Header from './Header';
import Footer from './Footer';
import Section from './Section';


/**
 * Declaración de los estilos usados para mostrar los componentes que se renderizan en este componente.
 */
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
  brightness4Icon: {
    position: 'fixed',
    top: '0.9rem',
    right: '6%',
    zIndex: '2000',
  },
}));



const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#988575',
    },
    secondary: {
      main: '#313438',
    },
    background: {
      default: '#212529',
    },
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundImage: 'url(/static/images/logo-exta-dark.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '8%',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#313438',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#313438',
      },
    },
  }
});

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#45636e',
    },
    secondary: {
      main: '#d5d5d5',
    },
    background: {
      default: '#eaeaea',
    },
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundImage: 'url(/static/images/logo-exta.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '8%',
        backgroundColor: '#ededed',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#efefef',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#efefef',
      },
    },
  },
});

/**
 * Componente principal en el que se renderizan los componentes 'Header', 'Section' y 'Footer'.
 *
 * @example ../../../../docs/Frontend/Layout/Index.md
 */
export default function Index() {
  if (!localStorage.getItem("theme")){
    localStorage.setItem("theme", "dark")
  }
  var themeCache = localStorage.getItem("theme");
  const [theme, setTheme] = useState(themeCache);
  const toggleTheme = () => {
    if (theme === 'light') {
      localStorage.setItem("theme", "dark")
      setTheme('dark');
    } else {
      localStorage.setItem("theme", "light")
      setTheme('light');
    }
  }
    const classes = useStyles();
    return (
<MuiThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
<div className={classes.root}>
<CssBaseline />
    <Header />
    <main className={classes.content}>
        <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Section />
              <IconButton onClick={toggleTheme} className={classes.brightness4Icon}>
                  <BrightnessMediumIcon />
              </IconButton>
              <Footer />
            </Container>
    </main>
</div>
</MuiThemeProvider>
    )
}
