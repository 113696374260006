// import { types } from '@babel/core';
import axios from 'axios';

import Table from '../components/layout/Table';
import { createMessage, returnErrors } from './messages';

const $ = require('jquery');
$.DataTable = require('datatables.net');
import { GET_AUDIT } from './types';
import { tokenConfig } from './auth';

/**Función que controla la petición GET a la URL de la API para recuperar la información de los logs. */
export const getAudit = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/audit', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_AUDIT,
            payload: res.data,
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    }); 
};