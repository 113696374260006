import axios from 'axios';
import {
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
} from './types';
import { createMessage, returnErrors } from './messages';

import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.baseURL = 'https://api.extatecnologica.com';

/**Función que controla la petición GET a la URL de la API para recuperar la información del usuario logueado. */
export const loadUser = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/loaduser', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
            type: AUTH_ERROR
        });
    });
};

/**Función que controla la petición de tipo POST a la URL de la API que controla el login de los usuarios. */
export const login = (username, password) => dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    // Request body
    const body = { 
        'username': username, 
        'password': password };

    axios.post('/api/v1/auth/login', body, config)
    .then(res => {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    }).catch(err => {
        toast.error('Error al iniciar sesión, compruebe las credenciales')
        dispatch(returnErrors(err.response.data, err.response.status));
        dispatch({
            type: LOGIN_FAIL
        });
        
    });
};

/**Función que controla la petición POST a la URL de la API que se encarga del logout del usuario. */
export const logout = () => (dispatch, getState) => {
    axios.post('/api/v1/auth/logout', null, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOGOUT_SUCCESS
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

/**Función para recuperar el token del usuario activo para limitar la visualización de elementos por usuario. */
export const tokenConfig = getState => {
    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config;
}

/**Función para recuperar el token del usuario activo para limitar la visualización de elementos por usuario. Version para subir/actualizar archivos */
export const tokenConfigFile = getState => {
    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    // If token, add to headers config
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }

    return config;
}
