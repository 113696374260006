import React, { Component } from 'react';
const $ = require('jquery');
$.DataTable = require('datatables.net');
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import { loadClients } from '../../actions/clients';
import { Button } from '@material-ui/core';
import { fileAdministrator } from '../../actions/files';
import { connect } from 'react-redux';
import axios from 'axios';
import { loadUser } from '../../actions/auth';
import { loadPermissions } from '../../actions/users';
import { toast } from 'react-toastify';

/**
 * Componente para la generación de tablas
 * 
 * @example ../../../../docs/Frontend/Layout/Table.md 
 */
export class Table extends Component {

    /**Declaración de PropTypes */
    static propTypes = {
        data: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        updateFile: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    /**Se declaran props por defecto */
    static defaultProps = {
        //Example data
        // title: '',
        data: [],
        // Example columns
        columns: [],
        // User_permissions
        // user_permissions: [],

    };

    validationValue = {
        1: "Complete",
        2: "Incomplete",
        3: "Aproved",
        4: "Denied",
        0: "Pending",
    }

    validationInfo = {
        1: "Falta de firma",
        2: "Faltan datos",
        3: "Se debe insertar fecha",
        4: "Valores inadecuados",
        0: "Falta de validación",
    }

    relationForm = {
        35: "/" + this.props.match.params.idBuild + "/plato/electric/form/1",
        36: "/" + this.props.match.params.idBuild + "/plato/electric/form/2",
        37: "/" + this.props.match.params.idBuild + "/plato/electric/form/3",
        38: "/" + this.props.match.params.idBuild + "/plato/electric/form/4",
        39: "/" + this.props.match.params.idBuild + "/plato/electric/form/5",
        40: "/" + this.props.match.params.idBuild + "/plato/electric/form/6",
        41: "/" + this.props.match.params.idBuild + "/plato/electric/form/7",
        42: "/" + this.props.match.params.idBuild + "/plato/electric/form/8",
        43: "/" + this.props.match.params.idBuild + "/plato/electric/form/9",
        44: "/" + this.props.match.params.idBuild + "/plato/electric/form/10",
        45: "/" + this.props.match.params.idBuild + "/plato/electric/form/11",
        46: "/" + this.props.match.params.idBuild + "/plato/electric/form/12",
        47: "/" + this.props.match.params.idBuild + "/plato/sanit/form/1",
        48: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/1",
        49: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/2",
        50: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/3",
        51: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/4",
        52: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/5",
        53: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/6",
        54: "/" + this.props.match.params.idBuild + "/plato/plumbing/form/7",
        55: "/" + this.props.match.params.idBuild + "/plato/pci/form/1",
        56: "/" + this.props.match.params.idBuild + "/plato/pci/form/2",
        57: "/" + this.props.match.params.idBuild + "/plato/pci/form/3",
        58: "/" + this.props.match.params.idBuild + "/plato/pci/form/4",
        59: "/" + this.props.match.params.idBuild + "/plato/pci/form/5",
        60: "/" + this.props.match.params.idBuild + "/plato/pci/form/6",
        61: "/" + this.props.match.params.idBuild + "/plato/pci/form/7",
        62: "/" + this.props.match.params.idBuild + "/plato/pci/form/8",
        63: "/" + this.props.match.params.idBuild + "/plato/pci/form/9",
        64: "/" + this.props.match.params.idBuild + "/plato/pci/form/10",
        65: "/" + this.props.match.params.idBuild + "/plato/pci/form/11",
        66: "/" + this.props.match.params.idBuild + "/plato/pci/form/12",
        67: "/" + this.props.match.params.idBuild + "/plato/pci/form/13",
        68: "/" + this.props.match.params.idBuild + "/plato/pci/form/14",
        69: "/" + this.props.match.params.idBuild + "/plato/pci/form/15",
        70: "/" + this.props.match.params.idBuild + "/plato/pci/form/16",
        71: "/" + this.props.match.params.idBuild + "/plato/air/form/1",
        72: "/" + this.props.match.params.idBuild + "/plato/air/form/2",
        73: "/" + this.props.match.params.idBuild + "/plato/air/form/3",
        74: "/" + this.props.match.params.idBuild + "/plato/air/form/4",
        75: "/" + this.props.match.params.idBuild + "/plato/air/form/5",
        76: "/" + this.props.match.params.idBuild + "/plato/air/form/6",
        77: "/" + this.props.match.params.idBuild + "/plato/air/form/7",
        78: "/" + this.props.match.params.idBuild + "/plato/air/form/8",
        79: "/" + this.props.match.params.idBuild + "/plato/air/form/9",
        80: "/" + this.props.match.params.idBuild + "/plato/air/form/10",
        81: "/" + this.props.match.params.idBuild + "/plato/air/form/11",
        82: "/" + this.props.match.params.idBuild + "/plato/air/form/12",
        83: "/" + this.props.match.params.idBuild + "/plato/air/form/13",
        84: "/" + this.props.match.params.idBuild + "/aristotle/air/form/1",
        85: "/" + this.props.match.params.idBuild + "/aristotle/air/form/2",
        86: "/" + this.props.match.params.idBuild + "/aristotle/air/form/3",
        87: "/" + this.props.match.params.idBuild + "/aristotle/air/form/4",
        88: "/" + this.props.match.params.idBuild + "/aristotle/air/form/5",
        89: "/" + this.props.match.params.idBuild + "/aristotle/air/form/6",
        90: "/" + this.props.match.params.idBuild + "/aristotle/air/form/7",
        91: "/" + this.props.match.params.idBuild + "/aristotle/air/form/8",
        92: "/" + this.props.match.params.idBuild + "/aristotle/air/form/9",
    }

    /**
     * Sirve para cuando se necesita que todas las redirecciones sean la misma
     */
    runRedirect(e, rowData) {
        const { history } = this.props;
        history.push(`${this.redirectPath}`, rowData);
        // history.push(`${this.redirectPath}${rowData.id}`);
    }

    /**
    * Sirve para cuando se necesita que todas las redirecciones sean diferentes, por cada fila
    */
    runRedirectDynamic(e, rowData) {
        const { history } = this.props;
        let redirectPath = ""
        if (!rowData.document_type) {
            redirectPath = this.relationForm[rowData.id];
        } else {
            redirectPath = this.relationForm[rowData.document_type.id];
        }
        rowData.origin = history.location.pathname
        history.push(redirectPath, rowData);
    }

    clickHandler(e, document) {
        let permission = this.props.user_permissions
        if (permission.includes(1)) {
            if (e.target.tagName.toUpperCase() != 'INPUT') {
                this.document = document
                this.inputRef.click();
            }
        }else{
            toast.error(`No tienes permisos para subir documentación`)
            return False
        }
    }

    onChangeFile(e) {
        console.log(e)
        e.preventDefault();
        var file = e.target.files[0]
        this.props.fileAdministrator(this.document, file, this.props.match.params.idBuild);
    }

    /**
     * Función que controla la creación de nuevas acciones para las tablas.
     */
    addAction(target, action, fAction, redirectPath) {

        let icon = "";
        let button = "";
        let path = "";
        let input = "";
        let newurl = "";

        // Recogida de permisos para mostrar upload
        const socrates_modules = [1, 2, 3, 4]

        switch (action) {
            case "delete":
                icon = "fa fa-trash"
                button = "image-upload MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"
                break;
            case "edit":
                icon = "fa fa-edit"
                button = "image-upload MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"
                break;
            case "view":
                icon = "fa fa-eye"
                button = "MuiButtonBase-root MuiButton-root MuiButton-containedExta"
                break;
            case "run-redirect":
                icon = "fa fa-eye"
                button = "btn MuiButton-textPrimary"
                fAction = this.runRedirect.bind(this);
                // this.redirectPath = redirectPath;
                break;
            case "run-redirect-dynamic":
                icon = "fa fa-eye"
                button = "btn MuiButton-textPrimary"
                fAction = this.runRedirectDynamic.bind(this);
                break;
            case "download":
                icon = "fa fa-download"
                button = "btn MuiButton-textPrimary"
                break;
            case "renew":
                icon = "fa fa-redo"
                button = "btn MuiButton-textPrimary"
                break;
            case "upload":
                icon = "fa fa-upload"
                button = "image-upload MuiButtonBase-root MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"
                fAction = this.clickHandler.bind(this);
                input = <input type="file" ref={(ref) => this.inputRef = ref} onChange={this.onChangeFile.bind(this)} style={{ display: "none" }} />
                break;
            case "read_alert":
                icon = "fa fa-eye"
                button = "image-upload MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"
                break;
        }
        this.actions.push({
            targets: [target],
            createdCell: (td, cellData, rowData, row, col) => {
                if (cellData != null) {
                    let module_name = this.props.history.location.pathname

                    if ((module_name.includes("plato") || (module_name.includes("aristotle")) && (action == "run-redirect" || action == "run-redirect-dynamic"))) {
                        icon = ""
                        button = "MuiButtonBase-root MuiButton-root Mui" + rowData.state + " MuiButton-containedExta";
                        if (rowData.file == null) {
                            input = this.validationValue[0]
                            rowData.info = this.validationInfo[0]
                        } else {
                            input = this.validationValue[rowData.file.validation]
                            rowData.info = this.validationInfo[rowData.file.validation]
                        }

                        let redirect_path = this.relationForm[cellData.id];
                        this.redirectPath = redirect_path

                        ReactDOM.render(
                            <div className="tooltip-exta">
                                <a className={button} onClick={(e) => fAction(e, rowData)}>
                                    <i className={icon}></i>{input}
                                </a>
                                <span className="tooltiptext-exta MuiButton-containedPrimary">{rowData.info}</span>
                            </div>, td
                        );
                    } else if (module_name.includes("validation")) {
                        if (col == 8) {
                            // Boton para validar
                            icon = ""
                            button = "MuiButtonBase-root MuiButton-root Mui-" + rowData.state + " MuiAppBar-colorPrimary";
                            input = "Validar"
                            let redirect_path = "/adminpanel/validation/form";
                            this.redirectPath = redirect_path
                            ReactDOM.render(
                                <div className="tooltip-exta">
                                    <Button className={button} onClick={(e) => fAction(e, rowData)}>
                                        <i className={icon}></i>{input}
                                    </Button>
                                </div>, td
                            );
                        } else if (col == 6) {

                            // Boton donde esta el archivo o el formulario

                            cellData.state = "no"
                            cellData.detail = "No se ha proporcionado";
                            cellData.download = "disabled"

                            if (cellData.file) {
                                cellData.state = "si"
                                cellData.download = "disponible"
                                cellData.detail = "Falta validación"
                                if (cellData.expiration_date != null) {
                                    cellData.detail = "Fecha de validez: " + cellData.expiration_date
                                }
                            }

                            let button = "MuiButtonBase-root MuiButton-root Mui-" + cellData.download + " MuiAppBar-colorPrimary"

                            if (socrates_modules.includes(cellData.module)) {
                                icon = "fa fa-file-pdf"
                                let url = `${axios.defaults.baseURL}${cellData.file}`
                                ReactDOM.render(
                                    <div className="tooltip-exta">
                                        <a href={url} className={button} target="_blank">
                                            {cellData.state} <i className={icon}></i>
                                        </a>
                                        <span className="tooltiptext-exta MuiAppBar-colorPrimary">{rowData.detail}</span>
                                    </div>, td
                                );
                            } else {
                                icon = ""
                                input = "Formulario"

                                let redirect_path = this.relationForm[cellData.document_type.id];

                                ReactDOM.render(
                                    <div className="tooltip-exta">
                                        <a className={button} onClick={(e) => fAction(e, rowData)}>
                                            <i className={icon}></i>{input}
                                        </a>
                                        <span className="tooltiptext-exta MuiAppBar-colorPrimary">{cellData.detail}</span>
                                    </div>, td
                                );
                            }
                        } else {
                            icon = ""
                            button = "MuiButtonBase-root MuiButton-root MuiButton-containedExta MuiAppBar-colorPrimary";
                            input = this.validationValue[rowData.validation]
                            rowData.info = this.validationInfo[rowData.validation]
                            ReactDOM.render(
                                <div className="tooltip-exta">
                                    <span className={button}>{input}</span>
                                    <span className="tooltiptext-exta MuiAppBar-colorPrimary">{rowData.info}</span>
                                </div>, td
                            );
                        }
                    }
                    else {
                        // Comprobación de permiso de escritura
                        if (action !== 'upload') {
                            ReactDOM.render(
                                <a className={button} onClick={(e) => fAction(e, rowData)}><i className={icon}></i>{input}</a>, td
                            );
                        } else {
                            ReactDOM.render(
                                <a className={button} onClick={(e) => fAction(e, rowData)}><i className={icon}></i>{input}</a>, td
                            );
                        }
                    }
                }
            }
        })
    }

    /**Función que controla la generación de las columnas que se le pasan al componente. */
    generateColumnActions() {

        const columns = this.props.columns;
        this.actions = []
        Object.keys(columns).forEach(function (key) {
            if (columns[key].hasOwnProperty("f_action") || columns[key].hasOwnProperty("redirect_path")) {
                if (columns[key].hasOwnProperty("action")) {
                    if (!columns[key].hasOwnProperty("redirect_path")) {
                        columns[key].redirect_path = ''
                    }
                    if (!columns[key].hasOwnProperty("f_action")) {
                        columns[key].f_action = ''
                    }

                    this.addAction(parseInt(key), columns[key].action, columns[key].f_action, columns[key].redirect_path);

                }
            }
        }.bind(this))
    }

    /**Función que controla las acciones a ejecutar antes de que se monte el componente. */
    componentDidMount() {
        this.$el = $(this.el);
        this.generateColumnActions()
        $(this.el).DataTable({
            data: this.props.data,
            columns: this.props.columns,
            responsive: true,
            columnDefs: this.actions,
            pageLength: 100,
            bLengthChange: false,
            bPaginate: false,
            bInfo: false,
        })
        this.props.loadUser();
        this.props.loadPermissions();
    }
    componentDidUpdate(prevProvs) {
        if (this.props !== prevProvs) {
            this.setState({
                user_permissions: this.props.user.permissions,
                user: this.props.user,
            })
        }
    }

    /**Función que controla las acciones a ejecutar cuando el componente vaya a ser desmontado. */
    componentWillUnmount() {
        this.$el = $(this.el);
        $(this.el).DataTable().destroy(true);
        //this.$el.DataTable.destroy(true)
    }

    /**Función que se encarga de la recarga de la tabla en caso de que no haya datos nuevos a mostrar. */
    reloadTableData(data) {
        this.$el = $(this.el);
        const table = $(this.el).DataTable();
        table.clear();
        table.rows.add(data);
        table.draw();
    }

    /**Función que controla las operación a ejecutar cuando el componente vaya a ser supuestamente montado. */
    shouldComponentUpdate(nextProps) {
        if (nextProps.data.length !== this.props.data.length) {
            this.reloadTableData(nextProps.data);
        } else {
            this.updateTable(nextProps.data);
        }
        return false;
    }

    /**Función que se encarga de actualizar la tabla en caso de que haya nuevos datos. */
    updateTable(data) {
        this.$el = $(this.el);
        const table = $(this.el).DataTable();
        let dataChanged = false;
        table.rows().every(function () {
            const oldIdData = this.data();
            const newIdData = data.find((idData) => {
                return idData.id === oldIdData.id;
            });
            if (!_.isEqual(oldIdData, newIdData)) {
                dataChanged = true;
                this.data(newIdData);
            }
            return true;
        });

        if (dataChanged) {

            /** La razon por la que se borra y se vuelve a añadir ĺos datos
             * es porque cuando se cambia de documento no se mostraba bien el 
             * input apareciendo [Object object], por el momento es la solución
             * a este problema.
             */
            table.clear();
            table.rows.add(data);
            table.draw();
        }
    }

    render() {
        return (
            //Div necesario para evitar errore de dom en navegación
            <div>
                <table className="mdl-data-table display responsive" width="100%" ref={el => this.el = el}></table>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    user_permissions: state.auth.user.permissions,
    user: state.auth.user,
});

export default connect(mapStateToProps, { fileAdministrator, loadPermissions, loadUser })(withRouter(Table));


