import { CREATE_MESSAGE } from '../actions/types';

const initialState = {};

/**Función que controla la creación de mensajes para PNotify */
export default function(state = initialState, action) {
    switch (action.type) {
        case CREATE_MESSAGE:
            return (state = action.payload)
        default:
            return state;
    }
}