import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core/';
import AlertsList from './alertsList';


export default function Alerts() {    
    return (
        <Fragment>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid item xs>
                    <Typography variant="h3" gutterBottom>
                    <Trans>Centro de alertas</Trans>
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
            </Grid>
            <AlertsList />
        </Fragment>
    )
}