import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import {  getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class PciList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Ver / Estado',
        data: null,
        action: "run-redirect-dynamic",
        redirect_path: ""
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          const infoURL = {
            55 : 1,
            56 : 1,
            57 : 1,
            58 : 4,
            59 : 4,
            60 : 4,
            61 : 7,
            62 : 8,
            63 : 9,
            64 : 9,
            65 : 11,
            66 : 12,
            67 : 13,
            68 : 13,
            69 : 15,
            70 : 15,
          }
          return '<a href="/' + data.building + '/plato/pci/info/' + infoURL[data.id] + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary" target="_blank"><i class="fa fa-info-circle" aria-hidden="true"></i></a>'
        },
      }
    ]
  }
  static propTypes = {
    data: PropTypes.array.isReqd
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Prueba hidráulica redes de tuberías', state: 'Secondary', detail: 'Denegado', info: 'Valores inadecuados' },
      // { id: 2, name: 'Verficación sistemas agua pulverizada', state: 'Primary', detail: 'aprobado', info: 'Válido hasta 02/06/2023' },
      // { id: 3, name: 'Prueba funcionamiento agua pulverizada', state:'Primary', detail: 'completo', info: 'Falta de firma' },
      // { id: 4, name: 'Verificación bocas de incendio', state:'Secondary', detail: 'pendiente', info: 'Falta de validación' },
      // { id: 5, name: 'Prueba funcionamiento bocas de incendio', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 6, name: 'Depósitos agua contra incendios', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 7, name: 'Grupos de bombeo', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 8, name: 'Columnas secas', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 9, name: 'Prueba de funcionamiento: detención y alarma', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 10, name: 'Verificación de sistemas: detención y alarma', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 11, name: 'Extintores portátiles y de carro', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 12, name: 'Prueba funcionamiento hidrantes exteriores', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 13, name: 'Prueba funcionamiento rociadores automáticos', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 14, name: 'Verificación sistemas rociadores automáticos', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 15, name: 'Prueba funcionamiento agentes gaseosos', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
      // { id: 16, name: 'Verificación sistemas agentes gaseosos', state:'Secondary', detail: 'incompleto', info: 'Faltan datos' },
    ],
  };

  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 8
    this.props.getDocumentTypes(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    const { idBuild } = this.props.match.params
    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    document_types.forEach(e => {
      e.building = idBuild
    });

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(PciList));
