import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, AppBar, Toolbar } from '@material-ui/core/';
import NavRight from './NavRight';

/**Función de renderizado de la información de Copyright */
function Copyright() {
  const classes = useStyles();
  return (
    <Typography variant="caption" align="right" className={classes.bar}>
      {'Copyright © '}
      <Link color="inherit" href="https://aerin.es/">
        Aerin Sistemas
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

/**Función de control de estilos visuales del componente. */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
    appBar: {
    top: 'auto',
    bottom: 0,
    width: '100%',
  },
  bar: {
    width: '90%',
  }
}));

/**
 * Componente de footer. En este componente se renderiza la información de copyright de la aplicación.
 * 
 * @example ../../../../docs/Frontend/Layout/Footer.md
 */
export default function Footer() {
    const classes = useStyles();
    return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        <NavRight />
        </Toolbar>
      </AppBar>
    </div>
    )

}
