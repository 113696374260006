import { combineReducers } from 'redux';
import auth from './auth';
import errors from './errors';
import messages from './messages';
import users from './users';
import audit from  './audit';
import clients from './clients';
import buildings from './buildings';
import files from './files';
import alerts from './alert';
import modules from './modules';

/**Declaración reunida de todos los reducers. */
export default combineReducers({
    auth,
    errors,
    messages,
    users,
    audit,
    clients,
    buildings,
    files, 
    alerts,
    modules
});
