import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid } from '@material-ui/core/';

export default function Signature() {   
    const n_tecnico = "input_ntecnico"
    const n_cliente = "input_ncliente"
    const n_fecha_1 = "input_fecha1"
    const n_fecha_2 = "input_fecha2"
    const n_firma_tecnico = "input_ftecnico"
    const n_firma_cliente = "input_fcliente"
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6}>
                    <TextField fullWidth label="Nombre del técnico" variant="outlined" name={n_tecnico} />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Nombre del cliente" variant="outlined" name={n_cliente}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Fecha" variant="outlined" name={n_fecha_1} />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Fecha" variant="outlined" name={n_fecha_2} />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Firma del técnico" variant="outlined" name={n_firma_tecnico}  />
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth label="Firma del cliente" variant="outlined" name={n_firma_cliente}  />
                </Grid>
            </Grid>
        </Fragment>
    )
}