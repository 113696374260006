/**Tipos relacionados con el login del usuario. */
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
/**Tipos relacionados con usuarios */
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const EDIT_USER = 'EDIT_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_PERMISSION = 'GET_PERMISSION';
/**Tipos relacionados con audits */
export const GET_AUDIT = 'GET_AUDIT';
/**Tipos relacionados con los mensajes de las alertas de PNotify */
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
/**Tipos relacionados con clientes */
export const ADD_CLIENT = "ADD_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";
/**Tipos relacionados con edificios */
export const ADD_BUILDING = "ADD_BUILDING";
export const GET_BUILDING = "GET_BUILDING";
export const LOAD_BUILDINGS = "LOAD_BUILDINGS";
export const DELETE_BUILDING = "DELETE_BUILDINGS";
export const EDIT_BUILDING = "EDIT_BUILDINGS";
export const LOAD_BUILDING = "LOAD_BUILDING";

/** Tipos de relaciones para todos los modelos */
export const DOCUMENTTYPE_SUCCESS = "DOCUMENTTYPE_SUCCESS";
export const DOCUMENTTYPE_FAIL = "DOCUMENTTYPE_FAIL";

/** Tipos relaciones con Socrates **/
export const FILE_SUCCESS = "FILE_SUCCESS";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPDATE = "FILE_UPDATE";
export const FILE_FAIL = "FILE_FAIL";

/** Tipos relacionados con Platon/Aristotela */
export const JSON_SUCCESS = "JSON_SUCCESS";

/** Tipos relacionados con el Banner */
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";
export const COUNTER_SUCCESS = "COUNTER_SUCCESS";

/** Tipos relacionados con el validador */
export const ASINGED_SUCCESS = "ASINGED_SUCCESS";
export const ISLOADING = "ISLOADING";

/** Tipos relaciones con las alertas */
export const LOAD_ALERTS = "LOAD_ALERTS";
export const READ_ALERT = "READ_ALERT";

/** Tipos relaciones con los modulos */
export const LOAD_MODULES = "LOAD_MODULES";