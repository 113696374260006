import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>VERIFICACIÓN DE LA EJECUCIÓN DEL MONTAJE</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado del circuito hidráulico son preceptivas bajo el Artículo 22 del <strong>Reglamento de Instalaciones Térmicas</strong> “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la <strong>IT 2</strong> “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en verificación de la ejecución del montaje.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de la ejecución del montaje</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La verificación de la ejecución del montaje implica la realización de los siguientes procedimientos:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Se comprobará que el circuito hidráulico se ha realizado siguiendo el esquema de principio del Proyecto o Memoria Técnica. En caso de existir diferencias, se deberá comprobar si se trata de errores o si se han realizado para mejorar el diseño inicial. En cualquier caso, se debe entregar el esquema del circuito finalmente ejecutado, contando con el visto bueno de la Dirección Técnica.</li>
                            <li>Se comprobará que el trazado de las tuberías se corresponde con los planos del Proyecto o Memoria Técnica y que es el adecuado.</li>
                            <li>Se comprobará que el material de las tuberías instaladas se corresponde con el proyecto, así como su espesor (o serie en el caso de tuberías de plástico).</li>
                            <li>Se comprobará que la sujeción de las tuberías a los cerramientos sea correcta y que se respetan las distancias de separación con otras conducciones.</li>
                            <li>Se comprobará que no existen contactos de metales de diferente naturaleza que puedan ocasionar oxidación por pares galvánicos. En el caso de existir conexiones de tubos de cobre a elementos como depósitos de acero, la conexión se realizará mediante latiguillos de materiales plásticos de longitud suficiente que impidan la corrosión de los materiales por corrientes galvánicas.</li>
                            <li>Se comprobará que el aislamiento instalado tiene la conductividad térmica y el espesor especificados en la documentación técnica.</li>
                            <li>Se comprobará que el aislamiento térmico está convenientemente instalado en tuberías y accesorios, así como convenientemente protegido de la lluvia y la radiación solar en los tramos que circulen a la intemperie (en cumplimiento del párrafo i de la IT 2.4 “Eficiencia Energética que exige comprobar las pérdidas térmicas de distribución de la instalación hidráulica)</li>
                            <li>En el caso de emplear un fluido con anticongelante por existir riesgo de heladas, se deberá comprobar que el fluido anticongelante empleado cumple con los requisitos especificados en la Documentación Técnica.</li>
                        </ul>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos prevista.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}