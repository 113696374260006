import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Button, ButtonGroup, Grid, Paper, Link, Container, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core/';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import ContactsIcon from '@material-ui/icons/Contacts';
import DocumentIcon from '@material-ui/icons/AssignmentTurnedIn';
import MyUserIcon from '@material-ui/icons/Person';

const useStyles = theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '120px',
        borderRadius: '1rem',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
    root: {
        paddingBottom: theme.spacing(5),
    },
    heading: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5rem',
        textAlign: 'center',
        opacity: '0.5',
    },
    accordionNav: {
        padding: '0.5rem',
    },
    noPadding: {
        padding: '8px 0px 0px',
    }
});

/**Componente para la gestión de edificios. */
export class AdminPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    /**Declaración de los PropTypes */
    static propTypes = {
        // loadConfig: PropTypes.func.isRequired
    }

    /**
     * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los edificios
     * existentes en ese momento.
     */
    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        const user = this.props.user
        let is_superuser = user.is_superuser
        let is_admin = false
        if (is_superuser || user.user_type == "admin") {
            is_admin = true
        }
        return (
            <div className={classes.root}>
                <AccordionDetails className={classes.noPadding}>
                    <Container>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                            <Grid item>
                                <Link href={`/myuser`}>
                                    <Paper className={classes.paper} elevation={2}>
                                        <MyUserIcon /> <br />
                                        <Typography variant='caption' align='center'><Trans>Mi Perfil</Trans></Typography>
                                    </Paper>
                                </Link>
                            </Grid>
                            {is_superuser &&
                                <>
                                    <Grid item>
                                        <Link href={`/users`}>
                                            <Paper className={classes.paper} elevation={2}>
                                                <PeopleIcon /> <br />
                                                <Typography variant='caption' align='center'><Trans>Usuarios</Trans></Typography>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href={`/adminpanel/clients`}>
                                            <Paper className={classes.paper} elevation={2}>
                                                <ContactsIcon /> <br />
                                                <Typography variant='caption' align='center'><Trans>Clientes</Trans></Typography>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                </>
                            }
                            {is_admin &&
                                <>
                                    <Grid item>
                                        <Link href={`/adminpanel/buildings`}>
                                            <Paper className={classes.paper} elevation={2}>
                                                <BusinessIcon /> <br />
                                                <Typography variant='caption' align='center'><Trans>Edificios</Trans></Typography>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href={`/adminpanel/validation`}>
                                            <Paper className={classes.paper} elevation={2}>
                                                <DocumentIcon /> <br />
                                                <Typography variant='caption' align='center'><Trans>Documentos</Trans></Typography>
                                            </Paper>
                                        </Link>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Container>
                </AccordionDetails>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default compose(connect(mapStateToProps, {}), withStyles(useStyles),)(AdminPanel);
