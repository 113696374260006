import React, { Fragment, Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Typography, Container, TextField, Grid, Button, ButtonGroup, Select, MenuItem, InputLabel, Divider, FormControl } from '@material-ui/core/';
import { connect } from 'react-redux';
import { validateFile } from '../../actions/files';
import PropTypes from 'prop-types';

const useStyles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        minHeight: '0px',
    },
    root: {
        paddingTop: theme.spacing(4),
    }
});

export class ValidationForm extends Component {

    constructor(props) {
        super(props);
    }
    
    state = {
        id: 0,
        validation_description: "",
        validation_date_choice: "",
        validation: 0,
        validation_date_input: false
    }

    static propTypes = {
        validateFile: PropTypes.func.isRequired
    }

    onSubmit = e => {
        e.preventDefault();

        var { id, validation_description, validation_date_choice, validation } = this.state;
        if (validation_date_choice == "") validation_date_choice = null
        if (validation != 1) {validation_date_choice = null}

        const document = {
            id,
            validation_description,
            validation_date_choice,
            validation,
        };

        this.props.validateFile(document);
        // this.props.history.push('/' + this.props.match.params.idBuild + '/validation');
        // this.forceUpdate()
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    onChangeValidation = e => {
        this.setState({ [e.target.name]: e.target.value })
        if (e.target.value == 1) {
            this.setState({
                validation_date_input: true
            })
        } else {
            this.setState({
                validation_date_input: false
            })
        }
    }

    componentDidMount() {
        var { id, validation, validation_description, validation_date_choice } = this.props.history.location.state
        var validation_date_input = false
        if (validation_description == null) validation_description = ""
        // En caso de que pase a otro que no sea completado, aparece vacio.
        if (validation != 1) {validation_date_choice = ""}
        else { validation_date_input = true}

        this.setState({
            id,
            validation_description,
            validation_date_choice,
            validation,
            validation_date_input
        })
    }

    render() {

        const { classes } = this.props;
        const { id, validation_description, validation_date_choice, validation, validation_date_input } = this.state

        if (validation_date_input) {
            var validation_date_input_html = 
            <div>
                <FormControl fullWidth variant="outlined">
                <InputLabel id="date-validation-label" >Fecha de validación</InputLabel>
                <Select
                    fullWidth
                    labelId="date-validation-label"
                    id="date-validation"
                    label="Fecha de validación"
                    value={validation_date_choice}
                    variant="outlined"
                    name="validation_date_choice"
                    onChange={this.onChange}
                    placeholder="Selecciona una fecha de validación"
                >
                    <MenuItem value={1}>1 año</MenuItem>
                    <MenuItem value={2}>2 años</MenuItem>
                    <MenuItem value={5}>5 años</MenuItem>
                    <MenuItem value={10}>10 años</MenuItem>
                </Select>
                </FormControl>
            </div>
        } else {
            var validation_date_input_html = <div></div>
        }

        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>VALIDACIÓN DEL DOCUMENTO</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3} >
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                <TextField
                                    fullWidth
                                    label="Descripción"
                                    variant="outlined"
                                    name="validation_description"
                                    value={validation_description}
                                    onChange={this.onChange}
                                />
                                </FormControl>    
                                <FormControl fullWidth variant="outlined">
                                <InputLabel id="validation-label" >Validación del documento</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="validation-label"
                                    id="validation"
                                    label="Validación del documento"
                                    value={validation}
                                    variant="outlined"
                                    name="validation"
                                    onChange={this.onChangeValidation}
                                    placeholder="Selecciona la validación"
                                >
                                    <MenuItem value={1}>Completado</MenuItem>
                                    <MenuItem value={2}>Incompleto</MenuItem>
                                    <MenuItem value={3}>Aprobado</MenuItem>
                                    <MenuItem value={4}>Denegado</MenuItem>
                                    <MenuItem value={0}>Pendiente</MenuItem>
                                </Select>
                                </FormControl>
                                {validation_date_input_html}                            
                            </Grid>
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                            <ButtonGroup size='large' aria-label="contained primary button group">
                                <Button type="button" className="MuiAppBar-colorPrimary" onClick={this.onSubmit}><Trans>Validar documento</Trans></Button>
                                <Button type="button" className="MuiAppBar-colorWhite"  component={Link} to="/adminpanel/validation"><Trans>Volver</Trans></Button>
                            </ButtonGroup>
                        </Grid>
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    // validationValues: {}
});

export default compose(connect(mapStateToProps, { validateFile }), withStyles(useStyles),)(ValidationForm);