import axios from 'axios';
import { LOAD_MODULES } from './types';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';

// LOAD BUILDINGS
export const loadModules = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/module', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOAD_MODULES,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};