import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>EQUIPOS DE BOMBEO</Trans>
            </Typography>
            <Container>
              <Typography variant="body1" gutterBottom>
                  <Trans>El grupo de bombeo y el abastecimiento de agua que alimenta a las instalaciones contra incendios nuevas deberán ser probados y certificados antes de la puesta en servicio y ocupación del edificio conforme al Reglamento de Protección contra incendios RD 513/2017 y la norma UNE-EN 12845.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las pruebas serán dirigidas obligatoriamente por un representante cualificado del instalador autorizado, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos y los medios necesarios para las pruebas serán presentados por el instalador antes del inicio de las mismas para aprobación.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Certificados de materiales:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Certificado de materiales del fabricante del grupo incluyendo el cuerpo, rodete y eje de cada bomba.</li>
                    <li>Certificados de prueba hidráulica del cuerpo de cada bomba.</li>
                    <li>Certificado de conformidad a normas NFPA, UNE y/o CEPREVEN de cada grupo de bombeo completo.</li>
                    <li>Certificado de ensayo en taller del fabricante de los cuadros eléctricos.</li>
                    <li>Certificado de homologación por organismo indicado en la documentación si es de aplicación.</li>
                    <li>Certificado de funcionamiento ininterrumpido en banco de pruebas del fabricante de cada grupo de bombeo durante un mínimo de 30 minutos al 140 % de su caudal nominal con anotación de los resultados.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Prueba hidráulica:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Las tuberías de aspiración e impulsión se someterán a una prueba de estanqueidad y resistencia mecánica a una presión estática de 1.500 kPa (15 bar) manteniendo dicha presión al menos durante dos horas, no debiendo aparecer fugas en ningún punto de la instalación.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Limpieza de tuberías:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Después de la realización de las pruebas de estanqueidad se efectuará una limpieza de las tuberías por flujo de agua con los caudales indicados en CEPREVEN RT2.ABA. Se mantendrá el flujo hasta asegurarse de la perfecta limpieza de las tuberías, no utilizándose el colector de retorno para esta operación.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Verificación de los equipos:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Antes de iniciar las pruebas de funcionamiento se verificará cada uno de los puntos siguientes:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se comprobará que el depósito reserva de agua está lleno y que los dispositivos de nivel y reposición están conectados y operativos.</li>
                    <li>Se comprobará que las válvulas de corte en aspiración están abiertas y las bombas en carga. En el caso de motores Diesel se verificará que la válvula de corte del circuito de refrigeración está igualmente abierta y la salida de humos está conducida al exterior.</li> 
                    <li>Se comprobará que los cuadros eléctricos tienen tensión correcta. En caso de motores Diesel las baterías estarán conectadas y debe haber combustible en el depósito.</li>
                    <li>Se comprobará que el equipo dispone de circuito de pruebas con caudalímetro y retorno al depósito de agua conectado. </li>
                    <li>Se comprobará que se dispone de manovacuómetro en aspiración y manómetros en impulsión.</li>
                    <li>Se comprobará que la válvula de caudal mínimo y su tarado por debajo de la presión a caudal cero. Se comprobará que está conducida directamente al drenaje.</li>
                    <li>Se comprobará que todas las bombas han sido alineadas correctamente.</li>
                    <li>Se comprobará que los grupos están libres de cualquier resto de obra y los protectores de acoplamiento montados correctamente. Asegurarse que está perfectamente alineado y las partes móviles están libres de obstrucciones.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Prueba de funcionamiento:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>El instalador contará durante las pruebas de los grupos de bombeo contra incendios con la presencia y asesoramiento de un técnico de la empresa suministradora con experiencia suficiente tanto en el equipo como en los cuadros de control y sus automatismos.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Por cada grupo de bombeo se repetirán las pruebas de funcionamiento que constan en los certificados del fabricante indicados en el epígrafe anterior para verificar los valores. Esta prueba se realizará mediante el caudalímetro y colector de retorno recirculando el agua de nuevo al depósito, manteniendo en funcionamiento durante 30 minutos al 140% de su caudal nominal.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se comprobará el correcto funcionamiento de los automatismos de arranque y alarmas visuales y acústicas, así como la transmisión de alarmas a distancia.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se verificará la ausencia de fugas por las uniones, apretar los tornillos y/o sustituir juntas en caso necesario, comprobado que no se producen vibraciones o ruidos anormales.</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se regulará si fuera necesario las válvulas de caudal mínimo y de seguridad hasta conseguir los resultados solicitados.</Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Arranque automático de bombas principales:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se comprobará el nivel de combustible y lubricante de los motores Diesel.</li>
                    <li>Se arrancará automáticamente cada grupo reduciendo la presión de agua en el presostato y anotando la presión de arranque, repitiendo esta prueba al menos 4 veces.</li>
                    <li>En caso de motores Diesel se parará el motor y volverá a arrancar con el pulsador de prueba de arranque manual.</li>
                    <li>Se comprobará la presión de aceite de los motores Diesel así como el flujo de agua en el circuito de refrigeración.</li>
                    <li>Se comprobará la temperatura del motor y el estado de las mangueras de aceite y tubos de combustible, verificando que no existen fugas de combustible, refrigerante y/o humo.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Arranque automático de bomba jockey:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se arrancará automáticamente reduciendo la presión de agua en el presostato y anotando la presión de arranque. Se cerrará y mantendrá hasta la parada automática del grupo, regulando para mantener funcionando un tiempo mínimo este equipo de acuerdo con las instrucciones del fabricante y anotando la presión de parada.</li>
                    <li>Se repetirá esta prueba al menos 6 veces para verificar los valores.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="h5" gutterBottom>
                  <Trans>Prueba del sistema de llenado del aljibe:</Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>
                    <ul>
                    <li>Se verificará el dispositivo de llenado del aljibe, comprobando la apertura y cierre correcto de la válvula automática y de la válvula manual. En caso de existir válvula reductora de presión se anotará la presión de entrada y salida, comprobando que no hay goteos por la válvula de vaciado y fugas en el rebosadero.</li>
                    <li>Se comprobará los niveles disponibles y su operación, anotando las cotas de referencia de los mismos.</li>
                    <li>Se verificará que no hay fugas por el rebosadero y conexiones de vaciado.</li>
                    </ul>
                  </Trans>
              </Typography>
              <Typography variant="body1" gutterBottom>
                  <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
              </Typography>
            </Container>
        </Fragment>
    )
}