import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Table from '../layout/Table';
import { Typography, Button, ButtonGroup, Grid } from '@material-ui/core/';
import { loadBuildings, deleteBuilding, editBuilding } from '../../actions/building';
import { loadUsers } from '../../actions/users';
import { loadClients } from '../../actions/clients';
import store from '../../store';
import Select from 'react-select';

/**Componente para la gestión de edificios. */
export class BuildingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buildings: []
    }

    /**
     * Se crean las columnas para la tabla que mostrará los edificios creados hasta el momento.
     */

    this.columns = [
      {
        title: 'Nombre',
        data: 'name'
      },
      {
        title: 'Localización',
        data: 'location'
      },
      {
        title: 'Editar edificio',
        data: 'id',
        action: 'edit',
        f_action: editBuilding.bind(this),
      },
      {
        title: 'Borrar edificio',
        data: 'id',
        action: 'delete',
        f_action: deleteBuilding.bind(this),
        // render: function () {
        //   return '<a class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary" input type="submit" value="Click" onClick="{this.deleteBuilding()}"><i class="fa fa-trash" aria-hidden="true"></i></a>'
        // }
        // onClick="{this.deleteBuilding}' + data.id + '"
        // deleteBuilding('+data.id+')
      },
      // {
      //   //     title:'Asignación de edificios', //id de client
      //   //     data: null, render() {

      //   //     // this.state = {
      //   //     //   client_assigned:null
      //   //     //   };

      //   //     // client_assigned = (selectedOptions) => {
      //   //     //   let clientAsigned = []
      //   //     //   console.log(selectedOptions.value)
      //   //     //   selectedOptions.map(o => clientAsigned.push(o.value))
      //   //     //   console.log(clientAsigned)
      //   //     //   this.setState({ client_assigned: clientAsigned })
      //   //     //   } 
      //   //     //   const { client_assigned } = this.state;
      //   //     //   var clientDrop = store.getState().clients.client;
      //   //     //   const optionsClient= []
      //   //     //   clientDrop.forEach(element => optionsClient.push({label: element.name, value:element.id}))
      //   //     //   console.log(optionsClient);

      //   //     //   return ( 
      //   //     //   <form>
      //   //     //   <Select
      //   //     //   width="100%"
      //   //     //   isMulti
      //   //     //   onChange={this.client_assigned}
      //   //     //   options={optionsClient}
      //   //     //   // styles={customStyles}
      //   //     //   placeholder="Selecciona uno o varios clientes"/>
      //   //     //   {/* <Button type="submit" color="primary"><Trans>Crear cliente</Trans></Button> */}
      //   //     // </form>
      //   //     // )
      //   //   } 
      // }
    ]

    function deleteBuilding(e, rowData) {
      this.props.deleteBuilding(rowData.id);
    }

    function editBuilding(e, rowData) {
      window.location.href = `editbuilding/${rowData.id}`;
    }
  }


  // onSubmit = e => {
  //   e.preventDefault();
  //   const { client_assigned } = this.state;

  //     const clientBuilding = {
  //       client_assigned
  //     };
  //     this.props.client_assigned(clientBuilding);
  //   }

  /**Declaración de los PropTypes */
  static propTypes = {
    deleteBuilding: PropTypes.func.isRequired,
    editBuilding: PropTypes.func.isRequired,
  }


  /**
   * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los edificios
   * existentes en ese momento.
   */
  componentDidMount() {
    this.props.loadBuildings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.buildings !== this.props.buildings) {
      this.setState({
        buildings: this.props.buildings
      })
    }
  }

  render() {
    const { buildings } = this.state

    return (
      <div className="root" >
        <Typography variant="h3" gutterBottom>
          <Trans>Gestión de edificios</Trans>
        </Typography>
        <Grid container direction="row" justify="flex-end" alignItems="flex-end">
          <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
            <Button type="button" color="primary" component={Link} to="/adminpanel/newbuilding"><Trans>Nuevo edificio</Trans></Button>
          </ButtonGroup>
        </Grid>
        <Table
          title=""
          columns={this.columns}
          data={buildings}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  buildings: state.buildings.buildings,
});

export default connect(mapStateToProps, { loadUsers, loadBuildings, deleteBuilding, editBuilding, loadClients })(BuildingList);
