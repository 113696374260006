import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import {
    FILE_FAIL, FILE_SUCCESS, FILE_UPLOAD, FILE_UPDATE,
    JSON_SUCCESS, DOCUMENT_SUCCESS, ASINGED_SUCCESS, ISLOADING,
    DOCUMENTTYPE_SUCCESS, COUNTER_SUCCESS
} from './types';
import { tokenConfig, tokenConfigFile } from './auth';
import { toast } from 'react-toastify';


// Recogida de los tipos de documentos

export const getDocumentTypes = (build_id, submodel_id) => (dispatch, getState) => {
    // Recibe el id del submodelo y devuelve los tipos de documentos asociados
    axios.get(`/api/v1/exta/document/type/${build_id}/${submodel_id}`, tokenConfig(getState))
        .then(res => {
            console.log(res)
            dispatch({
                type: DOCUMENTTYPE_SUCCESS,
                payload: res.data
            })
        }).catch(err => {
            dispatch(
                returnErrors(err.response.data, err.response.status)
            );
        });
}

// Recogida de los documentos

export const getFiles = (build_id, submodel_id) => (dispatch, getState) => {
    axios.get(`/api/v1/exta/document/${build_id}/${submodel_id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: FILE_SUCCESS,
                payload: res.data
            })
        }).catch(err => {
            dispatch(
                returnErrors(err.response.data, err.response.status)
            );


        });
}

export const fileAdministrator = (document, newDocument, idBuild) => (dispatch, getState) => {
    console.log("Aqui")

    let formData = new FormData();
    formData.append('file', newDocument)

    if (document.file != null) {
        formData.append('name', document.file.name)
        formData.append('building', document.file.building)
        formData.append('document_type', document.file.document_type)
        formData.append('module', document.file.module)
        formData.append('validation', document.file.validation)
        axios.put(`/api/v1/exta/document/${document.file.id}`, formData, tokenConfigFile(getState))
            .then(res => {
                dispatch({
                    type: FILE_UPDATE,
                    payload: res.data
                })
            }).catch(err => {
                dispatch(
                    returnErrors(err.response.data, err.response.status)
                );
            });
    } else {
        formData.append('name', "test_name") // TODO: ¿QUE NOMBRE SE LE PONE?
        formData.append('building', idBuild)
        formData.append('document_type', document.id)
        formData.append('module', document.submodule_assigned)
        formData.append('validation', 0)
        axios.post(`/api/v1/exta/document/`, formData, tokenConfigFile(getState))
            .then(res => {
                dispatch({
                    type: FILE_UPLOAD,
                    payload: res.data
                })
            }).catch(err => {
                dispatch(
                    returnErrors(err.response.data, err.response.status)
                );
            });
    }
}

export const JSONAdministrator = (document, newDocument) => (dispatch, getState) => {

    // Recoge la varriable y lo convierte en File para luego subirlo al servidor
    const blob = new Blob([newDocument], { type: "text/json" })
    let jsonFile = new File([blob], "parcial_name.json")
    let formData = new FormData();

    if (document.file != null) {
        formData.append('file', jsonFile)
        formData.append('name', document.file.name)
        formData.append('building', document.file.building)
        formData.append('document_type', document.file.document_type)
        formData.append('module', document.file.module)
        formData.append('validation', document.file.validation)

        axios.put(`/api/v1/exta/document/${document.file.id}`, formData, tokenConfigFile(getState))
            .then(res => {
                dispatch({
                    type: FILE_UPDATE,
                    payload: res.data
                })
            }).catch(err => {
                dispatch(
                    returnErrors(err.response.data, err.response.status)
                );
            });
    } else {
        formData.append('file', jsonFile)
        formData.append('name', "test_name_json")
        formData.append('building', document.building)
        formData.append('document_type', document.id)
        formData.append('module', document.submodule_assigned)
        formData.append('validation', 0)
        axios.post(`/api/v1/exta/document/`, formData, tokenConfigFile(getState))
            .then(res => {
                dispatch({
                    type: FILE_UPLOAD,
                    payload: res.data
                })
            }).catch(err => {
                dispatch(
                    returnErrors(err.response.data, err.response.status)
                );
            });
    }

}

export const getJSON = (path) => (dispatch, getState) => {
    if (path != null) {
        let filePath = ""
        if (path.file){
            filePath = path.file.split("media")[1]
        } else {
            filePath = path.split("media")[1]
        }
        
        axios.get(`/media${filePath}`, tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: JSON_SUCCESS,
                    payload: res.data
                })
            }).catch(err => {
                dispatch(
                    returnErrors(err.response.data, err.response.status)
                );
            });
    }
}

export const getFilesToCount = () => (dispatch, getState) => {

    axios.get(`/api/v1/exta/document/`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DOCUMENT_SUCCESS,
                payload: res.data,
            })
        }).catch(err => {
            dispatch(
                returnErrors(err.response.data, err.response.status)
            );
        });
}

export const bannerCounter = (idBuild, path) => (dispatch, getState) => {

    let id_module = 0;

    if (path.includes("socrates")) {
        id_module = 1
    } else if (path.includes("plato")) {
        id_module = 2
    } else if (path.includes("aristotle")) {
        id_module = 3
    }

    axios.get(`/api/v1/exta/banner/${idBuild}/${id_module}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: COUNTER_SUCCESS,
                payload: res.data
            })
        }).catch(err => {
            dispatch(
                returnErrors(err.response.data, err.response.status)
            );
        });
}

export const validateFile = (validation) => (dispatch, getState) => {

    axios.put(`/api/v1/exta/document/validate/${validation.id}`, validation, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ASINGED_SUCCESS
            })
            toast.success(`Documento validado con éxito`)
        }).catch(err => {
            toast.error(err)
            dispatch(
                returnErrors(err.response.data, err.response.status)
            );
        });
}
