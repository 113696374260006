import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../layout/Table';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import { loadAlerts, readAlert } from "../../actions/alert"


/**Componente para la gestión de usuarios. */
export class AlertsList extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'ID',
        data: 'id'
      },
      {
        title: 'Nombre',
        data: 'name'
      },
      {
        title: 'Descripción',
        data: 'description',
      },
      {
        title: 'Cliente',
        data: 'client_name'
      },
      {
        title: 'Documento',
        data: 'document_name'
      },
      {
        title: 'Fecha',
        data: 'timestamp'
      },
      {
        title: 'Ocultar',
        data: 'id',
        action: 'read_alert',
        f_action: readAlert.bind(this)
      },
    ]

    function readAlert(e, rowData){
      this.props.readAlert(rowData.id, {'readed':true})
    }
  }
  static propTypes = {
    alerts: PropTypes.array.isRequired
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    alerts: [],
  };

  render() {
    return (
      <Container>
        <Table
          title="Gestión de Notificaciones"
          columns={this.columns}
          data={this.props.alerts} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  alerts: state.alerts.alerts,
});

export default connect(mapStateToProps, { loadAlerts, readAlert })(AlertsList);
