import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import {getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Normativa',
        data: 'normative',
      },
      {
        title: 'Documentación\ndisponible',
        data: null, render: function (data, type, row) {
          let url = ""
          data.state = "no"
          data.detail = "No hay ningun archivo asociado";
          data.download = "disabled"
          if (data.file != null && data.file.file) {
            data.download = "disponible"
            data.state = "si"
            data.detail = "Texto si tiene archivo"
          }
          if (data.file != null) {
            url = `${data.file.file}`
            if (url.includes("http") == false){
              url = `${axios.defaults.baseURL}${data.file.file}`
            }
            return `<div class="tooltip-exta"><a href="${url}" target="_blank"  class="MuiButtonBase-root MuiButton-root Mui-'${data.download}' MuiButton-outlinedPrimary">${data.state}<i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">${data.detail}</span></div>`
          }
          return '<div class="tooltip-exta"><a href="' + url + '" class="MuiButtonBase-root MuiButton-root Mui-' + data.download + ' MuiButton-outlinedPrimary">' + data.state + ' <i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">' + data.detail + '</span></div>'
        },
      },
      {
        title: 'Subir\ndocumentación',
        data: null,
        action: 'upload',
        f_action: ''
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          return '<a href="#info-modal' + data.id + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"><i class="fa fa-info-circle" aria-hidden="true"></i></a><a href="#"><div id="info-modal' + data.id + '" class="modal info-modal"><div class="MuiPaper-rounded MuiPaper-root modal-contenido"><h4 class="text-modal">' + data.description + '</h4><p class="text-modal">' + data.information + '</p></div></div></a>'
        },
      }
    ]
  }
  static propTypes = {
    document_types: PropTypes.array,
    getDocumentTypes: PropTypes.func,
    isLoading: PropTypes.bool
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Licencia ocupación', description: 'OMTLU MADRID', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Para la ocupación del edificio es necesario disponer de la Licencia de primera ocupación y funcionamiento. La licencia de primera ocupación lo emite el Ayuntamiento una vez comprobado que el edificio ha sido ejecutado según el proyecto y condiciones en que la licencia fue concedida. Es relevante su obtención, ya que constata que las obras y actividades están terminadas y aptas en base a las condiciones urbanísticas, ambientales y de seguridad requeridas.' },
      // { id: 2, name: 'Autorización sanitaria del centro', description: 'Decreto 51/2006', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En el caso de ser un edificio sanitario o disponer  de servicios regulados por la Consejería de Sanidad se debe disponer de la preceptiva autorización sanitaria. En el caso de la Comunnidad de Madrid se regula a través del  Decreto 51/2006, de 15 de junio, del Consejo de Gobierno, Regulador del Régimen Jurídico y Procedimiento de Autorización y Registro de Centros, Servicios y Establecimiento Sanitarios de la Comunidad de Madrid.' },
      // { id: 3, name: 'Autorización sanitaria cocina', description: 'Ley 12/2001', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En España las regulaciones referidas a las Autorizaciones Sanitarias de cocinas y restaurantes dependen de cada municipio, se realiza a través de los organismos locales municipales o Ayuntamientos. En caso de disponer de cocina o restaurante corporativo se debe disponer de la preceptiva autorización para su explotación.' },
      // { id: 4, name: 'Autorización sanitaria cafetería', description: 'Ley 12/2001', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En España las regulaciones referidas a las Autorizaciones Sanitarias de cocinas y restaurantes dependen de cada municipio, se realiza a través de los organismos locales municipales o Ayuntamientos. En caso de disponer de cocina o restaurante corporativo se debe disponer de la preceptiva autorización para su explotación.' },
      // { id: 5, name: 'Autorización Funcionamiento', description: 'OMTLU MADRID', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Para la ocupación del edificio es necesario disponer de la Licencia de primera ocupación y funcionamiento. La licencia de primera ocupación lo emite el Ayuntamiento una vez comprobado que el edificio ha sido ejecutado según el proyecto y condiciones en que la licencia fue concedida. Es relevante su obtención, ya que constata que las obras y actividades están terminadas y aptas en base a las condiciones urbanísticas, ambientales y de seguridad requeridas.' },
    ],
  };

  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 2
    this.props.getDocumentTypes(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {

    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];
   
    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(AdminList));
