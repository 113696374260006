import React, { Fragment } from 'react';
import { TextField, Grid } from '@material-ui/core/';

export default function Signature() {    
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <TextField fullWidth label="Por la E. instaladora" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Por la E. de control" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Vº Bº Dirección facultativa" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Fecha" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Fecha" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Fecha" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Firma" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Firma" variant="outlined" />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Firma" variant="outlined" />
                </Grid>
            </Grid>
        </Fragment>
    )
}