import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }
    };

    render() {
        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>ELECTRICIDAD-003</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>BATERÍA DE CONDENSADORES</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Características BATERIAS CONDENSADORES</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Nº serie" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Modelo" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Potencia nominal (kVA)" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Fecuencia de sintonización (Hz)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tensión (V)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Regulador" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Escalonaje" variant="outlined" />
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>Verificación/puesta en servicio</Trans>
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Con el equipo en funcionamiento en carga realizar las siguientes comprobaciones</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Entrada de escalones de compensación" variant="outlined" />
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>OBSERVACIONES</Trans>
                        </Typography>
                        <TextField fullWidth label="Observaciones" multiline rows={6} variant="outlined" />

                        <Typography variant="h5" gutterBottom>
                            <Trans>CONFORMIDAD</Trans>
                        </Typography>
                        <TextField fullWidth label="Firma persona que realiza el protocolo" variant="outlined" />
                        <TextField fullWidth label="Fecha" variant="outlined" />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);