import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones de fontanería</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PREPARACIÓN Y LIMPIEZA DE LAS REDES DE TUBERÍAS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado de la instalación son preceptivas bajo el CTE DB HS 4 del Código Técnico de la Edificación que establece las pruebas y ensayos a realizar en la instalación terminada.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la preparación y limpieza de las redes de tuberías.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Preparación y limpieza de las redes de tuberías</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La preparación y limpieza de las redes de tuberías implica la realización de los siguientes procedimientos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Se llenará y vaciará la instalación el número de veces que sea necesario con agua.</li>
                            <li>Se pondrán en funcionamiento las bombas tras el llenado y se dejará circular el agua durante dos horas.</li>
                            <li>Se vaciará totalmente la red y se enjuagará con agua procedente del dispositivo de alimentación.</li>
                            <li>Se medirá el pH del agua del circuito en el caso de redes cerradas destinadas a la circulación de fluidos con temperaturas de funcionamiento menor que 100ºC. Si el pH resultara menor que 7,5 se repetirá la operación de limpieza y enjuague tantas veces como sea necesario. A continuación, se pondrá en funcionamiento la instalación con sus aparatos de tratamiento.</li>
                            <li>Se dejarán los filtros de malla metálica puestos para protección de las bombas durante una semana de funcionamiento, hasta que se compruebe que ha sido completada la eliminación de las partículas más finas que puede retener el tamiz de la malla. Sin embargo, los filtros para protección de válvulas automáticas y contadores entre otros se dejarán en su sitio.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}