import React, { Fragment, Component } from 'react';
import { Grid, Button } from '@material-ui/core/';
import { JSONAdministrator } from '../../../../../actions/files';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


export class Submit extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        JSONAdministrator: PropTypes.func.isRequired
    }

    /**
     * Se encarga de que cuando se haga click sobre el boton de "Guardar",
     * este lo que haga es enviar la información del documento que se abrio el formulario
     * y enviar el formulario con los datos.
     */
    clickHandler(e, real_document) {

        const formSerialize = formElement => {
            const values = {};
            const inputs = formElement.elements;

            if (inputs.yes){
                values.yes = []
                for (let i = 0; i < inputs.yes.length; i++) {
                    values.yes[i] = inputs.yes[i].checked;
                }
            }

            if (inputs.no){
                values.no = []
                for (let i = 0; i < inputs.no.length; i++) {
                    values.no[i] = inputs.no[i].checked;
                }
            }
            
            for (let i = 0; i < inputs.length; i++) {
                values[i] = inputs[i].value;
            }
            
            return values;
        }

        var form = document.querySelector('form');
        const json_file = JSON.stringify(formSerialize(form));
        this.props.JSONAdministrator(real_document, json_file);
        
        var pathSplitter = this.props.match.url.split("/");
        var path = "/" + pathSplitter[1] + "/" + pathSplitter[2] + "/" + pathSplitter[3];

        this.props.history.push(path)
        
    }

    render() {
        const real_document = this.props.document
        const { user_permissions } = this.props

        var submitButton = ""

        if (!real_document.origin.includes("/validation") && user_permissions.includes(3)) {
            submitButton = 
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Button variant="outlined" onClick={(e) => this.clickHandler(e, real_document)}>GUARDAR</Button>
            </Grid>
        }
        return (
            <Fragment>
                {submitButton}
            </Fragment>
        )
    }

}

const mapStateToProps = state => ({
    user_permissions: state.auth.user.permissions,
});

export default withRouter(connect(mapStateToProps, { JSONAdministrator })(Submit));