import axios from 'axios';
import { GET_BUILDING, ADD_BUILDING, LOAD_BUILDINGS, DELETE_BUILDING, EDIT_BUILDING, LOAD_BUILDING } from './types';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';


// CREATE BUILDING
export const createNewBuilding= ({ name, location }) => (dispatch, getState) => {
    // Request Body
    const body = JSON.stringify({ name, location });

    axios
        .post('/api/v1/exta/build/', body, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_BUILDING,
                payload: res.data
            })
            toast.success(`Se ha creado el edificio: ${res.data.name} con éxito`)
        })
        .catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};


// LOAD BUILDINGS
export const loadBuildings = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/build/', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOAD_BUILDINGS,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// LOAD BUILDING
export const loadBuilding = (id) => (dispatch, getState) => {
    axios.get(`/api/v1/exta/build/${id}`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOAD_BUILDING,
            payload: res.data
        });
    }).catch(err => {
        toast.error(err)
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// DELETE BUILDING
export const deleteBuilding = (id) => (dispatch, getState) => {
    axios
        .delete(`/api/v1/exta/build/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({ delete: 'Building deleted' }))
            dispatch({
                type: DELETE_BUILDING,
                payload: res.data.data
            })
            console.log(res)
            toast.success(`Se ha borrado el edificio con éxito`)
        }).catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};

// EDIT BUILDING
export const editBuilding = (building) => (dispatch, getState) => {
    axios
        .put(`/api/v1/exta/build/${building.id}`, building, tokenConfig(getState))
        .then(res => {
            toast.success(`Se ha editado el edificio con éxito`)
            dispatch({
                type: EDIT_BUILDING,
                payload: res.data
            })
        }).catch(err => {
            toast.error(err)
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};
