import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones de fontanería</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>VERIFICACIÓN DE LA EJECUCIÓN DEL MONTAJE</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado del circuito hidráulico son preceptivas bajo el CTE DB HS 4 del Código Técnico de la Edificación que establece las pruebas y ensayos a realizar en la instalación terminada.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la verificación de la ejecución del montaje siguiendo el esquema de principio del Proyecto o Memoria Técnica </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de la ejecución del montaje</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La verificación de la ejecución del montaje implica la realización de los siguientes procedimientos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                        <li>Se comprobará las características de la tubería en la acometida, así como la situación y dimensiones de los pasatubos y llaves de esta.</li>
                        <li>Se comprobará la ubicación, tipo y diámetro del contador, así como el diámetro de la tubería de alimentación y el tipo y la ubicación de las llaves de corte y retención.</li>
                        <li>Se comprobará las características y dimensiones del acumulador, así como la situación y dimensión del rebosadero, la comunicación de los depósitos y las características y dimensiones de las válvulas de llenado.</li>
                        <li>Se comprobará que el trazado de las tuberías.</li>
                        <li>Se comprobará que el material de las tuberías instaladas se corresponde con el proyecto, así como su espesor (o serie en el caso de tuberías de plástico) y sus elementos de unión, protecciones y pasamuros.</li>
                        <li>Se comprobará que la sujeción de las tuberías a los cerramientos sea correcta y que se respetan las distancias de separación con otras conducciones.</li>
                        <li>Se comprobará los elementos de dilatación y el tipo y montaje de la valvulería.</li>
                        <li>Se comprobará que el aislamiento instalado tiene la conductividad térmica y el espesor especificados en la documentación técnica.</li>
                        <li>Se comprobará, en el caso de disponer de termo eléctrico, las características, ubicación, consumo y capacidad del mismo, así como la sección y tensión de aislamiento de los conductos activos de alimentación y protección y la disposición de la válvula de seguridad y desagüe.</li>
                        <li>Se comprobarán las características de los aparatos sanitarios, así como su soportación, grifería y valvulería asociada, incluido desagües.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}