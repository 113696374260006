import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { Grid, Container } from '@material-ui/core/';
import NavPlato from '../plato/nav';
import NavSocrates from '../socrates/nav';
import NavAristotle from '../aristotle/nav';
import NavSpinoza from '../spinoza/nav';
import NavBernini from '../bernini/nav';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));

/**
 * Componente que contiene la información de la cabecera de la aplicación.
 *
 * @example ../../../../docs/Frontend/Layout/Header.md
 */
export default withTranslation()(function Nav(props) {
  let modules = []
  const classes = useStyles();
  if (props.modules) {
    modules = props.modules
  }
  return (
    <Fragment>
      <Container maxWidth="sm">
        <div className={classes.root}>
          <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
            {modules.includes('Sócrates') &&
              <Grid item xs={12}>
                <NavSocrates />
              </Grid>
            }
            {modules.includes('Platón') &&
              <Grid item xs={12}>
                <NavPlato />
              </Grid>
            }
            {modules.includes('Aristóteles') &&
              <Grid item xs={12}>
                <NavAristotle />
              </Grid>
            }
            {console.log(modules)}
            {modules.includes('Spinoza') &&
              <Grid item xs={12}>
                <NavSpinoza />
              </Grid>
            }
            {modules.includes('Bernini') &&
              <Grid item xs={12}>
                <NavBernini />
              </Grid>
            }
            {/**<Grid item xs={12}>
                <NavSpinoza />
              </Grid>**/}
          </Grid>
        </div>
      </Container>
    </Fragment>
  );
})
