import React, { Fragment } from 'react';
import { TextField, Grid } from '@material-ui/core/';

export default function Metre(time_used) {
    const num = time_used.time_used
    const equipo_name = "input_equipo_" + num
    const n_name = "input_n_" + num
    const obser_name = "input_obser_" + num
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <TextField fullWidth label="Equipo" variant="outlined" name={equipo_name} />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Nº" variant="outlined" name={n_name} />
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth label="Observaciones" variant="outlined" name={obser_name} />
                </Grid>
            </Grid>
        </Fragment>
    )
}