import { GET_ERRORS } from '../actions/types';

const initialState = {
    msg: {},
    status: null
}

/**Función que controla los mensajes de error para PNotify */
export default function(state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                msg: action.payload.msg,
                status: action.payload.status
            }
        default:
            return state;
    }
}