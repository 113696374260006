import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>SISTEMAS AUTOMÁTICOS AGUA PULVERIZADA</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Todos los sistemas nuevos de agua pulverizada deberán ser probados y certificados antes de la puesta en servicio del riesgo que protegen.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas serán realizadas obligatoriamente por un representante cualificado del instalador, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos para las pruebas serán presentados para aprobación antes del inicio de las mismas.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Certificado de conformidad a normas EN o UNE, NFPA, etc. emitido por la Empresa Instaladora.</li>
                            <li>Certificados de Aprobación y Homologación aplicables UL, FM, VdS, ULC, etc. de las válvulas de alarma, válvulas de corte principal, pulverizadores e interruptores de flujo.</li>
                            <li>Certificado de materiales del fabricante para la tubería, accesorios y soportación.</li>
                            <li>Certificado de tratamiento anticorrosivo de la tubería (galvanizado, etc.).</li>
                            <li>Certificado de homologación de los soldadores y ensayos de soldaduras si son de aplicación.</li>
                            <li>Cálculos hidráulicos para cada sistema.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de aceptación:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La totalidad de la instalación se someterá a una prueba de estanqueidad y resistencia mecánica a una presión estática de <strong>1.500 kPa (15 bar)</strong> cuando la presión de trabajo sea igual o inferior a <strong>1.000 kPa (10 bar)</strong> manteniendo dicha presión al menos durante dos horas, no debiendo aparecer fugas ni deformaciones en ningún punto de la instalación. La presión de prueba deberá ser de <strong>500 kPa (5 bar)</strong> por encima de la de trabajo cuando ésta sea superior a <strong>1.000 kPa (10 bar).</strong></Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión de prueba será medida en el punto más bajo de la instalación o sector probado, eliminando el aire totalmente en los puntos altos.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En la prueba se emplearán tapones para la prueba y se reemplazarán posteriormente por los pulverizadores abiertos una vez completada la misma favorablemente. No se utilizarán aditivos en el agua de pruebas de los que se emplean para taponar las fugas.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los sistemas:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                        <li>Se comprobará que el sistema cubre la totalidad del área o el riesgo definido en proyecto. Las coberturas de los pulverizadores deben ser las indicadas en los cálculos, prestando atención a los elementos estructurales que puedan producir obstrucciones en las descargas de las boquillas.</li> 
                        <li>Se verificará las distancias de seguridad a posibles elementos en tensión en caso de riesgos eléctricos en tensión.</li>
                        <li>Se comprobará todos los modelos de pulverizadores empleados anotando la marca y modelos, orificios y cantidad utilizada.</li>
                        <li>Se comprobará la válvula de alarma y sus accesorios anotando el modelo, diámetro y lecturas del manómetro situado aguas arriba. En sistemas con detección neumática para el disparo se anotará también la presión del aire. Se verificará que la válvula de corte principal está abierta y bloqueada en esa posición. </li>
                        <li>Se verificará que hay filtro en la instalación, debiendo este estar correctamente instalado y limpio de suciedad y residuos propios del montaje. </li>
                        <li>Se comprobará las tuberías en todo su recorrido, verificando que se corresponden a lo indicado en planos en cuanto a diámetros y trazado y que está debidamente soportada. La pintura será uniforme y del color determinado en proyecto.</li>
                        <li>Se verificará que los dispositivos eléctricos de alarma tales como detectores de incendio, pulsadores, interruptores de flujo, presostatos, etc. están conectados y transmiten correctamente a la central de control.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de funcionamiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En la prueba de estos sistemas se produce la descarga real de agua en el riesgo o local protegido por lo que se tomarán especiales medidas de precaución para evitar daños. Se comprobará que hay desagües adecuados en la zona y que la prueba puede realizarse.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                        <li>En la prueba de estos sistemas se produce la descarga real de agua en el riesgo o local protegido por lo que se tomarán especiales medidas de precaución para evitar daños. Se comprobará que hay desagües adecuados en la zona y que la prueba puede realizarse. </li>
                        <li>Se actuará en los detectores de incendio previstos como se indique en el protocolo de detección correspondiente (disparo automático). </li>
                        <li>Se comprobará que se produce la apertura de la válvula de control y la descarga de agua en el riesgo, verificando que todos los pulverizadores descargan uniformemente, limpiando aquellos que estén obstruidos, reorientando los que se requiera y anotando la presión dinámica en el puesto de control.</li>
                        <li>Se verificará el comportamiento de las tuberías no debiendo haber fugas ni movimientos excesivos, estabilizándose así rápidamente el sistema.</li>
                        <li>Se comprobará que el caudal de paso es el requerido en el proyecto. Se utilizará principalmente un medidor digital instalado en la tubería principal o mediante lecturas de presiones en las bombas contra incendios.</li>
                        <li>Se comprobará el funcionamiento de la campana hidráulica si se dispone en el puesto de control, verificando que el presostato de alarma transmite correctamente a la central de control.</li>
                        <li>Se comprobará el rearme del sistema y que la válvula de alarma cierra completamente no debiendo producirse golpes en esta operación y abriendo la válvula de drenaje principal. </li>
                        <li>Se repetirá la actuación mediante los dispositivos de disparo manuales previstos.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}