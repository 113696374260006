import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import Signature from '../components/signature';
import Metre from '../components/metre';
import YesNo from '../components/yes-no';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>PRESURIZACION DE ESCALERAS-013</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>PUESTA EN MARCHA PRESURIZACIÓN DE ESCALERAS Y VESTÍBULOS</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>DATOS GENERALES</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Técnico" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación de la escalera/vestíbulo" variant="outlined" />
                            </Grid>
                        </Grid>

                        <Typography variant="h5" gutterBottom>
                            <Trans>PUESTA EN MARCHA CON PUERTAS CERRADAS</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se han cerrado todas las puertas de escaleras y vestíbullos de todas las plantas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha activado el ventilador de presurización de escalera y hueco de ascensor (si conecta con vestíbulo)?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha activado el ventilador de presurización de los vestíbulos y se han regulado las rejillas de salida?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha ajustado el caudal del ventilado de presurización de vestíbulos?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La regulación de sobrepresión se mantiene estable en el tiempo?</Trans>
                        </Typography>
                        <YesNo />

                        <Typography variant="body1" gutterBottom>
                            <Trans>MEDIDAS VENTILADOR DE PRESURIZACIÓN DE ESCALERA Y HUECO DE ASCENSOR</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Aparatos de medida" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión (respecto a ambiente interior o punto exterior)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Caudal ventilador</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Consumo motor</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión estática (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión total (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Ajuste variador de velocidad/posición compuerta</Trans>
                        </Typography>
                        <Metre />

                        <Typography variant="body1" gutterBottom>
                            <Trans>MEDIDAS VENTILADOR DE PRESURIZACIÓN DE LOS VESTÍBULOS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Aparatos de medida" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión (respecto a ambiente interior o punto exterior)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Caudal ventilador</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Consumo motor</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión estática (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión total (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Ajuste variador de velocidad/posición compuerta</Trans>
                        </Typography>
                        <Metre />

                        <Typography variant="h5" gutterBottom>
                            <Trans>PUESTA EN MARCHA CON PUERTAS ABIERTAS</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se han cerrado todas las puertas escalera-vestíbulo-riesgo están abiertas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha ajustado el variador del ventilador hasta conseguir en puertas la velocidad prevista?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha comprobado la acción inversa de cierre de puertas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha ajustado el caudal del ventilado de presurización de vestíbulos?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La regulación de sobrepresión se mantiene estable en el tiempo?</Trans>
                        </Typography>
                        <YesNo />

                        <Typography variant="body1" gutterBottom>
                            <Trans>MEDIDAS (PUERTAS ABIERTAS)</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Aparatos de medida" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Caudal ventilador</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Consumo motor</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión estática (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión total (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Ajuste variador de velocidad/posición compuerta</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Velocidad en las puertas abiertas</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Lectura presostato</Trans>
                        </Typography>
                        <Metre />

                        <Typography variant="body1" gutterBottom>
                            <Trans>MEDIDAS (ACCIÓN INVERSA - PUERTAS CERRADAS)</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Aparatos de medida" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión (respecto a ambiente interior o punto exterior)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Caudal ventilador</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Consumo motor</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión estática (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión total (conducto impulsión)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Ajuste variador de velocidad/posición compuerta</Trans>
                        </Typography>
                        <Metre />

                        <Typography variant="h5" gutterBottom>
                            <Trans>RESULTADO DE LA ACTIVIDAD</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />

                        <Typography variant="h5" gutterBottom>
                            <Trans>CONFORMIDAD</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);