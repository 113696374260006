import React from 'react';
import {  Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Index from '../layout/Index';

/**Componente de creación de rutas privadas.
 * La función de las rutas privadas es evitar la navegación entre componentes en caso de que el usuario no esté logueado.
 */
const PrivateRoute = ({ component: Component, auth, ...rest}) => {
    return(
        <Route 
            {...rest}
            render={props => {
                if(auth.isLoading) {
                    return <h2>Loading...</h2>;
                } else if (!localStorage.getItem("token")) {
                    return <Redirect to="" />
                } else {
                    return <Component {...props}><Index /></Component>
                }
            }}
        />
    )
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
