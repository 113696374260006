import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerUser, loadPermissions } from '../../../actions/users';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, TextField, FormControlLabel, Checkbox, Grid, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core/';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

/**Componente que contiene el formulario para la creación de nuevos usuarios. */
export class NewUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      email: '',
      password: '',
      password2: '',
      first_name: '',
      last_name: '',
      is_staff: 'false',
      is_superuser: 'false',
      permissions: [],
      user_type: '',
      permissions_list: [],
      user_type_list: [
        { name: "Administrador", value: "admin" },
        { name: "Cliente", value: "client" }
      ]
    }

  }

  static propTypes = {
    registerUser: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  componentDidMount() {
    this.props.loadPermissions()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        permissions_list: this.props.permissions_list
      })
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { username, email, password, password2, is_staff, is_superuser, first_name, last_name, permissions, user_type } = this.state;
    if (password != password2) {
      toast.error('Las contraseñas no coinciden')
    }
    else {
      const newUser = {
        username,
        email,
        password,
        password2,
        first_name,
        last_name,
        is_staff,
        is_superuser,
        permissions,
        user_type
      };

      this.props.registerUser(newUser);
      this.props.history.push('/users');
    }
  }

  onChangeSuperuser = e => {
    let is_checked = this.state.is_superuser
    let is_superuser = false
    let is_staff = false

    if (is_checked) {
      is_superuser = true
      is_staff = true
    }
    this.setState({
      is_superuser: is_superuser,
      is_staff: is_staff
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const logged_user = this.props.user
    const { username, email, password, password2, first_name, last_name, permissions, user_type, permissions_list, user_type_list } = this.state;
    return (
      <div className="root">
        <Typography variant="h3" gutterBottom>
          <Trans>Crear usuario</Trans>
        </Typography>
        <form className="root"
          onSubmit={this.onSubmit}>
          <TextField variant="outlined" margin="normal" id="user-new-name" label="Nombre" type="text" fullWidth
            name="username"
            onChange={this.onChange}
            value={username} required />
          <TextField variant="outlined" margin="normal" id="user-password" label="Contraseña" type="password" fullWidth
            name="password"
            onChange={this.onChange}
            value={password} required />
          <TextField variant="outlined" margin="normal" id="user-confirm-password" label="Confirmar contraseña" type="password" fullWidth
            name="password2"
            onChange={this.onChange}
            value={password2} required />
          <TextField variant="outlined" margin="normal" id="user-personal-name" label="Nombre Personal" type="text" defaultValue="" fullWidth
            name="first_name"
            onChange={this.onChange}
            value={first_name} required />
          <TextField variant="outlined" margin="normal" id="user-surname" label="Apellido" type="text" defaultValue="" fullWidth
            name="last_name"
            onChange={this.onChange}
            value={last_name} required />
          <TextField variant="outlined" margin="normal" id="user-email" label="Email" type="Email" fullWidth
            name="email"
            onChange={this.onChange}
            value={email} required />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="label-log-type">Permisos Módulos</InputLabel>
            <Select
              name="permissions"
              multiple
              labelId="label-log-type"
              value={permissions}
              onChange={this.onChange}
            >
              {permissions_list.map((permission) => (
                <MenuItem value={permission.id}>{permission.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="label-log-type">Tipo de Usuario</InputLabel>
            <Select
              name="user_type"
              labelId="label-log-type"
              value={user_type}
              onChange={this.onChange}
            >
              {user_type_list.map((user_type) => (
                <MenuItem value={user_type.value}>{user_type.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {logged_user.is_superuser &&
            <FormControl fullWidth variant="outlined">
              <FormControlLabel control={<Checkbox name="superuser" onClick={this.onChangeSuperuser} />} label="Administrador Aplicación" />
            </FormControl>
          }
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
              <Button type="submit" color="primary"><Trans>Crear Usuario</Trans></Button>
              <Button type="button" color="secondary" component={Link} to="/users"><Trans>Volver</Trans></Button>
            </ButtonGroup>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  permissions_list: state.users.permissions,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { registerUser, loadPermissions })(NewUser);