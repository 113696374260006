import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';
import Template2 from './templates/2';
import Template3 from './templates/3';
import Template4 from './templates/4';
import Template7 from './templates/7';
import Template5 from './templates/5';
import Template6 from './templates/6';


export default function InfoPlumbing() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/plumbing/info/1" component={Template1} />
            <Route path="/:idBuild/plato/plumbing/info/2" component={Template2} />
            <Route path="/:idBuild/plato/plumbing/info/3" component={Template3} />
            <Route path="/:idBuild/plato/plumbing/info/4" component={Template4} />
            <Route path="/:idBuild/plato/plumbing/info/5" component={Template5} />
            <Route path="/:idBuild/plato/plumbing/info/6" component={Template6} />
            <Route path="/:idBuild/plato/plumbing/info/7" component={Template7} />
        </Switch>
    )
}