import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { getFiles, getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class OthersList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Normativa',
        data: 'normative',
      },
      {
        title: 'Documentación\ndisponible',
        data: null, render: function (data, type, row) {
          let url = ""
          data.state = "no"
          data.detail = "No hay ningun archivo asociado";
          data.download = "disabled"
          if (data.file != null && data.file.file) {
            data.download = "disponible"
            data.state = "si"
            data.detail = "Texto si tiene archivo"
          }
          if (data.file != null) {
            url = `${data.file.file}`
            if (url.includes("http") == false){
              url = `${axios.defaults.baseURL}${data.file.file}`
            }
            return `<div class="tooltip-exta"><a href="${url}" target="_blank" class="MuiButtonBase-root MuiButton-root Mui-'${data.download}' MuiButton-outlinedPrimary">${data.state}<i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">${data.detail}</span></div>`
          }
          return '<div class="tooltip-exta"><a href="' + url + '" class="MuiButtonBase-root MuiButton-root Mui-' + data.download + ' MuiButton-outlinedPrimary">' + data.state + ' <i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">' + data.detail + '</span></div>'
        },
      },
      {
        title: 'Subir\ndocumentación',
        data: null,
        action: 'upload',
        f_action: ''
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          return '<a href="#info-modal' + data.id + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"><i class="fa fa-info-circle" aria-hidden="true"></i></a><a href="#"><div id="info-modal' + data.id + '" class="modal info-modal"><div class="MuiPaper-rounded MuiPaper-root modal-contenido"><h4 class="text-modal">' + data.description + '</h4><p class="text-modal">' + data.information + '</p></div></div></a>'
        },
      }
    ]
  }
  static propTypes = {
    document_types: PropTypes.array,
    getDocumentTypes: PropTypes.func,
    isLoading: PropTypes.bool
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Plan de Gestión Interna de Residuos Clínicos', description: 'Decreto 83/1999', state: 'no', detail: 'Falta de validación', download: 'disabled', info: '' },
      // { id: 2, name: 'Autorización Nº de Registro Productor de Residuos Clínicos y nº de registro Residuos Tóxicos y Peligros.', description: '', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El Plan de gestión de residuos es el documento que establece las condiciones y medios para llevar a cabo la gestión de residuos producidos en las diferentes actividades. Este documento es fundamental para que algunas empresas puedan iniciar su actividad, debido al tipo de residuos que generan. \n\n Este documento debe contener una estimación de los residuos que se van a generar a raíz de la actividad y un listado con todas las medidas de prevención. También se incluirán los planos de las instalaciones o los costes previstos para la gestión de residuos, entre otros aspectos.' },
      // { id: 3, name: ' Plan, Programa de Trazabilidad Servicio de Esterilización. Productos sanitarios estériles proveniente de la Central de esterilización y trazabilidad conforme Real Decreto 414/1996, así como su posterior modificación RD 2727/1998, necesidades y requisitos con la central.', description: 'RD 2727/1998', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Los servicios de esterilización de un hospital deben contar con lo amrcado en el Real Decreto 2727/1998, de 18 de diciembre, por el que se modifica el Real Decreto 414/1996, de 1 de marzo, por el que se regulan los productos sanitarios.' },
      // { id: 4, name: 'Plan de Autoprotección', description: 'RD 393/2007', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El Plan de Autoprotección aborda la identificación y evaluación de los riesgos, las acciones y medidas necesarias para la prevención y control de riesgos, así como las medidas de protección y otras actuaciones a adoptar en caso de emergencia. Lo regula el Real Decreto 393/2007, de 23 de marzo, por el que se aprueba la Norma Básica de Autoprotección de los centros, establecimientos y dependencias dedicados a actividades que puedan dar origen a situaciones de emergencia.' },
      // { id: 5, name: 'Planes de Emergencia específicos de Servicios Relacionados con Servicios Relacionados con Servicios Energía Ionizante.', description: 'RD 1836/1999', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Los servicios que generen radiación ionozante en el hosptial debe contar con el visto bueno del CSN y cumplir con el  Real Decreto 1836/1999, de 3 de diciembre, por el que se aprueba el Reglamento sobre instalaciones nucleares y radiactivas.' },
    ],
  };

  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 4
    this.props.getDocumentTypes(idBuild, module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading
      });
    }
  }

  render() {
    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }
    
    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(OthersList));

