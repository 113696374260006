import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Paper, Grid, Typography, Link } from '@material-ui/core/';
import PowerIcon from '@material-ui/icons/Power';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import ApartmentIcon from '@material-ui/icons/Apartment';
import HealingIcon from '@material-ui/icons/Healing';
import BathtubIcon from '@material-ui/icons/Bathtub';
import { useLocation, useParams } from "react-router-dom";

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '120px',
        borderRadius: '1rem',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
          }
      },
      root: {
          paddingBottom: theme.spacing(5),
      }
  }));

export default function NavItemAristotle() {
    const { idBuild } = useParams();
    const classes = useStyles();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/aristotle/");
    const link_1 = "/" + idBuild + "/aristotle/air"
    return (
            <div className={classes.root}>
                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
                    {/**<Grid item>
                        <Link href='/plato/electric'>
                            <Paper className={[splitLocation[1] === "electric" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <PowerIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Electricidad</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href='/plato/sanit'>
                            <Paper className={[splitLocation[1] === "sanit" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <HealingIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Saneamiento</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href='/plato/plumbing'>
                            <Paper className={[splitLocation[1] === "plumbing" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <BathtubIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Fontanería</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href='/plato/pci'>
                            <Paper className={[splitLocation[1] === "pci" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <WhatshotIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>PCI</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>**/}
                    <Grid item>
                        <Link href={link_1}>
                            <Paper className={[splitLocation[1] === "air" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <WbSunnyIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Climatización</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    {/**<Grid item>
                        <Link href='/'>
                            <Paper className={classes.paper} elevation={2}>
                                <ApartmentIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>BMS</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>**/}
                </Grid>
        </div>
    )
}