import axios from 'axios';
import { createMessage, returnErrors } from './messages';
import { tokenConfig } from './auth';

import { GET_USER, GET_USERS, ADD_USER, EDIT_USER, DELETE_USER, GET_PERMISSION } from './types';

import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

export const getUser = (id) => (dispatch, getState) => {
    axios.get(`/api/v1/exta/user/${id}/`, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_USER,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const loadUsers = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/user', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_USERS,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};

// REGISTER USER
export const registerUser = (newUser) => (dispatch, getState) => {
    axios
        .post('/api/v1/exta/user', newUser, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: ADD_USER,
                payload: res.data
            })
            toast.success(`Se ha creado el usuario: ${res.data.username} con éxito`)
        })
        .catch(err => {
            toast.error(err.response.data)
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

// UPDATE USER
export const putUser = (user) => (dispatch, getState) => {
    axios
        .put(`/api/v1/exta/user/${user.id}`, user, tokenConfig(getState))
        .then(res => {
            toast.success(`Usuario: ${user.username} editado con éxito`)
            dispatch({
                type: EDIT_USER,
                payload: res.data
            })
        }).catch(err => {
            toast.error(err.response.data)
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};

// DELETE USER
export const deleteUser = (id) => (dispatch, getState) => {
    axios
        .delete(`/api/v1/exta/user/${id}`, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: DELETE_USER,
                payload: res.data
            })
            toast.success(`Se ha eliminado el usuario: ${res.data.username} con éxito`)
        })
        .catch(err => {
            toast.error(err.response.data)
            dispatch(returnErrors(err.response.data, err.response.status));
        });
};

export const loadPermissions = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/permission', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: GET_PERMISSION,
            payload: res.data
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    });
};