import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>OPERACIÓN SISTEMAS DE AIRE</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>DIAGNÓSTICO EN OPERACIÓN DE OFICINAS</Trans>
            </Typography>
            <Container>
                <Typography variant="h5" gutterBottom>
                    <Trans>VALORES DE PARTIDA DE PLATAFORMA NECESARIOS PARA LA EVALUACIÓN</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Los datos necesarios para la evaluación del funcionamiento del recinto serán los siguientes:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Temperatura </li>
                            <li>Humedad relativa</li>
                        </ul>
                    </Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}