import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Metre from '../components/metre';
import Signature from '../components/signature';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }

        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>PUESTA EN MARCHA INSTALACIÓN DE FONTANERÍA-004</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>COMPROBACIÓN Y AJUSTE DE LOS SISTEMAS DE EXPANSIÓN Y SEGURIDAD</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Técnico" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Material de la Tuberías" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación del circuito en la instalación" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>VASOS DE EXPANSION Y VÁLVULAS DE SEGURIDAD</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La instalación dispone de manómetro de rango adecuado para realizar las medidas?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El sistema de llenado permite el llenado de la instalación a la presión inicial prescrita?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El vaso de expansión tiene un volumen y presión inicial adecuada?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La válvula de seguridad del circuito está tarada a la presión prescrita y conectada al desagüe?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha comprobado que se hayan instalado purgadores automáticos en los puntos más altos del circuito?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Otros elementos como calderas, depósitos y calderas tienen conectadas sus válvulas de seguridad?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="h5" gutterBottom>
                            <Trans>DATOS TOMADOS DE PLACAS DE CARACTERÍSTICAS</Trans>
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Volumen del vaso de expansión</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión nominal del vaso de expansión</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión de prueba del vaso de expansión</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión de tarado de la válvula de seguridad</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión de prueba del circuito hidráulico</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión nominal mínima de los elementos instalados</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="h5" gutterBottom>
                            <Trans>SISTEMA DE LLENADO POR PRESIÓN DE RED</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La instalación dispone de manómetro de rango adecuado para medir la presión de red?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha instalado válvula limitadora de presión en su caso?, indicar la presión de corte.</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se ha instalado desconector?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El sistema de llenado es automático y se ha instalado contador de medida?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="h5" gutterBottom>
                            <Trans>SISTEMA DE LLENADO CON BOMBA</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿El fluido térmico empleado es el especificado para evitar heladas? indicar fluido empleado</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿La bomba de llenado instalada permite llegar a la presión mínima o de llenado?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="h5" gutterBottom>
                            <Trans>SISTEMA DE VACIADO Y PURGA</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Las válvulas de vaciado instaladas permiten el vaciado total y parcial del circuito?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Se han instalado purgadores necesarios en puntos elevados que permiten la fuga de aire?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="body1" gutterBottom>
                            <Trans>¿Los desagües se han realizado de forma adecuada y permiten el vaciado de forma visible?</Trans>
                        </Typography>
                        <YesNo />
                        <Typography variant="h5" gutterBottom>
                            <Trans>MEDIDAS REALIZADAS EN EL LLENADO</Trans>
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión inicial en el vaso de expansión</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión mínima o de llenado bar</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión máxima (se medirá si es posible)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="caption" gutterBottom>
                            <Trans>Presión máxima de llenado (red o bomba de llenado)</Trans>
                        </Typography>
                        <Metre />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />

                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);