// import { types } from '@babel/core';
import axios from 'axios';

import Table from '../components/layout/Table';
import { createMessage, returnErrors } from './messages';

import { LOAD_ALERTS, READ_ALERT } from './types';
import { tokenConfig } from './auth';

import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';

/**Función que controla la petición GET a la URL de la API para recuperar la información de los logs. */
export const loadAlerts = () => (dispatch, getState) => {
    axios.get('/api/v1/exta/alert/', tokenConfig(getState))
    .then(res => {
        dispatch({
            type: LOAD_ALERTS,
            payload: res.data,
        });
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    }); 
};

export const readAlert = (id, validation) => (dispatch, getState) => {
    axios.put(`/api/v1/exta/alert/read/${id}`, validation, tokenConfig(getState))
    .then(res => {
        dispatch({
            type: READ_ALERT,
            payload: res.data,
        });
        toast.success('Alerta ocultada con éxito 👍')
    }).catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status));
    }); 
};