import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import Signature from '../components/signature';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        Object.keys(form.elements).forEach((el) => {
            if (parseInt(el) != el) {
                if (el.includes("check_") && inputs[el].length > 1) {
                    let check_values = this.props.json[el]
                    if (check_values[0] == true) {
                        inputs[el][0].click()
                    } else if (check_values[1] == true) {
                        inputs[el][1].click()
                    }
                } else {
                    inputs[el].value = this.props.json[el];
                }
            }
        });
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>OPERACIÓN SISTEMAS DE AIRE-006</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>DIAGNÓSTICO EN OPERACIÓN QUIRÓFANO</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Proyecto" variant="outlined" name="text_proyecto"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Contrastado por" variant="outlined" name="text_contrastado"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Aparatos de medida" variant="outlined" name="text_aparato"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="En fecha" variant="outlined" name="text_enfecha"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Sistema Nº" variant="outlined" name="text_sistema"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Servicio a" variant="outlined" name="text_servicio"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Plano ref. Nº" variant="outlined" name="text_planoref"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Planta" variant="outlined" name="text_planta"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Protocolo realizado por" variant="outlined" name="text_protocolo"/>
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="LOCAL" variant="outlined" name="text_local_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="ZONA" variant="outlined" name="text_zona_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="DESTINO" variant="outlined" name="text_destino_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal impul. PROY." variant="outlined" name="text_impulproy_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal impul. MED." variant="outlined" name="text_impulmed_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="R/H. MED." variant="outlined" name="text_rhmed_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal retorno. PROY." variant="outlined" name="text_retornoproy_1" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal retorno. MED." variant="outlined" name="text_retornomed_1" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal extrac. PROY." variant="outlined" name="text_extracproy_1" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal extrac. MED." variant="outlined" name="text_extracmed_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Sobrepresión (+)(-)" variant="outlined" name="text_sobrepresion_1"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Ele. terminal TIPO" variant="outlined" name="text_terminaltipo_1"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Ele. terminal PRUEBA PROT.Nº" variant="outlined" name="text_terminoprueba_1"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="OBSERVACIONES" variant="outlined" name="text_observaciones_1" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" name="text_resultado"/>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);