import React, { Fragment } from 'react'
import { Switch } from 'react-router-dom';
import PrivateRoute from '../common/PrivateRoute';
import Users from '../NonameAdmin/users/users';
import NewUser from '../NonameAdmin/users/newUser';
import EditUser from '../NonameAdmin/users/editUser';
import Logs from '../NonameAdmin/audits/audit';
import Control from '../control/control';
import Technics from '../socrates/technics/technics';
import Admin from '../socrates/docAdmin/admin';
import Alerts from '../alerts/alerts';
import Installations from '../socrates/installations/installations';
import Others from '../socrates/docOthers/others';
import Pci from '../plato/pci/pci';
import FormPci from '../plato/pci/form/form';
import InfoPci from '../plato/pci/info/info';
import Air from '../plato/air/air';
import FormAir from '../plato/air/form/form';
import InfoAir from '../plato/air/info/info';
import Electric from '../plato/electric/electric';
import FormElectric from '../plato/electric/form/form';
import InfoElectric from '../plato/electric/info/info';
import Sanit from '../plato/sanit/sanit';
import FormSanit from '../plato/sanit/form/form';
import InfoSanit from '../plato/sanit/info/info';
import Plumbing from '../plato/plumbing/plumbing';
import FormPlumbing from '../plato/plumbing/form/form';
import InfoPlumbing from '../plato/plumbing/info/info';
import AirA from '../aristotle/air/air';
import FormAirA from '../aristotle/air/form/form';
import InfoAirA from '../aristotle/air/info/info';
import Clients from '../../components/clients/clients';
import NewClient from '../../components/clients/newClient';
import EditClient  from '../../components/clients/editClient';
import Building from '../../components/buildings/buildings';
import NewBuilding from '../../components/buildings/newBuilding';
import EditBuilding from '../../components/buildings/editBuilding';
import Validation from '../validation/validation';
import validationForm from '../validation/validationForm';
import SelectBuilding from '../buildings/selectBuilding';
import AdminPanel from '../NonameAdmin/admin/adminpanel';


/**
 * Componente donde se renderizarán el resto de componentes.
 * 
 * @example ../../../../docs/Frontend/Layout/Section.md
 */
export default function Section() {
    return (
        <Fragment>
            <Switch>
                <PrivateRoute exact path="/newuser" component={NewUser} />
                <PrivateRoute exact path="/myuser" component={EditUser} />
                <PrivateRoute exact path="/edituser/:id" component={EditUser} />
                <PrivateRoute exact path="/logs" component={Logs} />
                <PrivateRoute exact path="/alerts" component={Alerts} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/:idBuild/control" component={Control} />
                <PrivateRoute exact path="/:idBuild/socrates/technics" component={Technics} />
                <PrivateRoute exact path="/:idBuild/socrates/docadmin" component={Admin} />
                <PrivateRoute exact path="/:idBuild/socrates/installations" component={Installations} />
                <PrivateRoute exact path="/:idBuild/socrates/others" component={Others} />
                <PrivateRoute exact path="/:idBuild/plato/pci" component={Pci} />
                <PrivateRoute exact path="/:idBuild/plato/pci/form/:id" component={FormPci} />
                <PrivateRoute exact path="/:idBuild/plato/pci/info/:id" component={InfoPci} />
                <PrivateRoute exact path="/:idBuild/plato/air" component={Air} />
                <PrivateRoute exact path="/:idBuild/plato/air/form/:id" component={FormAir} />
                <PrivateRoute exact path="/:idBuild/plato/air/info/:id" component={InfoAir} />
                <PrivateRoute exact path="/:idBuild/plato/electric" component={Electric} />
                <PrivateRoute exact path="/:idBuild/plato/electric/form/:id" component={FormElectric} />
                <PrivateRoute exact path="/:idBuild/plato/electric/info/:id" component={InfoElectric} />
                <PrivateRoute exact path="/:idBuild/plato/sanit" component={Sanit} />
                <PrivateRoute exact path="/:idBuild/plato/sanit/form/:id" component={FormSanit} />
                <PrivateRoute exact path="/:idBuild/plato/sanit/info/:id" component={InfoSanit} />
                <PrivateRoute exact path="/:idBuild/plato/plumbing" component={Plumbing} />
                <PrivateRoute exact path="/:idBuild/plato/plumbing/form/:id" component={FormPlumbing} />
                <PrivateRoute exact path="/:idBuild/plato/plumbing/info/:id" component={InfoPlumbing} />  
                <PrivateRoute exact path="/:idBuild/aristotle/air" component={AirA} />
                <PrivateRoute exact path="/:idBuild/aristotle/air/form/:id" component={FormAirA} />
                <PrivateRoute exact path="/:idBuild/aristotle/air/info/:id" component={InfoAirA} />
                <PrivateRoute exact path="/:idBuild/validation" component={Validation} />
                <PrivateRoute exact path="/:idBuild/validation/form" component={validationForm} />
                <PrivateRoute exact path="/buildings" component={SelectBuilding} />
                <PrivateRoute exact path="/adminpanel" component={AdminPanel} />
                <PrivateRoute exact path="/adminpanel/clients" component={Clients} />
                <PrivateRoute exact path="/adminpanel/newclient" component={NewClient} />
                <PrivateRoute exact path="/adminpanel/editclient/:id" component={EditClient} />
                <PrivateRoute exact path="/adminpanel/buildings" component={Building} />
                <PrivateRoute exact path="/adminpanel/newbuilding" component={NewBuilding} />
                <PrivateRoute exact path="/adminpanel/editbuilding/:id" component={EditBuilding} />
            </Switch>
        </Fragment>
    )
}