import React, { Component, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { AppBar, Toolbar, IconButton } from '@material-ui/core/';
import FullscreenSharpIcon from '@material-ui/icons/FullscreenSharp';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsPowerTwoToneIcon from '@material-ui/icons/SettingsPowerTwoTone';

const drawerWidth = 240;

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 68,
    minHeight: '0px',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: '0.8rem 5%',
  },
  logo: {
    width: '12rem',
    height: '3.2em',
    opacity: '0.5',
  },
  aLogin: {
    display: 'block',
    height: '4em',
    width: '100%',
  },
  root: {
    width: '100%',
  },
  right: {
    opacity: '0.5',
  }
}));

/**
 * Componente que contiene la información de la cabecera de la aplicación.
 *
 * @example ../../../../docs/Frontend/Layout/Header.md
 */
export default withTranslation()(function Header() {
  const classes = useStyles();
  var toggleFullScreen = () => {
  }
    return (
    <Fragment>
      <AppBar position="fixed" color="secondary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.root} edge="start">
            <a className={classes.aLogin} href='/'><div className={classes.logo}></div></a>
          </div>
          <IconButton color="inherit" onClick={() => history.go(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton color="inherit" href='/' id="cerrar">
            <SettingsPowerTwoToneIcon />
          </IconButton>
          <IconButton color="inherit" onClick={toggleFullScreen = () => {
              var fullscreen = false;
              var elem = document.documentElement;

              if(fullscreen == false){
                if(elem.requestFullscreen){
                    elem.requestFullscreen();
                    fullscreen = true;
                  } else if (elem.mozRequestFullScreen){
                    elem.mozRequestFullScreen();
                    fullscreen = true;
                  } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen();
                    fullscreen = true;
                  } else if (elem.msRequestFullScreen) {
                    elem.msRequestFullScreen();
                    fullscreen = true;
                  }
                }
                if(fullscreen == true){
                  if (document.exitFullscreen){
                    document.exitFullscreen();
                    fullscreen = false;
                  } else if (document.mozCancelFullscreen) {
                    document.mozCancelFullscreen();
                    fullscreen = false;
                  } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                    fullscreen = false;
                  } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                    fullscreen = false;
                  }
                }
              }}>
                <FullscreenSharpIcon />
            </IconButton>
        </Toolbar>
      </AppBar>
    </Fragment>
    );
})
