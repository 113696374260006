import React, { Fragment } from 'react';
import { BrowserRouter as Switch, Route } from "react-router-dom";
import Template1 from './templates/1';


export default function InfoAir() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/electric/info/1" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/2" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/3" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/4" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/5" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/6" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/7" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/8" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/9" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/10" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/11" component={Template1} />
            <Route path="/:idBuild/plato/electric/info/12" component={Template1} />
        </Switch>
    )
}