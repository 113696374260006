import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import YesNo from '../components/yes-no';
import Signature from '../components/signature';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        
        Object.keys(form.elements).forEach((el) => {
            if (parseInt(el) != el) {
                if (el.includes("check_") && inputs[el].length > 1) {
                    
                    let check_values = this.props.json[el]
                    if (check_values[0] == true) {
                        inputs[el][0].click()
                    } else if (check_values[1] == true) {
                        inputs[el][1].click()
                    }
                } else {
                    inputs[el].value = this.props.json[el];
                }
            }
        });
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>OPERACIÓN SISTEMAS DE AIRE-002</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>DIAGNÓSTICO EN OPERACIÓN DE GRUPOS DE FRÍO</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Datos Generales</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" name="text_empresa_instaladora" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" name="text_cliente"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Técnico" variant="outlined" name="text_tecnico"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación de los equipos en la instalación" variant="outlined" name="text_ident_equipos" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Identificación del espacio o zonas a las que da servicio" variant="outlined" name="text_ident_espacio"/>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>CARACTERÍSTICAS DE LOS DIFUSORES INSTALADOS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="MOTOR" variant="outlined" name="text_motor_1" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="FABRICANTE" variant="outlined" name="text_fabricante_1" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="MODELO" variant="outlined" name="text_modelo_1" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="CAUDAL (m3/h)" variant="outlined" name="text_caudal_1"/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="PRESIÓN (Pa)" variant="outlined" name="text_presion_1"/>
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PRUEBAS CIRCULACIÓN RED DE CONDUCTOS</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Se comprobará que las unidades se han instalado según documentación del proyecto</Trans>
                        </Typography>
                        <YesNo time_used="1"/>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Se comprobará que el nivel de ruido producido por los equipos es el adecuado</Trans>
                        </Typography>
                        <YesNo time_used="2"/>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Se comprobará que el equipo esté limpio y en adecuado estado</Trans>
                        </Typography>
                        <YesNo time_used="3"/>
                        <Typography variant="h5" gutterBottom>
                            <Trans>MEDIDAS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="LOCAL" variant="outlined" name="text_local_2" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="EQUIPO" variant="outlined" name="text_equipo_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Caudal nominal (m3/h)" variant="outlined" name="text_caudal_nominal_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Presión nominal (Pa)" variant="outlined" name="text_presion_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="CAUDAL (m3/h)" variant="outlined" name="text_caudal_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="PRESIÓN (Pa)" variant="outlined" name="text_presion_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="INTENS. (A)" variant="outlined" name="text_intens_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="TENSIÓN (V)" variant="outlined" name="text_tension_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="VEOLC. (m/s)" variant="outlined" name="text_veolc_2"/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Resultado de la actividad</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" name="text_resultfinal" />
                        <Typography variant="h5" gutterBottom>
                            <Trans>Conformidad</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);