import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '../../actions/messages';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, TextField, FormControlLabel, Checkbox, Grid } from '@material-ui/core/';
import { createNewBuilding } from '../../actions/building';


/**Componente que contiene el formulario para la creación de nuevos edificios. */
export class NewBuilding extends Component {

  state = {
    name: '',
    location: ''
  }

  static propTypes = {
    createNewBuilding: PropTypes.func.isRequired
  };

  onSubmit = e => {
    e.preventDefault();
    const { name, location } = this.state;
    if (name==null,location==null) {
      this.props.createMessage({ needInfo: "Casilla vacia" });
    }
    else {
      const newBuilding = {
        name,
        location
      };
      this.props.createNewBuilding(newBuilding);
      this.props.history.push('/adminpanel/buildings');
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {

    const { name, location } = this.state;
    return (
      <div className="root">
        <Typography variant="h3" gutterBottom>
          <Trans>Crear edificio</Trans>
        </Typography>
        <form className="root" 
          onSubmit={this.onSubmit}>
          <TextField variant="outlined" margin="normal" id="building-new-name" label="Nombre" type="text" fullWidth
            name="name"
            onChange={this.onChange}
            value={name} />
          <TextField variant="outlined" margin="normal" id="building-location" label="Localización" type="text" fullWidth 
            name="location"
            onChange={this.onChange}
            value={location} />
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
              <Button type="submit" color="primary"><Trans>Crear Edificio</Trans></Button>
              <Button type="button" color="secondary" component={Link} to="/adminpanel/buildings"><Trans>Volver</Trans></Button>
            </ButtonGroup>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { createNewBuilding, createMessage })(NewBuilding);