import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import { Paper, Grid, Fab, Typography, Container } from '@material-ui/core/';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bannerCounter } from '../../actions/files';
import { withRouter } from 'react-router-dom';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '100%',
  },
  containerTop: {
    maxWidth: '30rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  fab: {
    backgroundColor: theme.palette.primary.main,
    width: '56px',
    height: '56px',
    padding: '0',
    fontSize: '0.875rem',
    minWidth: 0,
    minHeight: '36px',
    fontFamily: "Roboto",
    fontWeight: '500',
    lineHeight: '1.75',
    borderRadius: '50%',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    margin: '0',
    display: 'inline-flex',
    border: '0',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    color: '#efefef',
  }
});

export class Top extends Component {

  constructor(props) {
    super(props);

    /**
     * Se recogen los documentos del edifico que se este utilizando
     * Con el ID del edificio y el path que contendrá o no el modulo que se este 
     * usando
     */
    const { idBuild } = this.props.match.params;
    const path = window.location.pathname;
    this.props.bannerCounter(idBuild, path);

    this.state = {
      modules: []
    }
  }

  static propTypes = {
    bannerCounter: PropTypes.func.isRequired
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        modules: this.props.modules
      })
    }
  }

  countByValidation(list, num_val) {
    let total = 0

    if (list.length == 0) {
      return total
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].validation == num_val) {
        total++
      }
    }
    return total
  }
  /**
   * Componente que contiene la información de la cabecera de la aplicación.
   *
   * @example ../../../../docs/Frontend/Layout/Header.md
   */
  render() {
    const { classes } = this.props;
    const { modules } = this.state;


    /**
     * He quitado la comprobación no entiendo la razón del tener
     * un controlador de modulos si es obligatorio tener siempre
     */
    let showTop = false
    if (modules){
      if (modules.length > 0){
        showTop = true
      }
    }

    const completed_num = this.countByValidation(this.props.documents, 1);
    const incomplete_num = this.countByValidation(this.props.documents, 2);
    const approved_num = this.countByValidation(this.props.documents, 3);
    const denied_num = this.countByValidation(this.props.documents, 4);
    const pending_num = this.countByValidation(this.props.documents, 0);

    return (
      <Fragment>
          <Container className={classes.containerTop}>
            <div className={classes.root}>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <div color="primary" className={classes.fab}>
                      <AssignmentTurnedInIcon />
                    </div>
                  </Paper>
                  <Typography align='center'><Trans>Completos</Trans></Typography>
                  <Typography align='center' variant='h5'>{completed_num}</Typography>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <div color="primary" className={classes.fab}>
                      <AssignmentLateIcon />
                    </div>
                  </Paper>
                  <Typography align='center'><Trans>Incompletos</Trans></Typography>
                  <Typography align='center' variant='h5'>{incomplete_num}</Typography>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <div color="primary" className={classes.fab}>
                      <CheckIcon />
                    </div>
                  </Paper>
                  <Typography align='center'><Trans>Aprobados</Trans></Typography>
                  <Typography align='center' variant='h5'>{approved_num}</Typography>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <div color="primary" className={classes.fab}>
                      <CloseIcon />
                    </div>
                  </Paper>
                  <Typography align='center'><Trans>Denegados</Trans></Typography>
                  <Typography align='center' variant='h5'>{denied_num}</Typography>
                </Grid>
                <Grid item>
                  <Paper className={classes.paper} elevation={2}>
                    <div color="primary" className={classes.fab}>
                      <HourglassEmptyIcon />
                    </div>
                  </Paper>
                  <Typography align='center'><Trans>Pendientes</Trans></Typography>
                  <Typography align='center' variant='h5'>{pending_num}</Typography>
                </Grid>
              </Grid>
            </div>
          </Container>
      </Fragment>
    );
  }

}

const mapStateToProps = state => ({
  documents: state.files.documents
});

export default compose(connect(mapStateToProps, { bannerCounter }), withStyles(useStyles))(withRouter(Top));