import React, { Fragment } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid } from '@material-ui/core/';

export default function YesNo() {    
    return (
        <Fragment>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <FormControlLabel
                    control={
                        <Checkbox name="yes" color="primary" />
                    }
                    label="Si"
                    />
                    <FormControlLabel
                    control={
                        <Checkbox name="no" color="primary" />
                    }
                    label="No"
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField fullWidth label="Observaciones" variant="outlined" />
                </Grid>
            </Grid>
        </Fragment>
    )
}