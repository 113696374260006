import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Table from '../layout/Table';
import { Typography, Button, ButtonGroup, Grid } from '@material-ui/core/';
import { loadClients, deleteClient, editClient } from '../../actions/clients';
import store from '../../store';


/**Componente para la gestión de clientes. */
export class ClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
    };


    /**
     * Se crean las columnas para la tabla que mostrará los clientes creados hasta el momento.
     */

    this.columns = [
      {
        title: 'Nombre',
        data: 'name'
      },
      {
        title: 'Usuario Asignado',
        data: 'user_assigned_read'
      },
      {
        title: 'Edificio Asignado',
        data: 'building_assigned_read'
      },
      {
        title: 'Módulo Asignado',
        data: 'modules_assigned_read',
      },
      {
        title: 'Editar Cliente',
        data: 'id',
        action: 'edit',
        f_action: editClient.bind(this),
      },
      {
        title: 'Borrar Cliente',
        data: 'id',
        action: 'delete',
        f_action: deleteClient.bind(this),
      },
    ]

    function deleteClient(e, rowData) {
      this.props.deleteClient(rowData.id);
    }

    function editClient(e, rowData) {
      window.location.href = `editclient/${rowData.id}`;
    }
  }

  /**Declaración de los PropTypes */
  static propTypes = {
    loadClients: PropTypes.func.isRequired
  }


  static defaultProps = {
    data: []
  }


  /**
   * Función que ejecuta una llamada 'GET' a la url correspondiente de la API para recuperar la información de los clientes
   * existentes en ese momento.
   */
  componentDidMount() {
    this.props.loadClients();
  }

  componentDidUpdate(prevProvs) {
    if (this.props !== prevProvs) {
      this.setState({
        clients: this.props.clients
      })
    }
  }

  render() {
    const { clients } = this.state
    return (
      <div className="root" >
        <Typography variant="h3" gutterBottom>
          <Trans>Gestión de clientes</Trans>
        </Typography>
        <Grid container direction="row" justify="flex-end" alignItems="flex-end">
          <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
            <Button type="button" color="primary" component={Link} to="newclient"><Trans>Nuevo cliente</Trans></Button>
          </ButtonGroup>
        </Grid>
        <Table
          title=""
          columns={this.columns}
          data={clients} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  clients: state.clients.clients,
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadClients, deleteClient, editClient })(ClientList);
