import {
    FILE_FAIL, FILE_SUCCESS, FILE_UPLOAD, FILE_UPDATE,
    JSON_SUCCESS,
    DOCUMENT_SUCCESS,
    DOCUMENTTYPE_SUCCESS, DOCUMENTTYPE_FAIL,
    COUNTER_SUCCESS
} from "../actions/types";
import { log } from '../components/Global';


const initialState = {
    files: [],
    document_types: [],
    json: [],
    documents: [],
    isLoading: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DOCUMENTTYPE_SUCCESS:
            return {
                ...state,
                document_types: action.payload,
                isLoading: false,
            }
        case FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                files: action.payload,
            }
        
        case FILE_UPLOAD:
            return {
                ...state,
                isLoading: false,
                // files: state.files.concat(action.payload.document)
                document_types: state.document_types.map(
                    document_type => {
                        if(document_type.id == action.payload.document.document_type){
                            return {
                                ...document_type, file: action.payload.document
                            }
                        }
                        return document_type
                    }
                )
            }
        case FILE_UPDATE:
            return {
                ...state,
                isLoading: false,
                document_types: state.document_types.map(
                    document_type => {
                        if(document_type.id == action.payload.document_type){
                            return {
                                ...document_type, file: action.payload
                            }
                        }
                        return document_type
                    }
                )
            }
        case JSON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                json: action.payload
            }
        case DOCUMENT_SUCCESS:
        case COUNTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                documents: action.payload
            }
        case FILE_FAIL:
        case DOCUMENTTYPE_FAIL:
            return {
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }

}