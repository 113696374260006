import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PRESURIZACION DE ESCALERAS</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PUESTA EN MARCHA PRESURIZACIÓN DE ESCALERAS Y VESTÍBULOS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>La puesta en marcha de los sistemas de presurización de escaleras y vestíbulos es preceptiva bajo la norma UNE-EN 12101-6 “Sistemas para el control de humo y de calor. Parte 6: Especificaciones para los sistemas de diferencial de presión. Equipos” que establece que deben realizarse sólo cuando la instalación esté terminada y cuando el sistema de presión diferencial, así como el de climatización, se hayan puerto en servicio y equilibrado correctamente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se subdivide en dos fases, una primera fase de puesta en marcha con puertas cerradas y una segunda fase de puesta en marcha con puertas abiertas.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Puesta en marcha con puertas cerradas</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La puesta en marcha con puertas cerradas implica la realización de los siguientes procedimientos:</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Asegurarse que todas las puertas de escaleras y vestíbulos de todas las plantas están cerradas.</li>
                            <li>Se activará el ventilador de presurización de escalera y hueco del ascensor (si este conecta con el vestíbulo), manteniéndose en ambos casos 50 Pa respecto a un punto 0 que se obtendrá del ambiente interior o de un punto exterior protegido del viento conducido mediante un tubo a la parte de baja de los presostatos diferenciales instalados.</li>
                            <li>Una vez estabilizados estos dos puntos, se activará el ventilador de presurización de los vestíbulos y regulará las rejillas de salida de aire en cada vestíbulo hasta conseguir que la presión respecto al punto cero o al riesgo sean 40/45 Pa en todos los vestíbulos. Asimismo, será necesario ajustar el caudal del ventilador de presurización de vestíbulos mediante el variador de frecuencia manualmente o sobre una compuerta de regulación en aspiración de ventilador o conducto de descarga para evitar enviar demasiado aire para sea posible ajuste de las compuertas de regulación de las rejillas de salida en los vestíbulos.</li>
                            <li>Se comprobará que los ventiladores de presurización de escalera y ascensor no se hayan desestabilizado y que, por tanto, la regulación se estabiliza y se mantienen las sobrepresiones de 50 Pa en escalera y hueco del ascensor.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Puesta en marcha con puertas abiertas</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La puesta en marcha con puertas abiertas implica la realización de los siguientes procedimientos:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Asegurarse que todas las puertas escalera-vestíbulo-riesgo de la planta están abiertas.</li>
                            <li>Se ajustará manualmente el variador del ventilador de presurización de la escalera, mientras se mantiene constante la presión de conducto de salida de los ventiladores de los vestíbulos y hueco del ascensor, hasta conseguir en las puertas la velocidad prevista (0,7 m/s), anotando el valor de lectura del presostato en el conducto de impulsión a fin de establecer su punto de consigna. En el caso de que la instalación contemple la abertura simultánea de más de 1 puerta, el presostato acusará una disminución de la presión, ajustándose de nuevo mediante el variador la velocidad de los ventiladores, limitándose esta con la medición del consumo máximo de los motores de los ventiladores.</li>
                            <li>Se comprobará la acción inversa de cierre de puertas, tanto sobre el ventilador de presurización de la escalera como los de vestíbulos y hueco del ascensor, verificando que se vuelven a obtener los valores de puertas cerradas y que no se producen sobrepresiones superiores a las que indica la normativa (60 Pa) para ello, se garantizará una velocidad de respuesta de los bucles de control muy rápida (2 ó 3 segundos a comprobar).</li>
                            <li>Se comprobará la instalación de compuertas de sobrepresión con contrapesos calibrados entre la escalera y el exterior a fin de asegurar que no se producen sobrepresiones superiores a 60 Pa (en el caso de haber ejecutado esta recomendación).</li>
                        </ol>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Una vez que la instalación consigue los valores previstos (0,7 m/s en puertas abiertas y máximas sobrepresiones de 60 Pa) y que trabaja de manera estabilizada se procederá a la toma de datos según la hoja adjunta prevista.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}