import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones PCI</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>RED DE HIDRANTES EXTERIORES</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Todas las instalaciones nuevas de hidrantes de columna o enterrados deberán ser probadas y certificadas antes de la puesta en servicio e inicio de la actividad conforme al Reglamento de Protección contra incendios RD 513/2017. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas serán dirigidas obligatoriamente por un representante cualificado del instalador autorizado, anotando los resultados en los protocolos de pruebas aplicables. Los procedimientos y los medios necesarios para las pruebas serán presentados por el instalador antes del inicio de las mismas para aprobación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las anomalías detectadas serán corregidas de forma inmediata y en cualquier caso antes del inicio de la actividad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Certificados de materiales:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El instalador autorizado presentará certificados de los materiales que forman parte de la instalación conforme a la legislación vigente:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Certificado de conformidad a normas UNE 23.405 y UNE 23.406 de todos los hidrantes de columna seca y/o húmedos emitidos por Organismo de Control reconocido.</li>
                        <li>Certificado de conformidad a normas UNE 23.407 de los hidrantes de arqueta enterrados.</li>
                        <li>Certificado de conformidad a normas UNE 23.400 y UNE 23.091 de los racores y mangueras utilizados en la instalación.</li>
                        <li>Certificado de materiales del fabricante para la tubería y accesorios.</li>
                        <li>Certificados del fabricante de otros materiales (válvulas, interruptores de flujo, etc.)</li>
                        <li>Certificado de homologación de los soldadores y ensayos de soldaduras si son de aplicación.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba hidráulica:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La totalidad de la instalación antes de tapar la tubería se someterá a una prueba de estanqueidad y resistencia mecánica, a una presión estática de 1.500 kPa (15 bar), manteniendo dicha presión durante dos horas como mínimo, no debiendo aparecer fugas en ningún punto de la instalación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión de prueba será medida en el punto más bajo de la instalación o sector probado, eliminando el aire totalmente en los puntos altos.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Si la red de tubería es de hierro fundido, PVC u otro material enchufable comprobar antes de que está correctamente instalada según las instrucciones del fabricante y con dados de hormigón en todos los codos, derivaciones y extremos finales para evitar desplazamientos de la misma.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se comprobará que todos los hidrantes están cerrados y las válvulas de seccionamiento de la red abiertas y se taponarán y asegurarán los extremos de tubería inacabados.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Verificación de los equipos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Con la instalación llena de agua a la presión de servicio se verificará cada uno de los puntos siguientes:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                      <ul>
                        <li>Se comprobará el estado, accesibilidad y señalización de todos los hidrantes instalados. Se comprobará que son del modelo previsto y están ubicados en la posición conforme al proyecto. Se verificará que las distancias son correctas.</li>
                        <li>Se verificará que las uniones no presentan ningún tipo de fugas, los racores están instalados con sus tapas y son de los modelos normalizados. Se desmontarán los tapones para comprobar juntas y que se desmontan con facilidad.</li>
                        <li>Las arquetas de los modelos enterrados estarán perfectamente montadas, libres de restos de obra y secas. Se comprobará el dispositivo de cierre de la tapa y se engrasará convenientemente.</li>
                        <li>Se comprobará cada una de las casetas auxiliares y se verificará que abren normalmente y estén libres de óxidos. Se revisará que contienen el material conforme a lo especificado y que los racores y mangueras corresponden a modelos </li>normalizados.
                        <li>Se abrirá y cerrará cada una de las válvulas de sectorización y drenaje que dispone el sistema. Los husillos en las de tipo compuerta estarán engrasados. Se comprobará que las arquetas donde se alojan son operativas y están libres </li>de agua y restos de obra. 
                        <li>Se comprobará que los dispositivos de alarma tales como interruptores de flujo, presostatos, etc. transmiten correctamente a la central de control.</li>
                      </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de funcionamiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El sistema de abastecimiento previsto será probado para verificar el diseño abriendo las bocas de hidrantes hidráulicamente más desfavorables que determine el proyecto. Se emplearán las mangueras y lanzas de las casetas auxiliares y se anotarán las presiones dinámicas obtenidas. Cada una de las bocas de 70 mm en los hidrantes de columna deberá suministrar un caudal de <strong>8,4 l/s (500 l/min) a 700 kPa. (7 bar)</strong></Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se tomarán precauciones para el arranque automático y parada manual de las bombas contra incendios en el caso de estar conectados los hidrantes.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba del drenaje principal:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se abrirá la válvula de drenaje principal de la instalación, se anotarán las presiones estática y dinámica en el manómetro más próximo y se verificará que el drenaje está conducido y la descarga de agua es correcta.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}