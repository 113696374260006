import { ADD_CLIENT, GET_CLIENT, LOAD_CLIENTS, DELETE_CLIENT, EDIT_CLIENT } from '../actions/types';

const initalState = {
    clients: [],
    client: {}
};

export default function (state = initalState, action) {
    switch (action.type) {
        case LOAD_CLIENTS:
            return {
                ...state,
                clients: action.payload
            };
        case ADD_CLIENT:
            return {
                ...state,
                clients: [...state.clients, action.payload]
            };
        case GET_CLIENT:
        case EDIT_CLIENT:
            return {
                ...state,
                client: action.payload
            };
        case DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients.filter(client => client.id !== action.payload.id)
            };
        case EDIT_CLIENT:
            return {
                ...state,
                clients: [action.payload]
            };
        default:
            return state;
    }
}