import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';


export default function FormSanit() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/sanit/form/1" component={(prop) => <Template1 document={prop.location.state} />} />
        </Switch>
    )
}