import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBAS CIRCULACIÓN EN CIRCUITO HIDRÁULICO</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de circulación en circuitos hidráulicos son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas de circulación de los circuitos hidráulicos, incluyendo estas la puesta en servicio de los grupos de bombeo y el ajuste y equilibrado del circuito hidráulico.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de circulación en circuitos hidráulicos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La IT 1.2.4.2.7 “Redes de tuberías” y la IT 2.3 “Ajuste y equilibrado” establecen el empleo de válvulas de equilibrado para conseguir el equilibrado hidráulico de los circuitos de tuberías, siendo estas o las propias unidades terminales equilibrados al caudal de diseño. Este proceso se realizará siguiendo el siguiente procedimiento:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba de circulación se realizará siguiendo el siguiente procedimiento:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Se comprobará que el grupo de bombeo instalado se corresponden con las especificadas en el Proyecto o Memoria Técnica. </li>
                            <li>Se anotarán las características del grupo de bombeo directamente de la placa de características (incluyendo el diámetro de rodete instalado). </li>
                            <li>Se comprobará que la ubicación permite el mantenimiento del grupo de bombeo y que el montaje se ha realizado según especificaciones del fabricante. Asimismo, en el caso de grupos de bombeo instaladas en el exterior se comprobará que el fabricante permite la ubicación a la intemperie, siendo recomendable en cualquier caso la protección del grupo de bombeo contra la radiación solar directa y la lluvia.</li>
                            <li>Se comprobará que el grupo de bombeo está soportado con elementos que impidan la transmisión de vibraciones a los cerramientos del edificio, conectándose esta a su vez al circuito hidráulico empleando elementos antivibratorios.</li>
                            <li>Se comprobará la instalación de válvulas de corte que permitan desmontar el grupo de bombeo sin necesidad de vaciar el circuito hidráulico. </li>
                            <li>Se comprobará que la válvula antirretorno se ha instalado en la impulsión del grupo de bombeo y entre las válvulas de corte para permitir su revisión o sustitución sin desmontar el circuito. Asimismo, en tuberías de diámetros superiores a 80 mm, se comprobará que el cierre de las válvulas antirretorno no sea brusco para evitar golpes de aire peligrosos.</li>
                            <li>Se realizarán tomas para la medida de presión en la aspiración e impulsión del grupo de bombeo.</li>
                            <li>Se comprobará que las conexiones eléctricas a la máquina se han realizado de forma correcta y que los cables se encuentran bien sujetos. Asimismo, se comprobará que la caja de conexiones está correctamente cerrada para mantenimiento del nivel de estanquidad.</li>
                            <li>Se comprobará la tensión de suministro y las protecciones eléctricas son las adecuadas para el equipo instalado.</li>
                            <li>Se comprobará, antes del arranque del grupo de bombeo, que el circuito se encuentra bien purgado y que el filtro esté limpio, pudiendo ser necesario una segunda limpieza del mismo (en los primeros minutos de funcionamiento el agua puede arrastrar suciedad).</li>
                            <li>Se comprobará que el ruido de funcionamiento sea adecuado, sin ruido de cavitación o burbujas de aire.</li>
                            <li>Se comprobará que el sentido de giro del grupo de bombeo es el correcto.</li>
                            <li>Se ajustará el caudal al especificado en Proyecto o Memoria Técnica. En instalación solar, se suele considerar adecuado un caudal de 50 a 60 l/h.</li>
                            <li>Se comprobará que el sistema de control del grupo funciona correctamente.</li>
                            <li>Se ajustarán las válvulas de equilibrado a fin de garantizar que la unidad terminal reciba su caudal nominal, con una desviación máxima que no supere el 10% del caudal de diseño.</li>
                        </ul>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}