import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, FormControlLabel, Checkbox } from '@material-ui/core/';
import Signature from '../components/signature';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements
        var inputsYes = form.elements.yes
        var inputsNo = form.elements.no

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

        for (let i = 0; i < inputsYes.length; i++) {
            if (this.props.json.yes[i] == true) {
                inputsYes[i].click()
            }
        }
        for (let i = 0; i < inputsNo.length; i++) {
            if (this.props.json.no[i] == true) {
                inputsNo[i].click()
            }
        }
    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>Puesta en marcha instalaciones PCI-007</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>Grupos de bombeo</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>DATOS GENERALES</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cliente" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Técnico" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>GRUPOS DE BOMBEO</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>DATOS BOMBA</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Jockey</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Modelo" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="nº Fabri." variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Curva" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Modelo" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="nº Fabri." variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Curva" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Modelo" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="nº Fabri." variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="Curva" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>DATOS MOTOR</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Jockey</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="R.P.M" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pot. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Ten. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Inten. placa" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Conexión" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="R.P.M" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pot. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Ten. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Inten. placa" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Conexión" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Marca" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="R.P.M" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pot. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Ten. (CV)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Inten. placa" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Conexión" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>PRUEBA DE FUNCIONAMIENTO</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>VÁLVULA CERRADA</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Jockey</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>PUNTO NORMAL</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Jockey</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>PUNTO 140%</Trans>
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Qo (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Po (lpm)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Pabs (lpm)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Jockey</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Cojinete (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Prensa (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Consumo In (A)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Principal</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Cojinete (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Prensa (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Consumo In (A)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="body1" gutterBottom>
                            <Trans>Reserva</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Cojinete (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Prensa (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField fullWidth label="Tª Aceite (ºc)" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Tª Agua refrig." variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Consumo In (A)" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>VERIFICACIÓN EQUIPOS</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>DEPÓSITO AGUA LLENO</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>NIVELES CONECTADOS</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>VÁLV.LLENADO ABIERTA</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>VÁLV.ASPIRACIÓN ABIERTA</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>CIR. REFRIG. ABIERTO</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>COMBUSTIBLE DIESEL</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>CAUDALÍMETRO</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>TENSIÓN CORRECTA</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>DEP. HIDRONEUMÁTICO</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                                <TextField label="Tarado" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>VÁLVULA SEGURIDAD</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                                <TextField label="Tarado" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>VÁLVULA CAUDAL MÍNIMO</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                                <TextField label="Tarado" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body1" gutterBottom>
                                    <Trans>MANÓMETROS</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel control={<Checkbox name="yes" color="primary" />} label="Si" />
                                <FormControlLabel control={<Checkbox name="no" color="primary" />} label="No" />
                                <TextField label="DN" variant="outlined" />
                                <TextField label="Tarado" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Typography variant="h5" gutterBottom>
                            <Trans>RESULTADO DE LA ACTIVIDAD</Trans>
                        </Typography>
                        <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined" />
                        <Typography variant="h5" gutterBottom>
                            <Trans>CONFORMIDAD</Trans>
                        </Typography>
                        <Signature />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);