import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones de fontanería</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBA DE LIBRE DILATACIÓN</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado de la instalación son preceptivas bajo el CTE DB HS 4 del Código Técnico de la Edificación que establece las pruebas y ensayos a realizar en la instalación terminada.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas de libre dilatación.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba de libre dilatación</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba de libre dilatación se realizará siguiendo el siguiente procedimiento:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                    <ul>
                        <li>Se llevará la instalación hasta la temperatura de tarado de los elementos de seguridad, habiendo anulado previamente la actuación de los aparatos de regulación automática (en el caso de instalaciones con captadores solares se llevará a la temperatura de estancamiento). </li>
                        <li>Se mantendrá esta temperatura durante una hora al menos para, a continuación, parar normalmente la instalación y dejarla enfriar.</li>
                        <li>Se comprobará visualmente durante el enfriamiento de la instalación y al finalizar el mismo que no hayan tenido lugar deformaciones apreciables en ningún elemento o tramo de tubería y que el sistema de expansión haya funcionado correctamente.</li>
                    </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba se considera satisfactoria si no se presenta ninguna fuga o deformación después de un mes de funcionamiento de la instalación.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}