import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper, Grid, Typography, Container, AccordionDetails, AccordionSummary, Accordion, Link } from '@material-ui/core/';
import PowerIcon from '@material-ui/icons/Power';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import BathtubIcon from '@material-ui/icons/Bathtub';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
      },
      heading: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: '1.5rem',
        textAlign: 'center',
        opacity: '0.5',
      },
      accordionNav: {
          padding: '0.5rem',
      },
      paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        width: '120px',
        color: theme.palette.text.secondary,
        borderRadius: '1rem',
        "&:hover": {
            backgroundColor: '#988575'
          }
      },
      noPadding: {
        padding: '8px 0px 0px',
      }
  }));

export default function NavBernini() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Accordion className={classes.accordionNav}>
            <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}><Trans>Bernini</Trans></Typography>
            </AccordionSummary>
            {/* <AccordionDetails className={classes.noPadding}>
            <Container>
                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Grid item>
                        <Link to='/'>
                            <Paper className={classes.paper} elevation={2}>
                                <PowerIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Electricidad</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/'>
                            <Paper className={classes.paper} elevation={2}>
                                <BathtubIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Fontanería</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/'>
                            <Paper className={classes.paper} elevation={2}>
                                <WhatshotIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>PCI</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link to='/'>
                            <Paper className={classes.paper} elevation={2}>
                                <WbSunnyIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Climatización</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
            </AccordionDetails> */}
            </Accordion>
        </div>
    )
}