import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';
import image1 from './images/ejemplo-para-motor-asincrono.jpg';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>OPERACIÓN SISTEMAS DE AIRE</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>DIAGNÓSTICO EN OPERACIÓN DE GRUPOS DE FRÍO</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Uno de los valores predictivos de los posibles problemas de sobreconsumo por envejecimiento, falta de mantenimiento o fin de la vida útil de los ventiladores, tanto de unidades terminales como de unidades de tratamiento de aire es el amperaje (intensidad), rendimiento y horas de operación del mismo.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la evaluación a priori del funcionamiento de estos equipos en base a los valores nominales de su puesta en marcha y de los valores aportados por el fabricante sobre su vida útil. A efectos de definir la vida útil del equipo se definen los siguientes parámetros (sacados de la norma UNE EN 15459 Eficiencia energética de los edificios. Procedimiento de evaluación económica de los sistemas energéticos de los edificios):</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Vida útil: duración esperada de la vida del componente expresada en años. Con carácter complementario se podrá evaluar:
                                <ul>
                                    <li>Uptime: Tiempo durante el que un equipo está operativo en % respecto al total</li>
                                    <li>Downtime: Tiempo durante el que un equipo no está operativo en % respecto al total</li>
                                </ul>
                            </li>
                            <li>Año inicial: fecha base para cálculos y estimaciones.</li>
                            <li>Periodo de cálculo: periodo de tiempo considerado para el cálculo</li>
                        </ul>
                    </Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>Para definir su rendimiento y eficacia en operación se empleará el valor definido en el reglamento de Instalaciones térmicas RD 1027/2007 y modificación bajo RD 178/2021:</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>SFP (Potencia específica de los ventiladores) expresado como potencia eléctrica en W dividido por el caudal expresado en m3/s. (W/(m3/s).</li>
                            <li>Intensidad (en Amperios)</li>
                            <li>Si se disponen de datos de fabricante:
                                <ul>
                                    <li>Rendimiento en base al porcentaje de carga del equipo</li>
                                    <li>Horas de funcionamiento aseguradas</li>
                                </ul>
                            </li>
                        </ul>
                    </Trans>
                </Typography>  
                <Typography variant="h5" gutterBottom>
                    <Trans>VALORES DE PARTIDA DE PLATAFORMA PLATÓN O NECESARIOS PARA LA EVALUACIÓN</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>Los datos necesarios para la evaluación de la obsolescencia del equipo serán los siguientes:</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>De la puesta en marcha del equipo (sacados de la plataforma PLATÓN) se requieren los siguientes datos para la comparación del funcionamiento:
                                <ul>
                                    <li>Intensidad (consumo en Amperios)</li>
                                    <li>Voltaje (tensión en Voltios)</li>
                                    <li>Caudal (medido en m3/h)</li>
                                </ul>
                            </li>
                        </ul>
                    </Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>Estos datos se fijaban según la siguiente matriz de obtención:</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Del sistema BMS se evaluarán:
                                <ul>
                                    <li>Horas de funcionamiento</li>
                                    <li>Intensidad (consumo en Amperios)</li>
                                    <li>Caudal (medido en m3/s)</li>
                                </ul>
                            </li>
                        </ul>
                    </Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>Los criterios de comparación serán los siguientes:</Trans>
                </Typography>  
                <table>
                    <thead>
                        <th>PARAMETRO</th>
                        <th>CRITERIO DE COMPARACIÓN</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Vida útil</td>
                            <td>15 años según Anexo A de la norma UNE  EN 15459<br/>25.000 horas para motores AC<br/>35.000 horas para motores EC</td>
                        </tr>
                        <tr>
                            <td>Intensidad consumida</td>
                            <td>&gt; 10 % del Inominal (National Eléctrical Code (NEC))</td>
                        </tr>
                        <tr>
                            <td>SPF a partir de:<br/>Potencia (W)<br/>Caudal medido (m3/s)</td>
                            <td>Variación del 10% respecto al nominal del equipo. <br/>Norma de referencia UNE EN 16798:3 y RD 178/2021</td>
                        </tr>
                    </tbody>
                </table>
                <Typography variant="body1" gutterBottom>
                    <Trans>Si de forma complementaria se dispone de la curva de funcionamiento del ventilador se pueden establecer verificaciones según nivel de carga y sus derivaciones del 10% según NEC (ejemplo para motor de 1,5 kW asíncrono):<br/>
                    <img src={image1} alt='ejemplo para motor de 1,5 kW asíncrono' />
                    </Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}