import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';


export default function InfoSanit() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/sanit/info/1" component={Template1} />
        </Switch>
    )
}