
import React, { Component, Fragment } from 'react'
import { withTranslation, Trans, withNamespaces } from 'react-i18next';
import i18n from '../../i18n';
import hoistStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, loadUser } from '../../actions/auth';
import { loadAlerts } from '../../actions/alert'
import { IconButton, Menu, MenuItem, Badge, Grid, BottomNavigationAction } from '@material-ui/core/';
import TranslateSharpIcon from '@material-ui/icons/TranslateSharp';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

/**
 * Función que genera la lista de idiomas disponibles para traducir la aplicación. 
 */
function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <TranslateSharpIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => i18n.changeLanguage("es")}><Trans>Spanish</Trans></MenuItem>
        <MenuItem onClick={() => i18n.changeLanguage("en")}><Trans>English</Trans></MenuItem>
      </Menu>
    </div>
  );
}


/**
 * Componente de menú en la cabecera donde se muestran los botones de traducción, atrás, pantalla completa y cierre de sesión.
 * 
 * @example ../../../../docs/Frontend/Layout/NavRight.md
 */
export class NavRight extends Component {

  componentDidMount() {
    this.props.loadAlerts()
    this.props.loadUser()
  }

  render() {
    /**Declaración de los PropTypes por defecto. */
    const propTypes = {
      auth: PropTypes.object.isRequired,
      logout: PropTypes.func.isRequired
    };
    /**Función para el cambio de idioma de la aplicación. */
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };

    return (
      <Fragment>
        <Grid container direction='row' justifyContent='center' spacing={2} style={{ flexWrap: 'nowrap' }}>
          <Grid item>
            <BottomNavigationAction href='/buildings' label="Home" icon={<HomeIcon />} showLabel={true} />
          </Grid>
          <Grid item>
            <BottomNavigationAction href='/adminpanel' label="Admin" icon={<PeopleOutlineIcon />} showLabel={true} />
          </Grid>
          <Grid item>
            <BottomNavigationAction href='/alerts' label="Alertas" icon={<Badge badgeContent={this.props.alerts.length} color="error"><NotificationsNoneIcon /></Badge>} showLabel={true} />
          </Grid>
          <Grid item>
            <BottomNavigationAction href='/logs' label="Logs" icon={<AssignmentLateIcon />} showLabel={true} />
          </Grid>
          {/*<SimpleMenu />*/}
        </Grid>
      </Fragment >
    )
  }

}

const mapStateToProps = state => ({
  auth: state.auth,
  alerts: state.alerts.alerts
});

export default hoistStatics(withTranslation()(connect(mapStateToProps, { logout, loadAlerts, loadUser })(NavRight)), NavRight);
