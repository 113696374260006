import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PRUEBAS DE ESTANQUIDAD</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>COMPROBACIONES PREVIAS AL LLENADO DEL CIRCUITO HIDRÁULICO</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad son preceptivas bajo el Documento Básico DB HS 5 sobre evacuación de aguas del Código Técnico de la Edificación y la norma UNE-EN 1610:2016 “Construcción y ensayos de desagües y redes de alcantarillado”.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad se clasifican en: </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de estanquidad parcial</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Se realizarán pruebas de estanquidad parcial descargando cada aparato aislado o simultáneamente, verificando los tiempos de desagüe, los fenómenos de sifonado que se produzcan en el propio aparato o en los demás conectados a la red, ruidos en desagües y tuberías y comprobación de cierres hidráulicos.</li>
                            <li>No se admitirá que quede en el sifón de un aparato una altura de cierre hidráulico inferior a 25 mm. </li>
                            <li>Las pruebas de vaciado se realizarán abriendo los grifos de los aparatos, con los caudales mínimos considerados para cada uno de ellos y con la válvula de desagüe asimismo abierta; no se acumulará agua en el aparato en el tiempo mínimo de 1 minuto. </li>
                            <li>En la red horizontal se probará cada tramo de tubería, para garantizar su estanquidad introduciendo agua a presión (entre 0,3 y 0,6 bar) durante 10 minutos. </li>
                            <li>Las arquetas y pozos de registro se someterán a idénticas pruebas llenándolos previamente de agua y observando si se advierte o no un descenso de nivel. </li>
                            <li>Se controlarán al 100 % las uniones, entronques y/o derivaciones.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de estanquidad total</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad pueden realizarse bien de una sola vez o por separado, por ejemplo, la prueba de entronques, registros y cámaras de inspección con agua y la de tuberías con aire. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad se clasifican en función del método empleado en: </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba estanquidad con aire (Método L)</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad con aire se realizarán según las siguientes prescripciones (DB HS 5):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>La presión a la que se someterá la red será entre 0,5 y 1 bar como máximo.</li>
                            <li>Se considerará satisfactoria cuando la presión se mantenga constante durante 3 minutos.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La presión y el tiempo de prueba en tuberías se verificará para diámetros no convencionales de acuerdo a la norma UNE EN 1610 que establece ambos parámetros para tuberías (excluyendo registros y cámaras de inspección) en función del tamaño de la misma y el método de prueba fijado por el responsable (tabla 3 norma UNE EN 1610).</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>De acuerdo a la norma UNE EN 1610, se deberá utilizar conexiones herméticas apropiadas para evitar errores producidos por el equipo de prueba y se requiere de especial cuidado durante las pruebas de grandes diámetros por razones de seguridad. </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba se desarrolla siguiendo los siguientes pasos (UNE EN 1610):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Obturar el tramo de tubería a inspeccionar e introducir aire en el tramo de prueba. </li>
                            <li>Iniciar el proceso con una presión inicial un 10% superior a la requerida por el ensayo y se mantendrá durante 5 minutos aproximadamente. </li>
                            <li>Ajustar la presión a la de la prueba. </li>
                            <li>Comprobar que la pérdida de presión esté dentro de los límites aceptables. Si la caída de presión, medida después del tiempo de prueba, es menor que el diferencial dado (tabla 3 norma UNE EN 1610), la prueba es satisfactoria.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En la prueba de estanquidad con aire el número de correcciones y re-ensayos siguientes a un fallo no está restringido. En el caso de un fallo aislado o continuo, se permite el recurso a la prueba de agua y el resultado de la misma por sí sola deberá ser decisivo.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba estanquidad con agua (Método W):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad con agua se realizarán según las siguientes prescripciones (DB HS 5):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>La presión a la que debe estar sometida cualquier parte de la red no debe ser inferior a 0,3 bar, ni superar el máximo de 1 bar. </li>
                            <li>Si el sistema tuviese una altura equivalente más alta de 1 bar, se efectuarán las pruebas por fases, subdividiendo la red en partes en sentido vertical. </li>
                            <li>Si se prueba la red por partes, se hará con presiones entre 0,3 y 0,6 bar, suficientes para detectar fugas. </li>
                            <li>Si la red de ventilación está realizada en el momento de la prueba, se le someterá al mismo régimen que al resto de la red de evacuación. </li>
                            <li>La prueba se dará por terminada solamente cuando ninguna de las uniones acuse pérdida de agua.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba se desarrollará siguiendo los siguientes pasos (UNE EN 1610):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Taponar los terminales de las tuberías de evacuación, excepto los de cubierta, llenar la red con agua hasta rebosar.</li>
                            <li>Aplicar la presión de prueba requerida durante 30 minutos. Si fuese necesario un acondicionamiento previo se mantendrá la presión durante un tiempo, generalmente 1 hora, pudiendo ser este más largo para condiciones climáticas secas en el paso de tubos de hormigón.</li>
                            <li>Comprobar que la presión se ha mantenido dentro de un límite 0,01 bar alrededor de la presión de prueba.</li>
                            <li>Medir la cantidad total de agua añadida durante la prueba para conseguir esta condición y registrar esta con la carga hidráulica a la presión de prueba. Si la cantidad de agua añadida no supera los siguientes límites, la prueba es satisfactoria.
                                <ul>
                                    <li>0,15 l/m2 durante 30 minutos para tuberías</li>
                                    <li>0,20 l/m2 durante 30 minutos para tuberías incluyendo registros</li>
                                    <li>0,40 l/m2 durante 30 minutos para registros y pozos de inspección</li>
                                </ul>
                            </li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Prueba con humo:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las pruebas de estanquidad con humo se realizarán según las siguientes prescripciones (DB HS 5):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Debe utilizarse un producto que produzca un humo espeso y que, además, tenga un fuerte olor. </li>
                            <li>La introducción del producto se hará por medio de máquinas o bombas y se efectuará en la parte baja del sistema, desde distintos puntos si es necesario, para inundar completamente el sistema, después de haber llenado con agua todos los cierres hidráulicos. </li>
                            <li>El sistema debe resistir durante su funcionamiento fluctuaciones de ± 250 Pa, para las cuales ha sido diseñado, sin pérdida de estanquidad en los cierres hidráulicos. </li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba se desarrollará siguiendo los siguientes pasos (DB HS 5):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Llenar la red con humo espeso y de fuerte olor.</li>
                            <li>Taponar los terminales de cubierta del sistema cuando el humo comience a aparecer a fin de mantener una presión de gases de 250 Pa.</li>
                            <li>Si no se detecta presencia de humo y olores en el interior del edificio, la prueba es satisfactoria.</li>
                        </ol>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Resumen:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las duraciones y presiones de las pruebas de estanquidad serán los siguientes en base a la norma (exceptuando diámetros no convencionales):</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Prueba en aire: 3 min a 1 bar.</li>
                            <li>Prueba en agua (parcial): 10 min a 0,5 bar.</li>
                            <li>Prueba en agua (totalidad del sistema): 30 min a 1 bar.</li>
                            <li>Prueba con humo: 250 Pa.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos prevista para las pruebas</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}