import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography } from '@material-ui/core/';
import image1 from './images/prueba-de-resistencia-mecanica.jpg';
import image2 from './images/Ensayo-de-estanquidad-al-agua.jpg';
import image3 from './images/Prueba-de-estanquidad-al-agua.jpg';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBAS DE ESTANQUIDAD EN TUBERÍAS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado del circuito hidráulico son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas hidrostáticas de redes de tuberías.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de estanquidad en redes de tuberías:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En cumplimiento de la IT 2.2.2 “Pruebas de estanquidad en redes de tuberías de agua”, la norma UNE- EN 14336 “Sistemas de calefacción en edificios. Instalación y puesta en servicio de sistemas de calefacción por agua” y norma UNE-CEN/TR 12108 IN “Sistemas de canalización en materiales plásticos. Práctica recomendada para la instalación en el interior de la estructura de los edificios de sistemas de canalización a presión de agua caliente y fría destinada al consumo humano” se probarán hidrostáticamente todas las redes de circulación de fluidos portadores a fin de asegurar su estanquidad, antes de quedar ocultas por obras de albañilería, material de relleno o por el material aislante, siguiendo el siguiente procedimiento.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                        <li>Se realizará el purgado del aire contenido en las redes por los puntos altos.</li>
                        <li>Se comprobará que los aparatos y accesorios que queden incluidos en la sección de la red que se pretende probar puedan soportar la presión a la que se les va a someter. De no ser así, tales aparatos y accesorios deben quedar excluidos, cerrando válvulas o sustituyéndolos por tapones.</li>
                        <li>Se taponará los externos de la red antes de las unidades terminales baterías de UTAs, cajas u otros equipos, impidiendo dichos elementos de taponamiento someter a estas a una sobrepresión para la cual no están diseñadas.</li>
                        <li>Se realizará una prueba preliminar de estanquidad a baja presión para detectar fallos de continuidad de la red y evitar los daños que podría provocar la prueba de resistencia mecánica. Generalmente se empleará agua a la presión de llenado durante un tiempo suficiente que permita verificar la estanquidad de todas las uniones.</li>
                        <li>Se realizará la prueba de resistencia mecánica sometiendo a las uniones a un esfuerzo por la aplicación de la presión de prueba (ver tabla 1) durante un tiempo suficiente que permita verificar visualmente la resistencia estructural de los equipos y tuberías sometidos a la misma. Una vez elevada la presión de la instalación sometida a prueba, se cerrarán las válvulas de la bomba y se desmontará esta, custodiándola en la oficina de obra hasta la finalización de la misma.</li>
                        <li>Se cerrará el grifo de purga, supervisando la evolución durante 90 minutos y realizando un control visual para localizar las posibles fugas. Si se estabiliza a una presión constante, superior a 0,5 veces la presión de diseño, es indicativo de que el sistema de canalización es bueno, en cambio, si durante este periodo la presión tiene una tendencia a bajar. El procedimiento a seguir varía en función del tipo de tubería:
                            <ol>
                                <li>Tuberías metálicas:
                                    <ol>
                                        <li>Obturación de conexión o cierre hermético de todos los extremos abiertos.</li>
                                        <li>Retirada y/o aislamiento de todos los elementos vulnerables, accesorios e interruptores de presión de la instalación y juntas de fuelle de expansión.</li>
                                        <li>Cierre de todas las válvulas en los límites de la sección de tubería a ensayar y conexión de las válvulas si no son herméticas o pueden ser susceptibles de vibraciones. Apertura de todas las válvulas dentro de la sección de ensayo.</li>
                                        <li>Comprobación de que todos los puntos altos tienen purgadores y de que todos están cerrados.</li>
                                        <li>Comprobación de que todos los indicadores de presión o manómetros están en funcionamiento, tienen los valores correctos y se han calibrado recientemente.</li>
                                        <li>Comprobación de que hay una valvulería de drenaje adecuada, una manguera disponible y de que ésta unirá las válvulas de viciado con el drenaje.</li>
                                        <li>Llenado del sistema con el fluido de prueba y exploración continua del mismo comprobado si hay fugas mediante el ruido de escapes de aire o signos de pérdida de líquido.</li>
                                        <li>Evacuación de aire en los puntos altos sistemáticamente a través del sistema.</li>
                                        <li>Elevación de la presión a la de ensayo cuando el sistema está lleno y cierre hermético del mismo.</li>
                                        <li>Verificación del ensayo y obtención de firmas.<br />
                                        <img src={image1} alt='Mínima presión de prueba admisible en prueba de resistencia mecánica según IT 2.2.2.4.' />
                                        </li>
                                    </ol>
                                </li>
                                <li>Tuberías plásticas (procedimiento de ensayo A):
                                    <ol>
                                        <li>Apertura del sistema de purga.</li>
                                        <li>Purga del sistema con agua para expulsar todo el aire que pueda evacuarse por este medio. Parada del caudal y cierre del sistema de purga.</li>
                                        <li>Aplicación de la presión hidrostática de ensayo durante los primeros 30 minutos. Durante ese tiempo debería realizarse la inspección para detectar cualquier fuga sobre el sistema a ensayar.</li>
                                        <li>Reducción de la presión a 0,5 veces la presión de diseño.</li>
                                        <li>Cierre del grifo de purga, supervisión de la evolución durante 90 minutos y realización de un control visual para localizar las posibles fugas. Si se estabiliza a una presión constante, superior a 0,5 veces la presión de diseño, es indicativo de que el sistema de canalización es bueno, en cambio, si durante este periodo la presión tiene una tendencia a bajar esto es indicativo de que existe una fuga en el sistema.</li>
                                        <li>Registrar el resultado del ensayo.<br/>
                                        <img src={image2} alt='Ensayo de estanquidad al agua Procedimiento de ensayo [Recuperado de: Norma UNE-CEN/TR' />
                                        </li>
                                    </ol>
                                </li>
                                <li>Tuberías plásticas (procedimiento de ensayo B):
                                    <ol>
                                        <li>Apertura del sistema de purga.</li>
                                        <li>Purga del sistema con agua para expulsar todo el aire que pueda evacuarse por este medio. Parada del caudal y cierre del sistema de purga.</li>
                                        <li>Aplicación de la presión hidrostática de ensayo durante los primeros 30 minutos. </li>
                                        <li>Lectura de la presión al final de los 30 min.</li>
                                        <li>Lectura de la presión después de otros 30 min, y realización de un control visual de la estanquidad. Si la caída de presión se encuentra por debajo de 0,6 bar, se deduce que el sistema no presenta fugas y se continúa en el ensayo sin bombear nuevamente.</li>
                                        <li>Realización del control visual de la estanquidad y si, durante las siguientes 2 h, la caída de presión es superior a 0,2 bar, esto es indicativo de que existe una fuga dentro del sistema.</li>
                                        <li>Registrar el resultado del ensayo.<br/>
                                        <img src={image3} alt='Ensayo de estanquidad al agua Procedimiento de ensayo [Recuperado de: Norma UNE-CEN/TR 12108 IN].' />
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                            <li>Cierre del grifo de purga, supervisión de la evolución durante 90 minutos y realización de un control visual para localizar las posibles fugas. Si se estabiliza a una presión constante, superior a 0,5 veces la presión de diseño, es indicativo de que el sistema de canalización es bueno, en cambio, si durante este periodo la presión tiene una tendencia a bajar.</li>
                            <li>Se repararán las fugas detectadas desmontando la junta, accesorio o sección donde se haya originado la fuga y sustituyendo la parte defectuosa o averiada con material nuevo.</li>
                            <li>Una vez reparadas las anomalías, se volverá a comenzar desde la prueba preliminar, repitiéndose este proceso tantas veces como sea necesario, hasta que la red sea estanca.</li>
                        </ol>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>La prueba se considera satisfactoria si la presión leída en los manómetros de comprobación no varía en un plazo de veinticuatro horas.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos prevista.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}