import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Paper, Grid, Typography, Link } from '@material-ui/core/';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useLocation, useParams } from "react-router-dom";

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '120px',
        borderRadius: '1rem',
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
          },
      },
      root: {
          paddingBottom: theme.spacing(5),
      },
  }));

export default function NavItemPlato() {
    
    const { idBuild } = useParams();
    const classes = useStyles();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/socrates/");
    const link_1 = "/" + idBuild + "/socrates/technics"
    const link_2 = "/" + idBuild + "/socrates/docadmin"
    const link_3 = "/" + idBuild + "/socrates/installations"
    const link_4 = "/" + idBuild + "/socrates/others"

    return (
            <div className={classes.root}>
                <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                    <Grid item>
                        <Link href={link_1}>
                            <Paper className={[splitLocation[1] === "technics" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <AssignmentIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Documentación<br/> Técnica</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href={link_2}>
                            <Paper className={[splitLocation[1] === "docadmin" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <AssignmentIndIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Documentación<br/> Administrativa</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href={link_3}>
                            <Paper className={[splitLocation[1] === "installations" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <BuildIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Documentación de Instalaciones</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href={link_4}>
                            <Paper className={[splitLocation[1] === "others" ? "MuiAppBar-colorPrimary" : "", classes.paper]} elevation={2}>
                                <MoreHorizIcon /> <br/ >
                            <Typography variant='caption' align='center'><Trans>Documentación específica Otros</Trans></Typography>
                            </Paper>
                        </Link>
                    </Grid>
                </Grid>
            </div>
    )
}