import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>Puesta en marcha instalaciones de fontanería</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>COMPROBACIÓN Y AJUSTE DE LOS SISTEMAS DE EXPANSIÓN Y SEGURIDAD</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado de la instalación son preceptivas bajo el CTE DB HS 4 del Código Técnico de la Edificación que establece las pruebas y ensayos a realizar en la instalación terminada.</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la comprobación y ajuste de los sistemas de expansión y seguridad.</Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Comprobación y ajuste de los sistemas de expansión y seguridad</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La comprobación y ajuste de los sistemas de expansión y de seguridad implica la realización de los siguientes procedimientos:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>
                    <ul>
                        <li>Se comprobará la existencia de un manómetro de presión de rango y precisión adecuados para la medida de las presiones manométricas del circuito (si es posible, rango 0-3 bar, en caso contrario, rango 0-6 bar o superior).</li>
                        <li>Se comprobará que el sistema de llenado permita alcanzar la presión mínima requerida en el punto de llenado.</li>
                        <li>Se comprobará que el volumen del vaso de expansión es el correcto, así como las presiones nominal y máxima del vaso de expansión y la de tarado de la válvula de seguridad, comprobándose que coinciden con los especificados en el Proyecto o Memoria Técnica.</li>
                        <li>Se comprobará que la presión de llenado del vaso de expansión (presión del nitrógeno del vaso) es la adecuada y en caso contrario se ajustará al valor indicado en el Proyecto o Memoria Técnica. La presión inicial del vaso debe ser 0,2-0,3 bar inferior a la presión de llenado del circuito.</li>
                        <li>Se comprobará la presión de tarado de las distintas válvulas de seguridad instaladas, disparando las mismas de forma manual, además de comprobar su funcionamiento y el correcto funcionamiento del desagüe (la salida de agua debe ser visible).</li>
                        <li>Se comprobará el sistema de llenado que podrá ser por presión de red o mediante bomba manual o automática.</li>
                        <li>Sistema de llenado por presión de red:
                            <ul>
                                <li>Se comprobará la existencia de un manómetro de rango adecuado para medir la presión de red.</li>
                                <li>Se comprobará que se haya instalado una válvula limitadora de presión, indicándose en su caso la presión de corte.</li>
                                <li>Se comprobará que se haya instalado desconector.</li>
                                <li>Se comprobará que el sistema de llenado sea automático.</li>
                                <li>Se comprobará que se haya instalado contador de medida.</li>
                            </ul>
                        </li>
                        <li>Sistema de llenado con bomba:
                            <ul>
                                <li>Se comprobará que el fluido térmico empleado es el especificado para evitar heladas.</li>
                                <li>Se comprobará que la bomba de llenado instalada permite llegar a la presión mínima o de llenado</li>

                            </ul>
                        </li>
                        <li>Se comprobará que se han instalado suficientes válvulas de vaciado que permitan el vaciado total y parcial del circuito. </li>
                        <li>Se comprobará que se hayan instalado purgadores automáticos en los puntos más altos del circuito.</li>
                        <li>Se comprobará que los desagües se han realizado de forma adecuada y permiten el vaciado de forma visible.</li>
                    </ul>
                    </Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography>
            </Container>
        </Fragment>
    )
}