import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../../layout/Table';
import { Container } from '@material-ui/core';
import { connect } from 'react-redux';
import { getDocumentTypes } from '../../../actions/files';
import { withRouter } from 'react-router-dom';
import _ from "lodash";

/**Componente para la gestión de usuarios. */
export class InstallationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      document_types: [],
    };

    this.columns = [
      {
        title: 'Id',
        data: 'id'
      },
      {
        title: 'Instalación',
        data: 'description'
      },
      {
        title: 'Descripción',
        data: 'description'
      },
      {
        title: 'Normativa',
        data: 'normative',
      },
      {
        title: 'Documentación\ndisponible',
        data: null, 
        render: function (data, type, row) {
          let url = ""
          data.state = "no"
          data.detail = "No hay ningun archivo asociado";
          data.download = "disabled"
          if (data.file != null && data.file.file) {
            data.download = "disponible"
            data.state = "si"
            data.detail = "Texto si tiene archivo"
          }
          if (data.file != null) {
            url = `${data.file.file}`
            if (url.includes("http") == false){
              url = `${axios.defaults.baseURL}${data.file.file}`
            }
            return `<div class="tooltip-exta"><a href="${url}" target="_blank" class="MuiButtonBase-root MuiButton-root Mui-'${data.download}' MuiButton-outlinedPrimary">${data.state}<i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">${data.detail}</span></div>`
          }
          return '<div class="tooltip-exta"><a href="' + url + '" class="MuiButtonBase-root MuiButton-root Mui-' + data.download + ' MuiButton-outlinedPrimary">' + data.state + ' <i class="fa fa-file-pdf"></i></a><span class="tooltiptext-exta MuiButton-containedPrimary">' + data.detail + '</span></div>'
        },
      },
      {
        title: 'Subir\ndocumentación',
        data: null,
        action: 'upload',
        f_action: ''
      },
      {
        title: 'Info',
        data: null, render: function (data, type, row) {
          return '<a href="#info-modal' + data.id + '" class="MuiButtonBase-root MuiButton-root MuiButton-outlinedPrimary"><i class="fa fa-info-circle" aria-hidden="true"></i></a><a href="#"><div id="info-modal' + data.id + '" class="modal info-modal"><div class="MuiPaper-rounded MuiPaper-root modal-contenido"><h4 class="text-modal">' + data.description + '</h4><p class="text-modal">' + data.information + '</p></div></div></a>'
        },
      }
    ]
  }
  static propTypes = {
    document_types: PropTypes.array,
    getDocumentTypes: PropTypes.func,
    isLoading: PropTypes.bool
  }

  /**Se declaran props por defecto */
  static defaultProps = {
    //Example data
    title: '',
    data: [
      // { id: 1, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Saneamiento y fontanería', description: 'CTE RD 732/2019', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto debe cumplir el Documento Básico DB HS 4 sobre suministro de agua debe ser acorde al Código Técnico bajo RD 732/2019. El contenido y alcance está fijado en la propia normativa.' },
      // { id: 2, name: 'Registro en Industria y aprobación de puesta en marcha (Certificado OCA/EICI)', installation: 'Saneamiento y fontanería', description: 'CTE RD 732/2019', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El registro por parte del órgano competente de cada comunidad autónoma de las instalaciones interiores de suministro de agua que se lleven a cabo es condición necesaria para la puesta en servicio de la instalación. En el ámbito territorial de la Comunidad de Madrid se establece la intervención de las Entidades de Inspección y Control Industrial (EICI) en el procedimiento administrativo para el registro de las instalaciones interiores de suministro de agua.  Para las instalaciones que requieren proyecto es necesario aportar: \n\n 1. Solicitud de conformidad de instalaciones interiores de suministro de agua. Resumen técnico, en modelo normalizado por las EICI. \n 2. Proyecto específico de la instalación, que será suscrito por técnico competente. \n 3. Certificado de dirección y terminación de obra de instalación interior de suministro de agua. Acta de prueba de presión, por triplicado en modelo normalizado por las EICI. \n 4. Copia del certificado de empresa instaladora de fontanería en vigor.' },
      // { id: 3, name: 'Autorización de enganche a la red de saneamiento', installation: 'Saneamiento y fontanería', description: 'Normas particulares CIA', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'A solicitar en la compañía distribuidora de zona en el momento de realizar la instalación de conexión con la red municipal. Los requerimientos y documentación los fija la propia compañía distribuidora. En el caso de Madrid el Canal de Isabel II.' },
      // { id: 4, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Gas', description: 'RD 919/2006', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto debe cumplir lo establecido en el Real Decreto 919/2006, de 28 de julio, por el que se aprueba el Reglamento técnico de distribución y utilización de combustibles gaseosos y sus instrucciones técnicas complementarias ICG 01 a 11.. El contenido y alcance está fijado en la propia normativa.' },
      // { id: 5, name: 'Registro en Industria y aprobación de puesta en marcha (OCA/EICI)', installation: 'Gas', description: 'Orden 943/2003', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Las instalaciones con potencia superior a 70 kW deben contar con la realización de un proyecto por técnico titulado competente. Deben ser registradas en el órgano competente de la comunidad autónoma correspondiente. Si la potencia es menor que 70 kW es suficiente con un certificado expedido por instalador habilitado, sin que sea necesario en ese caso el paso por el órgano de la administración pública. La instalación se comunica a la administración una vez cerrado con el distribuidor y legalizada por Entidad homologada.' },
      // { id: 6, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización) de las instalaciones de Baja Tensión y Media Tensión', installation: 'Electricidad', description: 'RD 842/2002', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto de baja tensión lo regula el Real Decreto 842/2002, de 2 de agosto, por el que se aprueba el Reglamento electrotécnico para baja tensión. En el caso de proyecto adicional de meida tensión (por necesidad de enganche y centro de transformación asociado) lo regula Real Decreto 337/2014, de 9 de mayo, por el que se aprueban el Reglamento sobre condiciones técnicas y garantías de seguridad en instalaciones eléctricas de alta tensión y sus Instrucciones Técnicas Complementarias ITC-RAT 01 a 23. En ambos documentos se fijan las condiciones de redacción y alcance de presentación.' },
      // { id: 7, name: 'Registro en Industria y aprobación de puesta en marcha de MT', installation: 'Electricidad', description: 'RD 337/2014', state: 'si', detail: 'Validez 3 años', download: 'disponible', info: 'Cada Comunida d Autónoma publica  los procedimientos para autorizar las instalaciones de producción, transporte y distribución de energía eléctrica de alta tensión, incluidas las de producción abastecidas por recursos propios o fuentes de energía renovables, residuos y cogeneración, así como las instalaciones de autoconsumo al objeto de dar cumplimiento a los reglamentos y disposiciones vigentes.\n\n Entre ellos se incluye la puesta en servicio de instalaciones de alta tensión de consumidores para su uso exclusivo. Se debe disponer del certificado una vez finalizado el trámite y pruebas de la instalación. En el caso de la Media Tensión también se requiere permiso de la Consejería de Industria de la Comunidad Autónoma.' },
      // { id: 8, name: 'Certificado de OCA de depósitos de gasóleo (grupos electrogenos)', installation: 'Electricidad', description: 'RD 1523/1999', state: 'si', detail: 'Validez 10 años', download: 'disponible', info: 'Se deben legalizar las instalaciones  de gasóleo que se rigen por la norma MI-IP-03 y 04 que excedan de los cubicajes fijados en dicha norma. Precisaran de proyecto técnico y certificado final de obra de la dirección facultativa. La documentación se tiene que registrar en la Delegación de Industria de la Comunidad Autónoma correspondiente. \n\n En función de la comunidad autónoma se deberán tener en cuenta los formularios propios de la Administración correspondiente así como el pago de tasas que le aplique. Se anexará certificado finalziado el proceso en caso de estar dentro de los marcados en la normativa.' },
      // { id: 9, name: 'Certificado de OCA Baja Tension', installation: 'Electricidad', description: 'RD 842/2002', state: 'si', detail: 'Validez 5 años', download: 'disponible', info: 'Cada Comunida d Autónoma publica  los procedimientos para autorizar las instalaciones de Baja Tensión bajo el Real Decreto 842/20022. Se debe disponer del certificado una vez finalizado el trámite y pruebas de la instalación.' },
      // { id: 10, name: 'Certificado de OCA alumbrado exterior', installation: 'Electricidad', description: 'RD 1890/2008', state: 'si', detail: 'Validez 5 años', download: 'disponible', info: 'Según el Real Decreto 1890/2008, de 14 de noviembre, por el que se aprueba el Reglamento de eficiencia energética en instalaciones de alumbrado exterior y sus Instrucciones técnicas complementarias EA-01 a EA-07, toda instalación de alumbrado de mas de 1 kW deben legalizarse en base a los criterios establecidos por cada Comunidad Autónoma. Está sujeta a inspecciones de eficiencia energética con periodicidad marcada en la propia normativa.' },
      // { id: 11, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Calefacción y climatización', description: 'RD 1027/2007', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Para la instalación térmcia completa  (climatización,  ventilación y  producción de ACS) se debe presentar proyecto según el Real Decreto 1027/2007, de 20 de julio, por el que se aprueba el Reglamento de Instalaciones Térmicas en los Edificios. El alcance y contenido está descrito en la propia normativa.' },
      // { id: 12, name: 'Registro en Industria y aprobación de puesta en marcha (certificado OCA-EICI)', installation: 'Calefacción y climatización', description: 'RD 1027/2007', state: 'si', detail: 'Validez 4 años', download: 'disponible', info: 'Según el Real Decreto 1027/2007, de 20 de julio, por el que se aprueba el Reglamento de Instalaciones Térmicas en los Edificios, la Comunidad Autónoma es la responsable de solicitar la legalización y puesta en servicio de la instalación térmica completa (climatización, ventilación y producción de ACS).  En la Comunidad de Madrid lse realiza la gestión a través de la EICI. Para las instalaciones que requieren proyecto (Instalaciones o conjunto de instalaciones térmicas que, en régimen de generación de calor o frío, tengan una potencia nominal igual o superior a 70 kW)  se requiere: \n\n - Solicitud de registro de proyecto según modelo oficial. \n - Proyecto específico de la instalación redactado y firmado por técnico titulado competente y visado por su Colegio Oficial. \n - Certificado de montaje. \n - Certificado de Instalación.' },
      // { id: 13, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Protección contraincendios', description: 'RD 513/2017', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto debe cumplir con lo especificado en el Real Decreto 513/2017, de 22 de mayo, por el que se aprueba el Reglamento de instalaciones de protección contra incendios. El alcance y documentación justificativa se marca en la propia norma.' },
      // { id: 14, name: 'Registro en Industria y aprobación de puesta en marcha (certificado OCA-EICI)', installation: 'Protección contraincendios', description: 'RD 513/2017', state: 'si', detail: 'Validez 10 años', download: 'disponible', info: 'La instalación se legaliza en Industria de la COmunidad Autónoma. En el caso de Madrid se debe seguir, a través de EICI el "Procedimiento para la tramitación del registro de las instalaciones de protección contra incendios según orden de 12 de marzo de 2014 de la Comunidad de Madrid y Orden de 17 de febrero de 2009 de homogeneización y simplificación".' },
      // { id: 15, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Aparatos elevadores', description: 'RD 88/2013', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'La construcción y proyecto de ejecución, así como la puesta en servicio de un ascensor debe cumplir con el Real Decreto 88/2013, de 8 de febrero, por el que se aprueba la Instrucción Técnica Complementaria AEM 1 "Ascensores" del Reglamento de aparatos de elevación y manutención, aprobado por Real Decreto 2291/1985, de 8 de noviembre.' },
      // { id: 16, name: 'Registro en Industria y aprobación de puesta en marcha. Certificado OCA/EICI RAE', installation: 'Aparatos elevadores', description: 'RD 88/2013', state: 'si', detail: 'Validez 2 años', download: 'disponible', info: 'El RAE (Registro de Aparatos Elevadores) es el número de identificación y registro que el órgano competente de la Comunidad Autónoma otorga a un ascensor, una vez presentada la documentación legal correspondiente para su puesta en servicio.\n\n El alta de un aparato elevador en el RAE implica que, en el momento de su inscripción, este cumple con todos los requisitos en materia de seguridad según establece el Real Decreto 88/2013, de 8 de febrero, por el que se aprueba la Instrucción Técnica Complementaria AEM 1 ""Ascensores"" del Reglamento de aparatos de elevación y manutención, aprobado por Real Decreto 2291/1985, de 8 de noviembre . El RAE permite la identificación unívoca del aparato a la hora de realizar cualquier trámite relacionado con el ascensor.' },
      // { id: 17, name: 'Proyecto actualizado de la instalación con planos “As-built” (necesario para la legalización)', installation: 'Gases medicinales', description: 'RD 1800/2003', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto de gases medicinales debe cumplir con el Real Decreto 1800/2003, de 26 de diciembre, por el que se regulan los gases medicinales. Las condiciones de servicio y diseño en la actualidad están reguladas por la norma UNE EN ISO 7396-1:2016 sobre sistemas de canalización de gases medicinales. Parte 1: Sistemas de canalización para gases medicinales comprimidos y de vacío. (ISO 7396-1:2016).' },
      // { id: 18, name: 'Registro en Industria y aprobación de puesta en marcha', installation: 'Gases medicinales', description: 'RD 1800/2003 + RD 809/2021', state: 'si', detail: 'Validez 2 años', download: 'disponible', info: 'El registro en Industria de la instalación de gases medicinales debe cumplir con el Real Decreto 1800/2003, de 26 de diciembre, por el que se regulan los gases medicinales y adicionalmente con el Real Decreto 809/2021, de 21 de septiembre, por el que se aprueba el Reglamento de equipos a presión y sus instrucciones técnicas complementarias, en las partes y sistemas que aplique.' },
      // { id: 19, name: ' Proyecto actualizado de la instalación con planos “As-built”', installation: 'Instalaciones de comunicaciones', description: '', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'El proyecto de instalación de infraestructura común de telecomunicaciones ( ICT)  marca las bases de la instalación de telecomunicaciones en aquellos inmuebles con varios propietarios  (acogidos al régimen de propiedad horizontal) que requieren acceso a los servicios de telecomunicación en el regulados. El documento normativo de referencia es el Real Decreto 346/2011, de 11 de Marzo, por el que se Aprueba el Reglamento Regulador de las Infraestructuras Comunes de Telecomunicaciones para el Acceso a los Servicios de Telecomunicación en el Interior de las Edificaciones' },
      // { id: 20, name: 'Certificado de la instalación', installation: 'Instalaciones de comunicaciones', description: 'RD 346/2011', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'La empresa instaladora debe expedir el Boletín de Instalación y realizar el Protocolo de Pruebas marcadas en la normativa de aplicación.' },
    ],
  };

  componentDidMount() {

    const { idBuild } = this.props.match.params
    const module_id = 3
    this.props.getDocumentTypes(idBuild,module_id);

    this.setState({
      document_types: this.props.document_types,
      isLoading: this.props.isLoading,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.document_types, this.props.document_types)) {
      this.setState({
        document_types: this.props.document_types,
        isLoading: this.props.isLoading,
      });
    }
  }

  render() {

    const { isLoading } = this.state;
    const document_types = [...this.state.document_types];

    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    return (
      <Container>
        <Table
          title=""
          columns={this.columns}
          data={document_types} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  document_types: state.files.document_types,
  isLoading: state.files.isLoading,
});

export default connect(mapStateToProps, { getDocumentTypes })(withRouter(InstallationsList));
