import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createMessage } from '../../actions/messages';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, TextField, FormControlLabel, Checkbox, Grid } from '@material-ui/core/';
import { editBuilding, loadBuildings, loadBuilding } from '../../actions/building';
import { loadUser } from '../../actions/auth';


/**Componente que contiene el formulario para la edición de los datos del edificio actual. */
export class EditBuilding extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    building: {
      id: '',
      name: '',
      location: ''
    }
  }

  static propTypes = {
    build: PropTypes.func,
    edit: PropTypes.func,
    building: PropTypes.func.isRequired
  };
  
  componentDidMount() {
    this.props.loadBuilding(this.props.match.params.id);
  }

  componentDidUpdate(prevProps){
    if (prevProps !== this.props){
      this.setState({
        id: this.props.building.id,
        name: this.props.building.name,
        location: this.props.building.location,
      })
    }
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();
    const { id, name, location } = this.state;

      const editBuilding = {
        id,
        name,
        location
      }

      this.props.editBuilding(editBuilding);
    }

  render() {
    const { name, location } = this.state;
    return (
      <div className="root">
        <Typography variant="h3" gutterBottom>
          <Trans>Editar edificios</Trans>
        </Typography>
        <form className="root">
          <TextField variant="outlined" margin="normal" id="building-new-name" label="Nombre" type="text" fullWidth
            name="name"
            value={name}
            onChange={this.onChange}
            required
          />
          <TextField variant="outlined" margin="normal" id="building-location" label="Localización" type="text" fullWidth
            name="location"
            value={location}
            onChange={this.onChange}
            required
          />
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
              <Button type="button" color="primary" onClick={this.onSubmit}><Trans>Editar edificios</Trans></Button>
              <Button type="button" color="secondary" component={Link} to="/adminpanel/buildings"><Trans>Volver</Trans></Button>
            </ButtonGroup>
          </Grid>
        </form>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  building: state.buildings.building,
});

export default connect(mapStateToProps, { createMessage, editBuilding, loadBuildings, loadBuilding, loadUser })(EditBuilding);