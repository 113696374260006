import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';
import image1 from './images/conductos-instalados.jpg';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMAS DE AIRE</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBAS DE RESISTENCIA ESTRUCTURAL Y ESTANQUIDAD EN CONDUCTOS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas a la puesta en marcha de la red de conductos son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en las pruebas de resistencia estructural y estanquidad de la red de conductos.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Pruebas de resistencia estructural y estanquidad</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Los conductos de chapa serán herméticos y no deberán vibrar o moverse cuando el sistema esté funcionando. A objeto de garantizar esta condición, los conductos se sellarán con sellador inalterable adecuado al uso y que deberá contar con la aprobación de la Dirección Facultativa, prestando especial atención al sellado de piezas especiales, derivaciones y conductos instalados en la intemperie, independientemente de que estos vayan aislados o no.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>La red de conductos, en cumplimiento de la IT 2.2.5 “Pruebas de recepción de redes de conductos de aire” y la norma UNE-EN 12237 “Ventilación de edificios. Conductos. Resistencia y fugas de conductos circulares de chapa metálica”, se someterá a pruebas de resistencia estructural y de estanquidad antes de que la red de conductos se haga inaccesible por la instalación de aislamiento térmico o el cierre de obras de albañilería y de falsos techos, siguiendo el siguiente procedimiento:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                        <li>Se cerrarán rígidamente mediante tapones de chapa metálica u otro material las aperturas de los conductos donde irán conectados los elementos de difusión de aire o las unidades terminales a fin de que queden perfectamente selladas, realizándose el montaje de los elementos de cierre en el momento del montaje de los conductos para evitar la introducción de materiales extraños y de suciedad.</li>
                        <li>Se realizará una prueba preliminar siguiendo el siguiente procedimiento:
                            <ol>
                                <li>Se pondrá en marcha el ventilador gradualmente hasta alcanzar una presión igual a vez y media la presión máxima de trabajo de la red o a la presión máxima de trabajo más 500 Pa (se seleccionará la mayor de las dos), realizándose un acoplamiento entre la boca de descarga del ventilador y la entrada al tramo de conducto adecuado y evitándose la presencia de fugas.</li>
                                <li>Se realizará el reconocimiento auditivo de la red a fin de detectar las fugas de aire.</li>
                                <li>Se parará el ventilador y se procederá al sellado de todas las uniones defectuosas.</li>
                                <li>Se dejará transcurrir el tiempo necesario para que el material sellante tenga tiempo a fraguar.</li>
                                <li>Se efectuará de nuevo la prueba hasta que hayan sido eliminadas todas las fugas.   </li>
                            </ol>
                        </li>
                        <li>Se realizará una prueba estructural (solo para conductos de forma rectangular) siguiendo el siguiente procedimiento:
                            <ol>
                                <li>Se pondrá en marcha el ventilador hasta alcanzar una presión igual a vez y media la presión máxima de trabajo de la red, realizándose un acoplamiento entre la boca de descarga del ventilador y la entrada al tramo adecuado y evitándose la presencia de fugas.</li>
                                <li>Se comprobará que chapas de las paredes resisten la presión sin deformarse y sin perder la estanquidad, siendo la deflexión máxima permitida de:
                                    <ul>
                                        <li>10 mm para lados de hasta 300 mm.</li>
                                        <li>12 mm para lados de hasta 450 mm.</li>
                                        <li>15 mm para lados de hasta 600 mm.</li>
                                        <li>20 mm para lados de más de 600 mm.</li>
                                    </ul>
                                </li>
                                <li>Se comprobará que los refuerzos transversales de los conductos o sus uniones transversales son capaces de resistir la presión sin deformarse y sin perder la estanquidad, siendo la deflexión máxima permitida de 6 mm.</li>
                            </ol>
                        </li>
                        <li>Se realizará una prueba de estanquidad siguiendo el siguiente procedimiento:
                            <ol>
                                <li>Se pondrá en marcha el ventilador gradualmente hasta alcanzar la presión máxima de servicio, realizándose un acoplamiento entre la boca de descarga del ventilador y la entrada al tramo de conducto adecuado y evitándose la presencia de fugas.</li>
                                <li>Se anotará el caudal de fugas leído en el sistema de medida.</li>
                                <li>Se comprobará que el caudal de fuga se ajusta a lo indicado en el Proyecto o Memoria Técnica, de acuerdo con la clase estanquidad exigida por el RITE, que establece una estanquidad de clase B para redes de conductos. La norma UNE-EN 12237 exige que la clase B se aplique a conductos con presiones de hasta 1.000 Pa positivos y 750 Pa negativos, estableciendo un caudal máximo de fugas bajo estas condiciones en función de la superficie de la red a calcular con la siguiente fórmula: <strong>f = 0,009 x p<small><sub>ensayo</sub><sup>0,65</sup></small> x 10<small><sup>-3</sup></small> m<small><sup>3</sup></small>/(sm<small><sup>2</sup></small>)</strong>. Para conductos instalados en intemperie se exigirá clase C o D, resultando de aplicación la siguiente tabla:
                                <img src={image1} alt='Ensayo de conductos instalados' />
                                </li>
                            </ol>
                        </li>
                        </ol>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}