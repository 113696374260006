import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>EXTRACCIÓN DE GARAJES</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PUESTA EN MARCHA VENTILADORES DE GARAJES</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>La puesta en marcha de los ventiladores en garajes de acuerdo con el Artículo 7.4 “Control de la obra terminada” del CTE que establece que en la instalación terminada deben realizarse las comprobaciones y puestas en servicio previstas en el proyecto u ordenadas por la dirección facultativa y las exigidas por la legislación aplicable.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en puesta en marcha de ventiladores en garajes incluyendo preparación y verificaciones previas y mediciones de caudales y prestaciones de equipos.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Preparación y verificaciones previas</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>La preparación del sistema y las verificaciones previas de la ejecución del montaje implica la realización de los siguientes procedimientos:</Trans>
                </Typography>  
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Se verificará el estado de conservación, limpieza y apertura de las tomas y descargas de aire al exterior.</li>
                            <li>Se verificará el estado de los patinillos de descarga (acabado uniforme del enfoscado o similar implementado) y en el caso de la red de extracción del plenum de admisión teniendo especial cuidado a la anchura media del mismo.</li>
                            <li>Se verificará que las compuertas de regulación y cortafuegos (en el caso de existir en alguna red) estén en posición normal. </li>
                            <li>Se verificará que los ventiladores giran en el sentido correcto.</li>
                            <li>Se verificará la posición de las rejillas de extracción y elementos de regulación (el caudal unitario a extraer por cada rejilla está marcado en proyecto).</li>
                            <li>Se verificará que el aislamiento a tierra de los circuitos eléctricos de fuerza, mando y control es correcto.</li>
                            <li>Se medirá la tensión y corriente absorbida. </li>
                            <li>Se medirá la velocidad de giro de los motores y ver adecuación a selección de fabricante.</li>
                        </ul>
                    </Trans>
                </Typography>
                <Typography variant="h5" gutterBottom>
                    <Trans>Mediciones de caudales y prestaciones de equipos</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las mediciones se realizarán siguiendo los siguientes procedimientos:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ol>
                            <li>Se arrancarán los ventiladores de impulsión. </li>
                            <li>Se medirá el caudal aportado en lugar accesible en patinillo.</li>
                            <li>Se medirá el caudal de los extractores, pudiendo realizarse esta medición con anemómetro rotativo (mediante barrido o por puntos).</li>
                            <li>Se aceptará como válida la puesta en marcha si el valor medido del caudal de extracción está dentro del rango +/- 10% del valor nominal del equipo.</li>
                        </ol>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjuntan hojas de toma de datos previstas.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}