import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import Signature from '../components/signature';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }
    };

    render() {

        const classes = this.useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO-007</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PRUEBA CIRCULACIÓN: AJUSTE Y EQUILIBRADO</Trans>
            </Typography>
            <Container>
                <form className={classes.root}>
                    <Typography variant="h5" gutterBottom>
                    <Trans>Datos Generales</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Empresa Instaladora" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Cliente" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Técnico" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label="Identificación del circuito en la instalación" variant="outlined" />
                        </Grid> 
                        <Grid item xs={12}>
                            <TextField fullWidth label="Identificación del grupo de bombeo" variant="outlined" />
                        </Grid>    
                        <Grid item xs={12}>
                            <TextField fullWidth label="Procedimiento de equilibrado previsto en el Proyecto o Memoria Técnica" variant="outlined" />
                        </Grid>   
                    </Grid>
                    <Typography variant="h5" gutterBottom>
                    <Trans>PUESTA EN SERVICIO GRUPO DE BOMBEO</Trans>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                    <Trans>DATOS GENERALES</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Local" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="ID" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined"><AddIcon /></Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" gutterBottom>
                    <Trans>CARACTERÍSTICAS NOMINALES (T=5oC)</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="P.TOT [W]" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Q AGUA [l/h]" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined"><AddIcon /></Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" gutterBottom>
                    <Trans>CARACTERÍSTICAS PROYECTO</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={4}>
                            <TextField fullWidth label="P.TOT [W]" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField fullWidth label="Q AGUA [l/h]" variant="outlined" />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined"><AddIcon /></Button>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" gutterBottom>
                    <Trans>MEDIDAS REALIZADAS Indicar si en frío o en calor</Trans>
                    </Typography>
                    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="ΔT [oC]" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Ta AGUA IN (oC)" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Ta AGUA OUT (oC)" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Ta/ HR (%) AIRE IN" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Ta/ HR (%) AIRE OUT" variant="outlined" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="POSICIÓN VÁLVULA" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined"><AddIcon /></Button>
                        </Grid>
                    </Grid>
                    <Typography variant="h5" gutterBottom>
                    <Trans>RESULTADO DE LA ACTIVIDAD</Trans>
                    </Typography>
                    <TextField fullWidth label="Resultado de la actividad" multiline rows={6} variant="outlined"  />

                    <Typography variant="h5" gutterBottom>
                    <Trans>CONFORMIDAD</Trans>
                    </Typography>
                    <Signature />
                    <Submit document={this.props.document} />
                </form>
            </Container>
        </Fragment>
    )
}
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);