import { GET_AUDIT } from '../actions/types';

const initialState = {
    audit: [],
};

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_AUDIT:
            return {
                ...state,
                audit: [action.payload]
            };
        default:
            return state;
    }  
}
