import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../layout/Table';
import { Container, Paper, Grid, Typography, Button } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import { getFilesToCount } from '../../actions/files';
import _ from "lodash";

/**Componente para la gestión de documentos para validar. */
export class ValidationList extends Component {

  constructor(props) {
    super(props);

    if (!localStorage.getItem('filter_type') || this.props.documents.length == 0) {
      localStorage.setItem('filter_type', 1)
    }

    this.state = {
      isLoading: true,
      documents: [],
    }

    this.columns = [
      // {
      //   title: 'Id',
      //   data: 'id'
      // },
      {
        title: 'Fecha de creación',
        data: 'date',
      },
      {
        title: 'Módulo',
        data: 'modules_name',
      },
      {
        title: 'Submódulo',
        data: 'submodule_name',
      },
      {
        title: 'Edificio',
        data: 'building_name',
      },
      {
        title: 'Cliente',
        data: 'owner_name',
      },
      {
        title: 'Descripción',
        data: 'document_type.description'
      },
      // {
      //   title: 'Normativa',
      //   data: null,
      //   render: function (data) {
      //     var normative = ""
      //     var socrates_modules = [1,2,3,4]
      //     if (socrates_modules.includes(data.module)) {
      //       normative = data.document_type.normative
      //     }
      //     return normative
      //   }
      // },
      {
        title: 'Documentación\ndisponible',
        data: null,
        action: "run-redirect-dynamic",
        f_action: ""
      },
      {
        title: 'Ver / Estado',
        data: null,
        action: "view",
        f_action: ""
      },
      {
        title: 'Validar\ndocumentación',
        data: null,
        action: 'run-redirect',
        f_action: ''
      },
    ]
  }

  static propTypes = {
    documents: PropTypes.array,
  }

  /**Se declaran props por defecto */
  // static defaultProps = {
  //   //Example data
  //   title: '',
  //   data: [
  //     // { id: 1, name: 'Licencia ocupación', description: 'OMTLU MADRID', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Para la ocupación del edificio es necesario disponer de la Licencia de primera ocupación y funcionamiento. La licencia de primera ocupación lo emite el Ayuntamiento una vez comprobado que el edificio ha sido ejecutado según el proyecto y condiciones en que la licencia fue concedida. Es relevante su obtención, ya que constata que las obras y actividades están terminadas y aptas en base a las condiciones urbanísticas, ambientales y de seguridad requeridas.' },
  //     // { id: 2, name: 'Autorización sanitaria del centro', description: 'Decreto 51/2006', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En el caso de ser un edificio sanitario o disponer  de servicios regulados por la Consejería de Sanidad se debe disponer de la preceptiva autorización sanitaria. En el caso de la Comunnidad de Madrid se regula a través del  Decreto 51/2006, de 15 de junio, del Consejo de Gobierno, Regulador del Régimen Jurídico y Procedimiento de Autorización y Registro de Centros, Servicios y Establecimiento Sanitarios de la Comunidad de Madrid.' },
  //     // { id: 3, name: 'Autorización sanitaria cocina', description: 'Ley 12/2001', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En España las regulaciones referidas a las Autorizaciones Sanitarias de cocinas y restaurantes dependen de cada municipio, se realiza a través de los organismos locales municipales o Ayuntamientos. En caso de disponer de cocina o restaurante corporativo se debe disponer de la preceptiva autorización para su explotación.' },
  //     // { id: 4, name: 'Autorización sanitaria cafetería', description: 'Ley 12/2001', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'En España las regulaciones referidas a las Autorizaciones Sanitarias de cocinas y restaurantes dependen de cada municipio, se realiza a través de los organismos locales municipales o Ayuntamientos. En caso de disponer de cocina o restaurante corporativo se debe disponer de la preceptiva autorización para su explotación.' },
  //     // { id: 5, name: 'Autorización Funcionamiento', description: 'OMTLU MADRID', state: 'no', detail: 'Falta de validación', download: 'disabled', info: 'Para la ocupación del edificio es necesario disponer de la Licencia de primera ocupación y funcionamiento. La licencia de primera ocupación lo emite el Ayuntamiento una vez comprobado que el edificio ha sido ejecutado según el proyecto y condiciones en que la licencia fue concedida. Es relevante su obtención, ya que constata que las obras y actividades están terminadas y aptas en base a las condiciones urbanísticas, ambientales y de seguridad requeridas.' },
  //   ],
  // };

  componentDidMount() {

    // Contiene el GET de la URL para recoger el ID BUILDING
    // console.log(window.location.pathname)
    this.props.getFilesToCount();

    this.setState({
      documents: this.props.documents,
      isLoading: this.props.isLoading,
    });
  }
  
  componentDidUpdate(prevProps, prevState) {
    // _.isEqual lo que hace es comparar, da igual el orden del json
    // Con JSON.stringify lo compara pero a veces mueve el orden
    if (!_.isEqual(prevProps, this.props)) {
      this.setState({
        documents: this.props.documents,
        isLoading: this.props.isLoading,
      });
      this.mantainFilter(localStorage.getItem('filter_type'))
    }
  }

  clickHandler = (e, type) => {
    e.preventDefault()
    this.mantainFilter(type)
  }

  mantainFilter = (type) => {
    var document_list = [];
    var { filter } = this.state;

    if (type == 1) {
      document_list = this.props.documents

    } else if (type == 2) {
      this.props.documents.forEach(document => {
        if (document.validation != 1) document_list.push(document);
      });
    } else if (type == 3) {
      this.props.documents.forEach(document => {
        if (document.validation == 1) document_list.push(document)
      });
    }

    if (filter != type) {
      localStorage.setItem('filter_type', type)
      this.setState({
        documents: document_list,
        isLoading: this.props.isLoading
      })
    }
  }
  render() {
    const { documents, isLoading } = this.state
    if (isLoading) {
      return (
        <div className="App">
          <div>Loading...</div>
        </div>
      )
    }

    return (

      <Container>
        <div>
          <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
            <Grid item>
              <Button size='large' className="MuiAppBar-colorPrimary"  onClick={e => this.clickHandler(e, 1)}>
                Todos
              </Button>
            </Grid>
            <Grid item>
              <Button size='large' className="MuiAppBar-colorPrimary" onClick={e => this.clickHandler(e, 2)}>
                Sin validar
              </Button>
            </Grid>
            <Grid item>
              <Button size='large' className="MuiAppBar-colorPrimary"  onClick={e => this.clickHandler(e, 3)}>
                Validados
              </Button>
            </Grid>
          </Grid>
        </div>
        <Table
          title=""
          columns={this.columns}
          data={documents} />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  documents: state.files.documents,
  isLoading: state.files.isLoading,
});

export default connect(mapStateToProps, { getFilesToCount })(ValidationList);
