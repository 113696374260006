import React, { Fragment, Component } from 'react';
import { Button } from '@material-ui/core/';
import { useLocation, useParams } from "react-router-dom";
import { render } from 'react-dom';
import { loadBuilding } from '../../actions/building';
import { connect } from 'react-redux';

export class Breadcrumbs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      building: null,
    }
  }

  componentDidMount() {
    let pathname = window.location.pathname
    let idBuild = pathname.split('/')[1]
    this.props.loadBuilding(idBuild)
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        building: this.props.building
      })
    }
  }

  render() {
    const { building } = this.state
    let modules = []
    let link_1 = ""
    let link_2 = ""
    let link_3 = ""

    if (building) {
      modules = building.modules_assigned
      link_1 = "/" + building.id + "/socrates/technics";
      link_2 = "/" + building.id + "/plato/electric";
      link_3 = "/" + building.id + "/aristotle/air";
    }

    const splitLocation = window.location.pathname.split("/")

    return (
      <div>
        {modules.includes('Sócrates') &&
          <Button variant="outlined" color="primary" href={link_1} className={splitLocation[2] === "socrates" ? "MuiButton-containedPrimary" : ""}> Sócrates</Button>
        }
        {modules.includes('Platón') &&
          <Button variant="outlined" color="primary" href={link_2} className={splitLocation[2] === "plato" ? "MuiButton-containedPrimary" : ""}> Platón</Button>
        }
        {modules.includes('Aristóteles') &&
          <Button variant="outlined" color="primary" href={link_3} className={splitLocation[2] === "aristotle" ? "MuiButton-containedPrimary" : ""}> Aristóteles</Button>
        }
        {modules.includes('Spinoza') &&
          <Button variant="outlined" color="primary" href={link_3} className={splitLocation[2] === "Spinoza" ? "MuiButton-containedPrimary" : ""} disabled> Spinoza</Button>
        }
        {modules.includes('Bernini') &&
          <Button variant="outlined" color="primary" href={link_3} className={splitLocation[2] === "Bernini" ? "MuiButton-containedPrimary" : ""} disabled> Bernini</Button>
        }
      </div >
    )
  }
}

const mapStateToProps = state => ({
  building: state.buildings.building,
});

export default connect(mapStateToProps, { loadBuilding })(Breadcrumbs);