import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container } from '@material-ui/core/';

/**Declaración de los estilos usados en el componente 'Header' */
const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '0px',
  },
  root: {
      paddingTop: theme.spacing(4),
  }
}));

export default function Template() {    
    const classes = useStyles();
    return (
        <Fragment>
            <Typography variant="h3" gutterBottom>
                 <Trans>PUESTA EN MARCHA SISTEMA HIDRÁULICO</Trans>
            </Typography>
            <Typography variant="h4" gutterBottom>
                 <Trans>PREPARACIÓN Y LIMPIEZA DE LAS REDES DE TUBERÍAS</Trans>
            </Typography>
            <Container>
                <Typography variant="body1" gutterBottom>
                    <Trans>Las comprobaciones previas al llenado del circuito hidráulico son preceptivas bajo el Artículo 22 del Reglamento de Instalaciones Térmicas “Control de la instalación terminada” que establece que en la instalación terminada, bien sobre la instalación en su conjunto o bien sobre sus diferentes partes, debe realizarse las comprobaciones y pruebas de servicio en el Proyecto o Memoria Técnica u ordenados por el instalador autorizado o por el director de la instalación, cuando la participación de este último sea preceptiva, tanto las previstas en la IT 2 “Montaje”, como las exigidas por la normativa vigente.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>El presente documento se centra en la preparación y limpieza de las redes de tuberías.</Trans>
                </Typography> 
                <Typography variant="h5" gutterBottom>
                    <Trans>Preparación y limpieza de las redes de tuberías:</Trans>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <Trans>En cumplimiento de la IT 2.2.2.2 “Preparación y limpieza de redes de tuberías” se limpiarán las redes de tuberías de agua para eliminar los residuos procedentes del montaje, tales como polvo, cascarillas, aceites o cualquier otro material extraño, antes de realizar las pruebas hidrostáticas y la puesta en funcionamiento siguiendo el siguiente procedimiento:</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>
                        <ul>
                            <li>Se llenará y vaciará la instalación el número de veces que sea necesario, con agua o con una solución acuosa de un producto detergente (excepto en redes de tuberías destinadas a la distribución de agua para usos sanitarios), con dispersantes compatibles con los materiales empleados en el circuito, cuya concentración será establecida por el fabricante.</li>
                            <li>Se pondrán en funcionamiento las bombas tras el llenado y se dejará circular el agua durante dos horas.</li>
                            <li>Se vaciará totalmente la red y se enjuagará con agua procedente del dispositivo de alimentación.</li>
                            <li>Se purgarán, en el caso de circuito primario solar, el aire u otros gases, ya que de lo contrario podría impedirse la circulación del fluido caloportador por los colectores, impidiéndose la transferencia térmica. Asimismo, si el fluido caloportador contiene elementos químicos anticongelantes, deberá impedirse la purga de gas automática de la instalación (eliminaría a la atmósfera los alcoholes que contiene los anticongelantes quedando expuesta la instalación a la congelación).</li>
                            <li>Se medirá el pH del agua del circuito en el caso de redes cerradas destinadas a la circulación de fluidos con temperaturas de funcionamiento menor que 100ºC. Si el pH resultara menor que 7,5 se repetirá la operación de limpieza y enjuague tantas veces como sea necesario. A continuación, se pondrá en funcionamiento la instalación con sus aparatos de tratamiento.</li>
                            <li>Se dejarán los filtros de malla metálica puestos para protección de las bombas durante una semana de funcionamiento, hasta que se compruebe que ha sido completada la eliminación de las partículas más finas que puede retener el tamiz de la malla. Sin embargo, los filtros para protección de válvulas automáticas y contadores entre otros se dejarán en su sitio.</li>
                        </ul>
                    </Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>En instalación solar, la toma de contacto del fluido caloportador con las superficies calientes de los colectores, podría dar lugar a un choque térmico produciéndose vaporización instantánea y una tensión mecánica por contracción brusca en las superficies metálicas, con consecuencias peligrosas para la instalación, por tanto, los procesos de purga y limpieza deben efectuarse en ausencia del Sol.</Trans>
                </Typography> 
                <Typography variant="body1" gutterBottom>
                    <Trans>Se adjunta hoja de toma de datos prevista.</Trans>
                </Typography> 
            </Container>
        </Fragment>
    )
}