import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadUser } from '../../../actions/auth';
import { getUser } from '../../../actions/users';
import { putUser, loadPermissions } from '../../../actions/users';
import { createMessage } from '../../../actions/messages';
import { Trans } from 'react-i18next';
import { Typography, Button, ButtonGroup, TextField, FormControlLabel, Checkbox, Grid, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core/';
import 'react-toastify/dist/ReactToastify.min.css';
import { toast } from 'react-toastify';


/**Componente que contiene el formulario para la edición de los datos del usuario actual. */
export class EditUser extends Component {

  state = {
    id: 0,
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password2: '',
    is_staff: 'false',
    is_superuser: 'false',
    permissions: [],
    user_type: '',
    permissions_list: [],
    user_type_list: [
      { name: "Administrador", value: "admin" },
      { name: "Cliente", value: "client" }
    ]
  }

  static propTypes = {
    user: PropTypes.object,
    user_edit: PropTypes.object,
    edit: PropTypes.func,
    loadUser: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.loadUser()
    this.props.loadPermissions()
    if (this.props.match.path != "/myuser"){
      this.props.getUser(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user_edit !== prevProps.user_edit) {
      this.setState({
        id: this.props.user_edit.id,
        username: this.props.user_edit.username,
        first_name: this.props.user_edit.first_name,
        last_name: this.props.user_edit.last_name,
        email: this.props.user_edit.email,
        is_staff: this.props.user_edit.is_staff,
        is_superuser: this.props.user_edit.is_superuser,
        permissions: this.props.user_edit.permissions,
        user_type: this.props.user_edit.user_type
      })
    }
    if (this.props.user !== prevProps.user){
      if (this.props.match.path == "/myuser"){
        this.setState({
          id: this.props.user.id,
          username: this.props.user.username,
          first_name: this.props.user.first_name,
          last_name: this.props.user.last_name,
          email: this.props.user.email,
          is_staff: this.props.user.is_staff,
          is_superuser: this.props.user.is_superuser,
          permissions: this.props.user.permissions,
          user_type: this.props.user.user_type
        })
      }
    }

    if (this.props.permissions_list !== prevProps.permissions_list) {
      this.setState({
        permissions_list: this.props.permissions_list
      })
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const { id, username, first_name, last_name, email, password, password2, is_staff, is_superuser, permissions, user_type } = this.state
    if (password != password2) {
      toast.error('Las contraseñas no coinciden')
    }
    else {
      let editUser = {
        id,
        username,
        first_name,
        last_name,
        email,
        is_staff,
        is_superuser,
        permissions,
        user_type
      };
      if (password) {
        editUser['password'] = password
      }
      this.props.putUser(editUser);
    }
  }

  onChangeSuperuser = e => {
    let is_superuser = e.target.checked
    let is_staff = e.target.checked

    this.setState({
      is_superuser: is_superuser,
      is_staff: is_staff
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const logged_user = this.props.user
    const { id, username, first_name, last_name, email, password, password2, is_superuser, permissions, user_type, permissions_list, user_type_list } = this.state;
    return (
      <div className="root">
        <Typography variant="h3" gutterBottom>
          <Trans>Editar perfil</Trans>
        </Typography>
        <form className="root">
          <TextField variant="outlined" margin="normal" id="user-id" label="ID" type="number"
            disabled={true} fullWidth
            name="id"
            onChange={this.onChange}
            value={id} />
          <TextField variant="outlined" margin="normal" id="user-new-name" label="Nombre" type="text" fullWidth
            name="username"
            onChange={this.onChange}
            value={username} />
          <TextField variant="outlined" margin="normal" id="user-password" label="Password" type="password" fullWidth
            name="password"
            onChange={this.onChange}
            value={password} />
          <TextField variant="outlined" margin="normal" id="user-confirm-password" label="Confirmar contraseña" type="password" fullWidth
            name="password2"
            onChange={this.onChange}
            value={password2} />
          <TextField variant="outlined" margin="normal" id="user-personal-name" label="Nombre personal" type="text" fullWidth
            name="first_name"
            onChange={this.onChange}
            value={first_name} />
          <TextField variant="outlined" margin="normal" id="user-surname" label="Apellido" type="text" fullWidth
            name="last_name"
            onChange={this.onChange}
            value={last_name} />
          <TextField variant="outlined" margin="normal" id="user-email" label="Email" type="text" fullWidth
            name="email"
            onChange={this.onChange}
            value={email} />
          {logged_user.is_superuser &&
            <>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="label-log-type">Permisos Módulos</InputLabel>
                <Select
                  name="permissions"
                  multiple
                  labelId="label-log-type"
                  value={permissions}
                  onChange={this.onChange}
                >
                  {permissions_list.map((permission) => (
                    <MenuItem value={permission.id}>{permission.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="label-log-type">Tipo de Usuario</InputLabel>
                <Select
                  name="user_type"
                  labelId="label-log-type"
                  value={user_type}
                  onChange={this.onChange}
                >
                  {user_type_list.map((user_type) => (
                    <MenuItem value={user_type.value}>{user_type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <FormControlLabel control={<Checkbox checked={is_superuser} name="superuser" onClick={this.onChangeSuperuser} />} label="Administrador Aplicación" />
              </FormControl>
            </>
          }
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <ButtonGroup size='large' variant="contained" color="primary" aria-label="contained primary button group">
              <Button type="button" color="primary" onClick={this.onSubmit}><Trans>Editar Usuario</Trans></Button>
              <Button type="button" color="secondary" component={Link} to="/users"><Trans>Volver</Trans></Button>
            </ButtonGroup>
          </Grid>
        </form>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  user: state.auth.user,
  user_edit: state.users.user_edit,
  permissions_list: state.users.permissions
});

export default connect(mapStateToProps, { loadUser, createMessage, putUser, loadPermissions, getUser })(EditUser);