import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import Template1 from './templates/1';
import Template4 from './templates/4';
import Template7 from './templates/7';
import Template8 from './templates/8';
import Template9 from './templates/9';
import Template11 from './templates/11';
import Template12 from './templates/12';
import Template13 from './templates/13';
import Template15 from './templates/15';


export default function InfoPci() {
    return (
        <Switch>
            <Route path="/:idBuild/plato/pci/info/1" component={Template1} />
            <Route path="/:idBuild/plato/pci/info/2" component={Template1} />
            <Route path="/:idBuild/plato/pci/info/3" component={Template1} />
            <Route path="/:idBuild/plato/pci/info/4" component={Template4} />
            <Route path="/:idBuild/plato/pci/info/5" component={Template4} />
            <Route path="/:idBuild/plato/pci/info/6" component={Template4} />
            <Route path="/:idBuild/plato/pci/info/7" component={Template7} />
            <Route path="/:idBuild/plato/pci/info/8" component={Template8} />
            <Route path="/:idBuild/plato/pci/info/9" component={Template9} />
            <Route path="/:idBuild/plato/pci/info/10" component={Template9} />
            <Route path="/:idBuild/plato/pci/info/11" component={Template11} />
            <Route path="/:idBuild/plato/pci/info/12" component={Template12} />
            <Route path="/:idBuild/plato/pci/info/13" component={Template13} />
            <Route path="/:idBuild/plato/pci/info/14" component={Template13} />
            <Route path="/:idBuild/plato/pci/info/15" component={Template15} />
            <Route path="/:idBuild/plato/pci/info/16" component={Template15} />
        </Switch>
    )
}