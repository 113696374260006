import React, { Fragment, Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';
import { Typography, Container, TextField, Grid, Button } from '@material-ui/core/';
import AddIcon from '@material-ui/icons/Add';
import Submit from '../components/submit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getJSON } from '../../../../../actions/files';

export class Template extends Component {

    constructor(props) {
        super(props);
        this.props.getJSON(this.props.document.file);
    }

    static propTypes = {
        getJSON: PropTypes.func.isRequired
    }


    /**Declaración de los estilos usados en el componente 'Header' */
    useStyles() {
        return makeStyles(theme => ({
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                minHeight: '0px',
            },
            root: {
                paddingTop: theme.spacing(4),
            }
        }));
    }

    componentDidUpdate() {
        var form = document.querySelector('form')
        var inputs = form.elements

        for (let i = 0; i < (inputs.length - 1); i++) {
            inputs[i].value = this.props.json[i]
        }

    };

    render() {

        const classes = this.useStyles();
        return (
            <Fragment>
                <Typography variant="h3" gutterBottom>
                    <Trans>ELECTRICIDAD-007</Trans>
                </Typography>
                <Typography variant="h4" gutterBottom>
                    <Trans>SECUENCIA DE FASES</Trans>
                </Typography>
                <Container>
                    <form className={classes.root}>
                        <Typography variant="h5" gutterBottom>
                            <Trans>Comprobación de secuencia de fases</Trans>
                        </Typography>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Equipo de medida" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Nº serie" variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Cuadro" variant="outlined" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth label="Servicio" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" gutterBottom>
                                    <Trans>VERIFICACIÓN COLOR DE MARCADO (Comprobar en el origen y en el servicio)</Trans>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="R marrón" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="S negro" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="T gris" variant="outlined" />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField fullWidth label="N azul" variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField fullWidth label="Sentido giro (origen y servicio)" variant="outlined" />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField fullWidth label="OBSERVACIONES" variant="outlined" />
                            </Grid>
                            <Grid item xs={4}>
                                <Button variant="outlined"><AddIcon /></Button>
                            </Grid>
                        </Grid>
                        <Typography variant="caption" gutterBottom>
                            <Trans>
                                Nota 1: La columna de SENTIDO DE GIRO deberá cumplimentarse de la siguiente forma: <br />
                                L1 - L2 - L3/L1 - L2 - L3 ó R - S - T/R - S - T <br />
                                Correspondiéndose las secuencias separadas por / a la secuencia media en origen y destino respectivamente
                            </Trans>
                        </Typography>
                        <Typography variant="h5" gutterBottom>
                            <Trans>CONFORMIDAD</Trans>
                        </Typography>
                        <TextField fullWidth label="Firma persona que realiza el protocolo" variant="outlined" />
                        <TextField fullWidth label="Fecha" variant="outlined" />
                        <Submit document={this.props.document} />
                    </form>
                </Container>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    json: state.files.json
});

export default connect(mapStateToProps, { getJSON })(Template);